import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import labelKey from '../../../localization/label.json'
import tosterKey from '../../../localization/toster.json'
import { KTSVG } from '../../../../_metronic/helpers'
import { useDeleteAttachmentPortalSupportRequestMutation } from '../../../../services/PortalSupportApi'
import { toast } from 'react-toastify'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
    formik: any
    fileInputRef: any
    adminAttachmentDeleteData?: any
    adminFileInputRef?: any
}

const DeleteAttachmentModal = ({ show, handleClose, data, adminAttachmentDeleteData, formik, fileInputRef, adminFileInputRef }: Props) => {
    const [deleteAttachment, { isSuccess, isError, error, isLoading }] = useDeleteAttachmentPortalSupportRequestMutation()
    const deleteAttachmentHandler = (index: number) => {
        const updatedAttachments = formik.values?.attachments.filter((_: any, i: any) => i !== index);
        formik.setFieldValue('attachments', updatedAttachments);
        const fileInput = fileInputRef.current;

        if (fileInput) {
            fileInput.value = ''; // Clear the input value
        }
        handleClose()
    };
    const deleteAdminAttachmentHandler = (index: number) => {

        const updatedAdminAttachments = formik.values?.adminAttachments.filter((_: any, i: any) => i !== index);
        formik.setFieldValue('adminAttachments', updatedAdminAttachments);
        const adminFileInputRef = fileInputRef.current;

        if (adminFileInputRef) {
            adminFileInputRef.value = ''; // Clear the input value
        }

    };
    const handleDelete = async (data: any) => {
        // if (data !== null && data !== undefined) {
        //     deleteAttachmentHandler(data);
        // } else if (adminAttachmentDeleteData !== null && adminAttachmentDeleteData !== undefined) {
        //     deleteAdminAttachmentHandler(adminAttachmentDeleteData);
        //     await deleteAttachment(data)
        // }
        if (data) {
            await deleteAttachment(data)
        } else {
            deleteAdminAttachmentHandler(adminAttachmentDeleteData);
            handleClose()
        }
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.attachementDeletedSuccessfully);
            if (typeof adminAttachmentDeleteData === 'number') {
                deleteAdminAttachmentHandler(adminAttachmentDeleteData);
              }
            handleClose()
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.deleteConfirmation}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-5 fw-semibold'>{labelKey.areYouSureYouDelete} <span className='text-danger'>"{labelKey.attachment}"</span>.</p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-danger'
                        // onClick={handleDelete}
                        onClick={() => handleDelete(data)}

                    >{isLoading ? (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            {labelKey.pleaseWait}...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ):(<>{labelKey.delete}</>)}
                        
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default DeleteAttachmentModal