// export const timePeriodOptions = [
//     {
//         id: 'today',
//         value: 'Today'
//     },
//     {
//         id: 'yesterday',
//         value: 'Yesterday'
//     },
//     {
//         id: 'week',
//         value: 'Week'
//     },
//     {
//         id: 'month',
//         value: 'Month'
//     },
//     {
//         id: 'quarter',
//         value: 'Quarter'
//     },
//     {
//         id: 'year',
//         value: 'Year'
//     },

// ]

export const timePeriodOptions = [
    {
        id: 'today',
        value: 'Today'
    },
    {
        id: 'yesterday',
        value: 'Yesterday'
    },
    {
        id: 'last7Days',
        value: 'Last 7 Days'
    },
    {
        id: 'last30Days',
        value: 'Last 30 Days'
    },
    {
        id: 'thisMonth',
        value: 'This Month'
    },
    {
        id: 'lastMonth',
        value: 'Last Month'
    },
    {
        id: 'thisQuarter',
        value: 'This Quarter'
    },
    {
        id: 'lastQuarter',
        value: 'Last Quarter'
    },
    {
        id: 'thisYear',
        value: 'This Year'
    },
    {
        id: 'lastYear',
        value: 'Last Year'
    },
]