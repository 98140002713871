import ReactApexChart from 'react-apexcharts';
import labelKey from "../../localization/label.json";
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

type props = {
    data: any
}
const ExpenseCategoryChart = ({ data }: props) => {
    const pieChartData = {
        series: data?.result?.categoriesSubtottal.map((category: any) => category?.categoryExpenseAmount) || [],
        options: {
            labels: data?.result?.categoriesSubtottal.map((category: any) => category?.categoryName) || [],
            legend: {
                show: true,
                labels: {
                    colors: 'var(--bs-heading-color)', // Apply CSS variable to legend labels
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '12px', // Set the font size for the name label
                                color: 'var(--bs-heading-color)', // Apply CSS variable to donut name label
                            },
                            value: {
                                show: true,
                                color: 'var(--bs-heading-color)', // Apply CSS variable to donut value label
                                formatter: function (val: any) {
                                    return '$' + Number(val).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                },
                            },
                            total: {
                                show: true,
                                label: labelKey.total,
                                color: 'var(--bs-heading-color)', // Apply CSS variable to total label
                                formatter: function (w: any) {
                                    const total = w.globals.seriesTotals.reduce((a: any, b: any) => {
                                        return a + b;
                                    }, 0);
                                    return `${'$' + Number(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                                },
                            },
                        },
                    },
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250,
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 50,
                        labels: {
                            colors: 'var(--bs-heading-color)', // Apply CSS variable to responsive legend labels
                        },
                    },
                },
            }],
            tooltip: {
                y: {
                    formatter: function (value: any) {
                        return `$${value.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`;
                    },
                },
            },
        },
    };

    const isDataAvailable = data && data.result?.categoriesSubtottal.length > 0;
    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className="col-md-12 col-lg-6 mt-5 mb-5 mb-xl-10">
                <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                    <h3>{labelKey.expenseByCategory}
                        <FaInfoCircle className='text-gray cursor-pointer ms-2'
                            data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.' />
                    </h3>
                    {isDataAvailable ? (
                        <>
                            <div className="d-flex align-items-center gap-3">
                                <p className='fs-5 fw-bold'>{labelKey.totalCategories}</p>
                                <p className='fs-5 fw-semibold'>{data?.result?.totalCategories}</p>
                            </div>
                            <div className='d-flex d-md-inline justify-content-center'>
                                <ReactApexChart options={pieChartData?.options} series={pieChartData.series} type="donut" height={300} />
                            </div>
                        </>
                    ) : (
                        <p className='text-center mt-5'>No data available for the chart.</p>
                    )}

                </div>
            </div>
        </>
    )
}

export default ExpenseCategoryChart