import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore
} from "redux-persist"
import authReducer from './features/authSlice'
import toastReducer from './features/toastSlice'
import { authApi } from './services/authApi'
import { BackgroundJobApi } from './services/BackgroundJobApi'
import { BudgetApi } from './services/BudgetApi'
import { ClientAndProjectsApi } from './services/ClientAndProjectsApi'
import { CreditCardApi } from './services/CreditCardApi'
import { DashboardApi } from './services/DashboardApi'
import { DraftApi } from './services/DraftApi'
import { ExpenseApi } from './services/ExpenseApi'
import { ExpenseVoilationApi } from './services/ExpenseVoilationApi'
import { FamilyMemberApi } from './services/FamilyMemberApi'
import { GeneralApi } from './services/GeneralApi'
import { IntegrationAPI } from './services/IntegrartionApi'
import { OrgSettingsApi } from './services/OrgSettingsApi'
import { organizationApi } from './services/OrganizationApi'
import { ReportAndAnalyticsApi } from './services/ReportAndAnalyticsApi'
import { VendorApi } from './services/VendorApi'
import { WorkFlowApi } from './services/WorkFlowApi'
import { authenticationApi } from './services/authenticationApi'
import { AutomationApi } from './services/AutomationApi'
import { CategoriesApi } from './services/CategoriesApi'
import { InvoiceDataDraftApi } from './services/InvoiceDataDraftApi'
import columnVisibilityReducer from './features/columnVisibilitySlice'
import { SystemSettingsApi } from './services/SystemSettingsApi'
import { PortalSupportApi } from './services/PortalSupportApi'
import uploadReceiptStep2 from './features/uploadReceiptStep2'
import { BCIntegrationApi } from './services/BCIntegrationApi'
export const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    uploadReceiptStep2: uploadReceiptStep2,
    columnVisibility: columnVisibilityReducer,
    [authApi.reducerPath]: authApi.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [GeneralApi.reducerPath]: GeneralApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [FamilyMemberApi.reducerPath]: FamilyMemberApi.reducer,
    [ExpenseApi.reducerPath]: ExpenseApi.reducer,
    [WorkFlowApi.reducerPath]: WorkFlowApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [ClientAndProjectsApi.reducerPath]: ClientAndProjectsApi.reducer,
    [ReportAndAnalyticsApi.reducerPath]: ReportAndAnalyticsApi.reducer,
    [BudgetApi.reducerPath]: BudgetApi.reducer,
    [VendorApi.reducerPath]: VendorApi.reducer,
    [IntegrationAPI.reducerPath]: IntegrationAPI.reducer,
    [DraftApi.reducerPath]: DraftApi.reducer,
    [CreditCardApi.reducerPath]: CreditCardApi.reducer,
    [BackgroundJobApi.reducerPath]: BackgroundJobApi.reducer,
    [OrgSettingsApi.reducerPath]: OrgSettingsApi.reducer,
    [ExpenseVoilationApi.reducerPath]: ExpenseVoilationApi.reducer,
    [AutomationApi.reducerPath]: AutomationApi.reducer,
    [CategoriesApi.reducerPath]: CategoriesApi.reducer,
    [InvoiceDataDraftApi.reducerPath]: InvoiceDataDraftApi.reducer,
    [SystemSettingsApi.reducerPath]: SystemSettingsApi.reducer,
    [PortalSupportApi.reducerPath]: PortalSupportApi.reducer,
    [BCIntegrationApi.reducerPath]: BCIntegrationApi.reducer,

    

  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware).concat(authenticationApi.middleware).concat(GeneralApi.middleware).concat(organizationApi.middleware).concat(FamilyMemberApi.middleware).concat(ExpenseApi.middleware).concat(WorkFlowApi.middleware).concat(DashboardApi.middleware).concat(ClientAndProjectsApi.middleware).concat(ReportAndAnalyticsApi.middleware).concat(BudgetApi.middleware).concat(VendorApi.middleware).concat(IntegrationAPI.middleware).concat(DraftApi.middleware).concat(CreditCardApi.middleware).concat(BackgroundJobApi.middleware).concat(OrgSettingsApi.middleware).concat(ExpenseVoilationApi.middleware).concat(AutomationApi.middleware).concat(CategoriesApi.middleware).concat(InvoiceDataDraftApi.middleware).concat(SystemSettingsApi.middleware).concat(PortalSupportApi.middleware).concat(BCIntegrationApi.middleware),
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// setupListeners(store.dispatch)
export const persistor = persistStore(store);
