import moment from 'moment';
import { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetExpenseItemByExpenseIdQuery } from '../../../services/ExpenseApi';
import { useGetExpenseToApproveQuery } from '../../../services/WorkFlowApi';
import ExpenseLogModal from '../expenseItems/ExpenseLogModal';
import ImageModal from '../expenseItems/ImageModal';
import UpdateAprroverStatusModal from './UpdateAprroverStatusModal';
import labelKey from "../../localization/label.json"
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import { FaHistory } from 'react-icons/fa';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import { FaCheck } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

const ApproversPage = () => {
  const { userAccountID } = useAppSelector(selectAuth);
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState('');
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterByCategory, setFilterByCategory] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");

  const { data: getExpenseToAprove,
    isLoading: expenseToAproveIsLoading,
    refetch: expenseToAproveRefetch } = useGetExpenseToApproveQuery({
      userAccountId: userAccountID,
      pageNumber: page,
      pageSize: pageSize,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchStr: searchStr,
      filterByCategory: filterByCategory,
      filterByStatus: filterByStatus,
    });

  // pagination
  const handlePageClick = (e: any) => {
    setPage(e.selected + 1);
  };
  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPage(1); // Reset page number to 1
  };
  const handleSort = (property: string) => {
    setSortBy(property);
    setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    // Perform additional sorting logic...
  };

  //per page record
  const handlePageRecords = (e: any) => {
    setPage(1); // Reset the page to 1
  };
  const handleSearchChange = (e: any) => {
    setSearchStr(e.target.value);
  }
  const handleStatusChange = (e: any) => {
    setFilterByStatus(e.target.value);
  };

  useEffect(() => {
    if (
      filterByStatus === "" ||
      searchStr === "" ||
      sortOrder === "asc" ||
      sortBy === "") {
      expenseToAproveRefetch();
    }
  }, [filterByStatus, sortOrder, sortBy, searchStr]);

  const handleDeparmentChange = (selectedOption: any) => {
    setFilterByStatus(selectedOption.value);
  };
  const [showInnerTable, setShowInnerTable] = useState(null);

  const handleRowClick = (rowId: any) => {
    if (showInnerTable === rowId) {
      setShowInnerTable(null);
    } else {
      setShowInnerTable(rowId);
    }
  };

  const [showUpdateApproverStatusModal, setShowUpdateApproverStatusModal] = useState(false)
  const [rejectExpense, setRejectExpense] = useState('')
  const [approvedExpense, setApprovedExpense] = useState('')
  const [editData, setEditData] = useState({});

  const editApproverStatusHandler = () => {
    setShowUpdateApproverStatusModal((prev) => !prev);
  };
  const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
  const [expenseLogData, setExpenseLogData] = useState({});

  const expenseLogHandler = () => {
    setShowExpenseLogModal((prev) => !prev);
  };

  useEffect(() => {
    if (showUpdateApproverStatusModal === false || showExpenseLogModal === false) {
      expenseToAproveRefetch();
    }
  }, [showUpdateApproverStatusModal, showExpenseLogModal])

  const [expenseId, setExpenseId] = useState({})
  const { data: expenseItemData } = useGetExpenseItemByExpenseIdQuery({
    ExpenseId: expenseId,
  })
  const [showImageModal, setShowImageModal] = useState(false)
  const [userDetailData, setuserDetailData] = useState({});

  const imageModalHandler = () => {
    setShowImageModal((prev) => !prev);
  };
  return (
    <>
      <ExpenseLogModal show={showExpenseLogModal} handleClose={() => setShowExpenseLogModal(false)} data={expenseLogData} />
      <UpdateAprroverStatusModal
        show={showUpdateApproverStatusModal}
        handleClose={() => setShowUpdateApproverStatusModal(false)}
        data={editData}
        approvedExpense={approvedExpense}
        rejectExpense={rejectExpense} />
      <ImageModal show={showImageModal} handleClose={() => setShowImageModal(false)} data={userDetailData} expenseItems={expenseItemData?.result} />
      {/* <ExpenseDetailModal show={showExpenseDetailModal} handleClose={() => setShowExpenseDetailModal(false)} data={expenseDetailData} expenseItems={expenseItemData?.result} /> */}

      <PageTitle>{labelKey.approvers}</PageTitle>
      <div className='card mb-5'>
        <div className='card-header border-0 pt-5'>
          <SearchBarComponent
            placeholder='Expense'
            value={searchStr}
            onChange={handleSearchChange}
          />
          <div className='d-flex align-items-center'>
            <div>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                {labelKey.filter}
              </button>
              <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px mh-450px overflow-auto' data-kt-menu='true'>
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                </div>

                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5' data-kt-user-table-filter='form'>

                  <div className='mt-3'>
                    {/* <label className='form-label fs-6 fw-semibold'>Category Filter:</label>
                    <select className='form-select form-select-solid fw-semi bold'
                      onChange={handleCategoryChange}>
                      <option value="">Select Category</option>
                      <option value="Regular">Regular</option>
                      <option value="Flex">Flex</option>
                    </select> */}
                    <label className='form-label fs-6 fw-semibold mt-5'>{labelKey.status}:</label>
                    <select className='form-select form-select-solid fw-semi bold'
                      value={filterByStatus}
                      onChange={handleStatusChange}>
                      <option value="">{labelKey.selectStatus}</option>
                      <option value="Pending for Approval">{labelKey.pendingForApproval}</option>
                      <option value="Clarification">{labelKey.clarification}</option>
                      <option value="Approved">{labelKey.approved}</option>
                      <option value="Rejected">{labelKey.rejected}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-8'>
          <div className="row w-100">
            <div className="col-md-12">
              <div className='table-responsive table-height'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='ps-4 rounded-start'>{labelKey.reportId}</th>
                      <th onClick={() => handleSort("userAccount")}>
                        <div className='table-arrow'>
                          {labelKey.reportees}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th onClick={() => handleSort("title")}>
                        <div className='table-arrow'>
                          {labelKey.title}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th className='w-200px' onClick={() => handleSort("expenseDetail")}>
                        <div className='table-arrow'>
                          {labelKey.description}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th onClick={() => handleSort("amount")}>
                        <div className='table-arrow'>
                          {labelKey.totalAmount}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th onClick={() => handleSort("createdDate")}>
                        <div className='table-arrow'>
                          {labelKey.date}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th onClick={() => handleSort("expenseStatus")}>
                        <div className='table-arrow'>{labelKey.status}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th onClick={() => handleSort("approverDate")}>
                        <div className='table-arrow'>
                          {labelKey.approvalDate}
                          {sortOrder === "desc" ? (
                            <i className="fa fa-arrow-down"></i>
                          ) : (
                            <i className="fa fa-arrow-up"></i>
                          )}
                        </div>
                      </th>
                      <th>{labelKey.comment}</th>
                      <th className='text-end rounded-end pe-4'>{labelKey.action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!expenseToAproveIsLoading &&
                      <>
                        {getExpenseToAprove?.result?.data?.length > 0 ? (
                          <>
                            {getExpenseToAprove?.result?.data?.map((data: any, index: any) => (
                              <>
                                <tr onClick={() => {
                                  handleRowClick(data?.expenseId);
                                  setExpenseId(data?.expenseId);
                                }}>
                                  <td className='ps-5'>{data?.trackingId}</td>
                                  <td>
                                    <Link to={`/user-profile/expense-items/${data.userAccount?.orgUserID}`}>
                                      <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-50px me-5'>
                                          {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> :
                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                          }
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                          <p className='text-capitalize mb-0'>
                                            {data?.userAccount?.firstName}  {data?.userAccount?.middleName} {data?.userAccount?.lastName}
                                          </p>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                            {data?.userAccount?.email}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </td>
                                  <td>{data?.title}</td>
                                  <td>{data?.expenseDetail?.length > 60 ? `${data?.expenseDetail.substring(0, 40)}...` : data?.expenseDetail}</td>
                                  <td>${data?.amount?.toLocaleString('en-US')}</td>
                                  <td>{moment(data?.createdDate).format("DD MMM YYYY")}</td>
                                  <td>
                                    {data?.approverStatus.title === "Pending" ?
                                      <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.pending}</span> :
                                      data?.approverStatus.title === "Approve" ?
                                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approve}</span> :
                                        data?.approverStatus.title === "Reject" ?
                                          <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.reject}</span> :
                                          data?.approverStatus.title === "Clarification" ?
                                            <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                            data?.approverStatus.title === "Rejected" ? <span className="badge badge-light-danger fs-7 fw-semibold">Rejected</span> : ""
                                    }
                                  </td>
                                  <td>{data?.approverDate ? (moment(data?.approverDate).format("DD MMM YYYY")) : "_"}</td>
                                  <td>{data?.comment && data?.comment.length > 20 ? `${data?.comment.substring(0, 20)}...` : data?.comment}</td>
                                  <td className='text-end' >
                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                      <Tooltip id="Approve-Status" place="top" />
                                      {data?.approverStatus?.id === 1 && (
                                        // <OverlayTrigger
                                        //   delay={{ show: 250, hide: 350 }}
                                        //   overlay={
                                        //     <Tooltip>
                                        //       {labelKey.updateStatus}
                                        //     </Tooltip>
                                        //   }>
                                        //   <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        //     onClick={() => {
                                        //       setEditData(data);
                                        //       editApproverStatusHandler();
                                        //     }}
                                        //   >
                                        //     <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                        //   </div>
                                        // </OverlayTrigger>
                                        <>
                                          <div
                                            data-tooltip-id="Approve-Status"
                                            data-tooltip-content='Approve Expense Report'
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            onClick={() => {
                                              setEditData(data);
                                              setApprovedExpense('approved')
                                              setRejectExpense('');
                                              editApproverStatusHandler();
                                            }}
                                          >
                                            <FaCheck className='h-14px w-14px' />
                                          </div>
                                          <div
                                            data-tooltip-id="Approve-Status"
                                            data-tooltip-content='Reject Expense Report'
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            onClick={() => {
                                              setEditData(data);
                                              setRejectExpense('rejected')
                                              setApprovedExpense('');
                                              editApproverStatusHandler();
                                            }}
                                          >
                                            <IoMdClose className='h-15px w-15px' />
                                          </div>
                                        </>
                                      )}
                                      <div
                                        data-tooltip-id="Approve-Status"
                                        data-tooltip-content={labelKey.expenseLogs}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        onClick={() => {
                                          setExpenseLogData(data?.expenseId);
                                          expenseLogHandler();
                                        }}
                                      >
                                        <FaHistory className='text-dark text-hover-primary' />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {showInnerTable === data?.expenseId && (
                                  <tr className="inner-row">
                                    <td className='pt-0 px-3' colSpan={10}>
                                      <div className="table-responsive sticky-responsive">
                                        <table className="table align-middle gs-0 gy-4">
                                          <thead>
                                            <tr className='fw-bold text-muted bg-light'>
                                              <th className='ps-4 rounded-start text-center w-60px'>{labelKey.sr}</th>
                                              <th>{labelKey.category}</th>
                                              <th>{labelKey.subCategory}</th>
                                              <th>{labelKey.currency}</th>
                                              <th>{labelKey.amount}</th>
                                              <th>{labelKey.vendor}</th>
                                              <th>{labelKey.paymentMethod}</th>
                                              <th>{labelKey.itemDate}</th>
                                              <th>{labelKey.attachment}</th>
                                              <th>{labelKey.description}</th>
                                              {(data?.expenseStatus?.title === "Drafted") && (
                                                <th className='text-end pe-3'>{labelKey.action}</th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {expenseItemData?.result?.map((itemData: any, index: any) => (
                                              <tr>
                                                <td className='text-center'>
                                                  {index + 1}
                                                </td>
                                                <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                                                <td>{itemData?.expenseSubCategory?.title}</td>
                                                <td>{itemData?.currency?.title}</td>
                                                <td>${itemData?.amount?.toLocaleString('en-US')} </td>
                                                <td>{itemData?.vander}</td>
                                                <td>{itemData?.paymentMethod?.title}</td>
                                                <td>{moment(itemData.itemDate).format("DD MMM YYYY")}</td>
                                                <td>
                                                  <div onClick={() => {
                                                    setuserDetailData(itemData);
                                                    setExpenseId(data?.expenseId);
                                                    imageModalHandler();
                                                  }}>
                                                    <img src={itemData?.attachmentFile} width="40px" height="40px" alt="" />
                                                  </div>
                                                </td>
                                                <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={10}>
                              <NoRecordFound />
                            </td>
                          </tr>
                        )}
                      </>
                    }

                    {expenseToAproveIsLoading && (
                      <tr>
                        <td colSpan={10} className="text-center">
                          <LoadingComponent />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="d-flex flex-stack flex-wrap pt-10">
                <div className="fs-6 fw-bold text-gray-700">
                  {labelKey.showing} {((getExpenseToAprove?.result?.pageNumber - 1) * getExpenseToAprove?.result?.pageSize) + 1} {labelKey.to} {Math.min(getExpenseToAprove?.result?.pageNumber * getExpenseToAprove?.result?.pageSize, getExpenseToAprove?.result?.totalRecords)} {labelKey.of} <span>{getExpenseToAprove?.result?.totalRecords}</span> Entries
                </div>
                <div className='d-flex align-items-center gap-3'>
                  <div className='d-flex align-items-center'>
                    <label className='fs-6 text-gray-700 fw-semibold me-2'>{labelKey.perPage}</label>
                    <select value={pageSize} className='form-select form-select-solid h-40px w-80px' onChange={handlePageSizeChange}>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel="Next"
                    onPageChange={(e) => handlePageClick(e)}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={getExpenseToAprove?.result?.totalPages}
                    previousLabel="Previous"

                    containerClassName='pagination'
                    pageClassName='page-item'
                    previousClassName='page-item'
                    nextClassName='page-item'
                    breakClassName='page-item'

                    pageLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    breakLinkClassName='page-link'
                    activeClassName='active'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApproversPage