import ReactApexChart from 'react-apexcharts';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import labelKey from "../../localization/label.json";

const ExpenseDepartmentChart = ({ reportByDeptAndUsersData }: any) => {

  const barChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    colors: ['var(--bs-primary)', 'var(--bs-secondary)'], // Use CSS variables for bar colors
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: reportByDeptAndUsersData?.result?.expenseReportByDepartment?.map((department: any) => department?.departmentName) || [],
      title: {
        text: 'Departments', // X-axis label
        style: {
          color: 'var(--bs-heading-color)', // Apply CSS variable to X-axis label
        },
      },
      labels: {
        style: {
          colors: 'var(--bs-heading-color)', // Apply CSS variable to X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: 'Expense Amount', // Y-axis label
        style: {
          color: 'var(--bs-heading-color)', // Apply CSS variable to Y-axis label
        },
      },
      labels: {
        style: {
          colors: 'var(--bs-heading-color)', // Apply CSS variable to Y-axis labels
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 8,
        dataLabels: {
          position: 'top', // Display labels on top of each bar
        },
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right', // Align legend to the right
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: 'var(--bs-heading-color)', // Apply CSS variable to legend labels
      },
    },
    annotations: {
      xaxis: [
        {
          x: 0, // x-axis index where the line will be drawn
          borderColor: 'var(--bs-border-color)', // Apply CSS variable to annotation line color
        },
      ],
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: function (value: any) {
            return `$${value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`;
          },
        },
      ],
    },
  };


  const barChartSeries = [
    {
      name: 'No of Expenses',
      data: reportByDeptAndUsersData?.result?.expenseReportByDepartment?.map((department: any) => department?.departmentExpenseCount) || [],
    },
    {
      name: 'Total Expense Amount',
      data: reportByDeptAndUsersData?.result?.expenseReportByDepartment?.map((department: any) => department?.departmentTotalExpenseAmount) || [],
    },
  ];
  return (
    <>
      <Tooltip id="chart" place="top" />
      <div>
        <h3>{labelKey.expenseByDepartment} <span data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.'>
          <FaInfoCircle className='text-gray cursor-pointer' />
        </span> </h3>
      </div>
      <div className="mt-5">
        <div className='d-flex d-md-inline justify-content-center'>
          <ReactApexChart options={barChartOptions} series={barChartSeries} type='bar' height={400} />
        </div>
      </div>
    </>
  )
}

export default ExpenseDepartmentChart