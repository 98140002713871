import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the type for a single row's data
interface RowData {
    remarks?: string;
    fileURL?: string;
    itemDate?: string;
    amount?: number;
    vendorInfo?: {
        vendorId?: number;
        name?: string;
    };
    expenseCategory?: {
        id?: number;
        title?: string;
    };
    paymentMethod?: {
        id?: number;
        title?: string;
    };
    invoiceDataDraftId?: number;
}

// Define the initial state type
interface RowState {
    [key: number]: RowData; // key is rowId, value is RowData
}

// Define the initial state
const initialState: RowState = {};

// Create a slice
const uploadReceiptStep2 = createSlice({
    name: 'uploadReceiptStep2',
    initialState,
    reducers: {
        updateRowData(state, action: PayloadAction<{ rowId: number; data: RowData }>) {
            const { rowId, data } = action.payload;
            state[rowId] = data;
        },
        removeRowData(state, action: PayloadAction<number>) {
            delete state[action.payload];
        }
    },
});

// Export actions and reducer
export const { updateRowData, removeRowData } = uploadReceiptStep2.actions;
export default uploadReceiptStep2.reducer;
