import React, { useEffect, useState } from 'react'
import { useGetExpenseReportByUserQuery, useGetExpenseReportByUserWithAmountQuery } from '../../../services/ReportAndAnalyticsApi';
import ReactApexChart from 'react-apexcharts'
import { KTSVG } from '../../../_metronic/helpers'
import labelKey from "../../localization/label.json"
import TextField from '../../../components/textFields/TextField'
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
const ExpenseReportByUser = ({ reportByDeptAndUsersData }: any) => {


    const lineChartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: reportByDeptAndUsersData?.result?.expenseReportByUser?.map((user: any) => user?.userName) || [],
            title: {
                text: 'Users', // X-axis label
                style: {
                    color: 'var(--bs-heading-color)', // Apply CSS variable to X-axis label
                },
            },
            labels: {
                style: {
                    colors: 'var(--bs-heading-color)', // Apply CSS variable to X-axis labels
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            title: {
                text: 'Expense Count', // Y-axis label
                style: {
                    color: 'var(--bs-heading-color)', // Apply CSS variable to Y-axis label
                },
            },
            labels: {
                style: {
                    colors: 'var(--bs-heading-color)', // Apply CSS variable to Y-axis labels
                },
            },
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right', // Align legend to the right
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: 'var(--bs-heading-color)', // Apply CSS variable to legend labels
            },
        },
        annotations: {
            xaxis: [
                {
                    x: 0, // x-axis index where the line will be drawn
                    borderColor: 'var(--bs-border-color)', // Apply CSS variable to annotation line color
                },
            ],
        },
        tooltip: {
            y: [
                {
                    formatter: function (value: any) {
                        return `$${value.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`;
                    },
                },
            ],
        },
    };



    const lineChartSeries = [
        {
            name: 'Total Expense',
            data: reportByDeptAndUsersData?.result?.expenseReportByUser?.map((user: any) => user?.totalExpenseCount) || [],
        },
        {
            name: 'Total Expense Amount',
            data: reportByDeptAndUsersData?.result?.expenseReportByUser?.map((user: any) => user?.userExpenseAmount) || [],
        },
    ];

    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className="row">
                <div className="col-md-12">
                    <div>
                        <h2>{labelKey.expenseByUsers}
                            <FaInfoCircle className='text-gray cursor-pointer ms-2'
                                data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.' />
                        </h2>

                    </div>
                </div>
                <div className="col-md-12 mt-5">
                    <div >
                        <ReactApexChart options={lineChartOptions} series={lineChartSeries} type='line' height={400} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseReportByUser