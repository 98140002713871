import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAppSelector } from '../../../../hooks';
import { selectAuth } from '../../../../features/authSlice';
import { constraintConfig } from '../../../../constraintConfig';

const Error404: FC = () => {
  const { roleID } = useAppSelector(selectAuth);
  const themeModeMenuValue = localStorage.getItem('kt_theme_mode_menu');

  return (
    <div className='container-fluid'>
      <div className="row align-items-center justify-content-center" style={{ height: '100vh' }}>
        <div className="col-md-4">
          <div>
            <img
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/404.png')}
              alt=''
              className='w-100 mb-10 h-lg-550px'
            />

          </div>
        </div>
        <div className="col-md-5">
          <div>
            <h1 className='fs-20px' style={{ fontSize: 42 }}>Oops <br /> <span className='text-primary'>nothing</span> here...</h1>
            <h1 className='fw-semibold mb-10' style={{ color: '#A3A3C7' }}>
              Uh oh, we can't seem to find the page you're looking for. <br />
              Try going back to home page or contact us for more information.
            </h1>
            {roleID === constraintConfig.roleID.role10 ?
              <Link to='/ap-approved' className='btn btn-primary'>
                Return Home
              </Link> :
              roleID === constraintConfig.roleID.roleIDPortalSupport ?
                <Link to='/portal-support-requests' className='btn btn-primary'>
                  Return Home
                </Link>
                :
                <Link to='/' className='btn btn-primary'>
                  Return Home
                </Link>
            }
          </div>
        </div>
      </div>
      {/* <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
          <img
            src={toAbsoluteUrl('/media/illustrations/sketchy-1/18.png')}
            alt=''
            className='mw-100 mb-10 h-lg-450px'
          />
          <h1 className='fw-semibold mb-10' style={{ color: '#A3A3C7' }}>
            Seems there is nothing here
          </h1>

          {roleID === constraintConfig.roleID.role10 ?
            <Link to='/ap-approved' className='btn btn-primary'>
              Return Home
            </Link> :
            roleID === constraintConfig.roleID.roleIDPortalSupport ?
              <Link to='/portal-support-requests' className='btn btn-primary'>
                Return Home
              </Link>
              :
              <Link to='/' className='btn btn-primary'>
                Return Home
              </Link>
          }
        </div>
      </div> */}
    </div>
  )
}

export { Error404 }
