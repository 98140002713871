import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { selectAuth, setUser, stopImpersonating } from '../../../features/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useImpersonateUserMutation } from '../../../services/authApi';
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import { FaCircleCheck } from "react-icons/fa6";

const RevertImpersonateUser = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useAppSelector(selectAuth);
    const [revertImpersonateUser, { data: imersonateData, isLoading, isSuccess, isError, error }] = useImpersonateUserMutation()
    const initialValues = {
        email: ''
    }
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {

            const originalEmail = localStorage.getItem("originalEmail");
            if (originalEmail) {
                revertImpersonateUser({ email: originalEmail });

            } else {
                // console.log("Original email not found in localStorage!");
            }
        }
    })
    useEffect(() => {
        if (isSuccess) {
            dispatch(
                setUser({
                    firstName: imersonateData.result.userAccountBasicInfo.firstName,
                    lastName: imersonateData.result.userAccountBasicInfo.lastName,
                    email: imersonateData.result.userAccountBasicInfo.email,
                    role: imersonateData.result.userAccountBasicInfo.roleName,
                    roleID: imersonateData.result.userAccountBasicInfo.roleID,
                    contactPhone: imersonateData.result.userAccountBasicInfo.cellPhone,
                    businessPhone: imersonateData.result.userAccountBasicInfo.businessPhone,
                    businessExt: imersonateData.result.userAccountBasicInfo.businessExt,
                    userAccountID: imersonateData.result.userAccountBasicInfo.userAccountID,
                    userInOrgId: imersonateData.result.userAccountBasicInfo.userAccountInOrgs[0].userInOrgId,
                    locationType: imersonateData.result.userAccountBasicInfo.locationType.id,
                    expenseType: imersonateData.result.userAccountBasicInfo.expenseType.id,
                    location: imersonateData.result.userAccountBasicInfo.location?.id,
                    locationName: imersonateData.result.userAccountBasicInfo.location?.value,
                    reportPermision: imersonateData.result.userAccountBasicInfo.reportPermision,
                    timeZone: imersonateData.result?.userAccountBasicInfo?.userTimeZone?.title,
                    token: imersonateData.result.token,
                    expiresAt: imersonateData.result.expiresAt,
                    baseURL: imersonateData.result.baseURL,
                    microServiceURL: imersonateData.result.microServiceURL,
                })
            );
            dispatch(stopImpersonating()); // Set isImpersonating to false
            // navigate("/expense-users")
            setTimeout(() => {
                navigate("/expense-users");
            }, 0);
            toast.success(tosterKey.impersonateRevertSuccessfully);
            localStorage.removeItem('originalEmail');
        }
    }, [isSuccess, isError, auth, dispatch]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';
            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            {auth.isImpersonating && localStorage.getItem("originalEmail") && (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5 col-md-7">
                            <div className='position-absolute mt-2'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='bg-light-success text-center px-3 rounded mt-2'>
                                        <FaCircleCheck className="text-success" />
                                        <span className='ms-2'>
                                            {/* {labelKey.impersoatingOtherUser} */}
                                            You are currently impersonating <span className='fw-bold text-primary'>{auth?.firstName} {auth?.lastName}</span>.
                                        </span>
                                        <button type='submit' className='badge badge-light-success fs-7 fw-semibold border-0 ms-3'>
                                            {!isLoading && <>{labelKey.revertIdentity}</>}
                                            {isLoading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    {labelKey.pleaseWait}...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default RevertImpersonateUser