
export const constraintConfig = {
  roleID: {
    role1: 1, // super admin
    role2: 2, // admin
    role3: 3,
    role4: 4, // manager
    role5: 5, // user
    role10: 10, // Ap
    roleIDPortalSupport: 13 // support portal
  },

  expenseStatus: {
    drafted: 1,
    pendingForApproval: 2,
    // clarification: 3,
    approved: 4,
    rejected: 3,
    apApproved: 8,
    processing: 9
  },

  expenseType: {
    regular: 1,
    flex: 2
  },
  expenseCategoryID: {
    mileage: 14
  },
  approverStatus: {
    pending: 1,
    approved: 2,
    rejected: 4,
    submitted: 5,
    accountsPayable: 6
    // clarification:4,
  },


  workflowPolicyType: {
    hierarchy: 1,
    userSpecific: 2,
    directAp: 3
  },

  paymentMethod: {
    cash: 1,
    personalCreditCard: 2,
    corporateCreditCard: 3
  },

  categoriesName: {
    travelExpenses: "Travel Expenses",
    entertainmentExpenses: "Entertainment Expenses",
    officeSuppliesAndStationery: "Office Supplies and Stationery",
    communicationExpenses: "Communication Expenses",
    mealsAndRefreshments: "Meals and Refreshments",
    trainingAndProfessionalDevelopment: "Training and Professional Development",
    marketingAndAdvertising: "Marketing and Advertising",
    utilities: "Utilities ",
    maintenanceAndRepairs: "Maintenance and Repairs",
    professionalServices: "Professional Services",
    insurancePremiums: "Insurance Premiums",
    officeEquipmentandFurniture: "Office Equipment and Furniture",
    miscellaneousExpenses: "Miscellaneous Expenses",
    mileage: "Mileage",
    companyEntertainment: "Company Entertainment",

  },
  categoriesId: {
    other: 1,
    travelExpenses: 16,
    entertainmentExpenses: 2,
    officeSuppliesAndStationery: 3,
    communicationExpenses: 4,
    mealsAndRefreshments: 5,
    trainingAndProfessionalDevelopment: 6,
    marketingAndAdvertising: 7,
    utilities: 8,
    maintenanceAndRepairs: 9,
    professionalServices: 10,
    insurancePremiums: 11,
    officeEquipmentandFurniture: 12,
    miscellaneousExpenses: 13,
    mileage: 14,
    companyEntertainment: 15

  },

  recordSourceId: {
    NotAvailable: 0,
    WebApp: 1,
    Mobile: 2,
    Integrations: 3,
    BankFeed: 4,
  },

  debitOrCreditIndicator: {
    creditIndicator: 'C'
  },

  invoiceStatus: {
    readyToUse: 1,
    failed: 2,
    attached: 3,
    alreadyUsed: 5,
    futureReceipt: 6,
    all: 9
  }
};
