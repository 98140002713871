// import moment from 'moment';
import moment from 'moment-timezone';

import React from 'react';
import { useAppSelector } from '../../hooks';
import { selectAuth } from '../../features/authSlice';

interface Props {
    formattedDate?: string;
}

const DateTimeComp: React.FC<Props> = ({ formattedDate }) => {
    const { timeZone } = useAppSelector(selectAuth);
    
    const safeTimeZone = timeZone || 'UTC';
    // const formattedDateTimeString = formattedDate
    //     ? moment(formattedDate).tz('Etc/GMT-6').format("MM-DD-YYYY HH:mm:ss")
    //     : '';
    const parsedDate = formattedDate
        ? moment.utc(formattedDate) // Parses date as UTC to avoid local time misinterpretations
        : null;
    const formattedDateTimeString = parsedDate
        ? parsedDate.tz(safeTimeZone).format('MM-DD-YYYY hh:mm A')
        : '';
    return (
        <>{formattedDateTimeString}</>
    );
}

export default DateTimeComp;
