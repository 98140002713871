import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from '../features/authSlice';
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};
const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }
    const urlEnd = typeof args === 'string' ? args : args.url
    const adjustedUrl = `${orgBaseUrl}/api/PortalSupport${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const PortalSupportApi = createApi({
    reducerPath: 'PortalSupportApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        portalSupportRequest: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.portalSupportRequest,
                    method: "POST",
                    body,
                }
            },
        }),
        getAllPortalSupportRequest: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getAllPortalSupportRequest}`,
                    method: "GET",
                    params
                }
            },
        }),
        updatePortalSupportRequest: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updatePortalSupportRequest,
                    method: "PUT",
                    body,
                }
            },
        }),
        deleteAttachmentPortalSupportRequest: builder.mutation({
            query: (supportRequestAttachmentId) => {
                return {
                    url: `${API_END_POINTS.deleteAttachmentPortalSupportRequest}?supportRequestAttachmentId=${supportRequestAttachmentId}`,
                    method: "DELETE",
                }
            },
        }),
    }),
})


export const {
    usePortalSupportRequestMutation,
    useGetAllPortalSupportRequestQuery,
    useUpdatePortalSupportRequestMutation,
    useDeleteAttachmentPortalSupportRequestMutation
} = PortalSupportApi