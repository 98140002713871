import moment from 'moment';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Link, useParams } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetExpenseItemByExpenseIdQuery } from '../../../services/ExpenseApi';
import { useGetExpenseToApproveByExpenseIdQuery } from '../../../services/WorkFlowApi';
import ExpenseLogModal from '../expenseItems/ExpenseLogModal';
import UpdateAprroverStatusModal from './UpdateAprroverStatusModal';
import ExpenseDetailModal from '../expenseItems/ExpenseDetailModal';
import labelKey from "../../localization/label.json"
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { FaHistory } from 'react-icons/fa';
import { FaCheck, FaEye } from 'react-icons/fa6';
import { PageTitle } from '../../../_metronic/layout/core';
import ExpenseDetailWithOcrModal from '../expenseItems/ExpenseDetailWithOcrModal';
import { IoIosWarning, IoMdClose } from 'react-icons/io';
import CategoriesWithIcons from '../expenseItems/CategoriesWithIcons';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import DateComp from '../../../components/dateComponent/DateComp';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import { constraintConfig } from '../../../constraintConfig';
interface LightboxData {
    imageURL: string;
}
const SingleApproverPage = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { userAccountID } = useAppSelector(selectAuth);
    const { expenseId, dateTime } = useParams();

    const { data, isLoading,
        refetch: expenseToAproveRefetch } = useGetExpenseToApproveByExpenseIdQuery({
            userAccountId: userAccountID,
            expenseId: expenseId,
            dateTime: dateTime
        });
    const { data: expenseItemData } = useGetExpenseItemByExpenseIdQuery({
        ExpenseId: expenseId,
    })

    const [showInnerTable, setShowInnerTable] = useState(null);

    const handleRowClick = (rowId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
        }
    };

    const [showUpdateApproverStatusModal, setShowUpdateApproverStatusModal] = useState(false)
    const [rejectExpense, setRejectExpense] = useState('')
    const [approvedExpense, setApprovedExpense] = useState('')
    const [editData, setEditData] = useState({});

    const editApproverStatusHandler = () => {
        setShowUpdateApproverStatusModal((prev) => !prev);
    };

    const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
    const [expenseLogData, setExpenseLogData] = useState({});

    const expenseLogHandler = () => {
        setShowExpenseLogModal((prev) => !prev);
    };
    const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false)
    const [expenseDetailData, setExpenseDetailData] = useState({});

    const expenseDetailHandler = () => {
        setShowExpenseDetailModal((prev) => !prev);
    };
    const [showExpenseDetailWithOcrModal, setShowExpenseDetailWithOcrModal] = useState(false)
    const expenseDetailWithOcrHandler = () => {
        setShowExpenseDetailWithOcrModal((prev) => !prev);
    };
    useEffect(() => {
        if (showUpdateApproverStatusModal === false) {
            expenseToAproveRefetch();
        }
        expenseToAproveRefetch();
    }, [showUpdateApproverStatusModal])
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    return (
        <>
            <ExpenseDetailModal show={showExpenseDetailModal} handleClose={() => setShowExpenseDetailModal(false)} data={expenseDetailData} expenseItems={expenseItemData?.result} />
            <UpdateAprroverStatusModal
                show={showUpdateApproverStatusModal}
                handleClose={() => setShowUpdateApproverStatusModal(false)}
                data={editData}
                approvedExpense={approvedExpense}
                rejectExpense={rejectExpense} />
            <ExpenseLogModal show={showExpenseLogModal} handleClose={() => setShowExpenseLogModal(false)} data={expenseLogData} />
            <ExpenseDetailWithOcrModal show={showExpenseDetailWithOcrModal} handleClose={() => setShowExpenseDetailWithOcrModal(false)} data={expenseDetailData} />
            <PageTitle>{labelKey.expenseForApproval}</PageTitle>

            <div className='card mb-5'>
                <div className='card-body py-8'>
                    <div className="row w-100">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='ps-4 rounded-start'>{labelKey.reportId}</th>
                                            <th>{labelKey.reportees}</th>
                                            <th>{labelKey.type}</th>
                                            <th>{labelKey.title}</th>
                                            <th className='w-200px'>{labelKey.description}</th>
                                            <th>{labelKey.totalAmount}</th>
                                            <th>{labelKey.date}</th>
                                            <th>{labelKey.status}</th>
                                            <th>{labelKey.approvalComment}</th>
                                            <th className='text-end rounded-end pe-4'>{labelKey.action}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                <Tooltip id="expand-row" place="bottom" />
                                                <tr
                                                    data-tooltip-id="expand-row" data-tooltip-content={labelKey.clickHereToExpand}
                                                    className={`cursor-pointer ${showInnerTable === data?.result?.expenseId ? 'highlighted-row' : ''}`}
                                                    onClick={() => {
                                                        handleRowClick(data?.result?.expenseId);
                                                    }}>
                                                    <td className='ps-5'>{data?.result?.trackingId}</td>
                                                    <td>
                                                        <Link to={`/user-profile/expense-items/${data?.result?.userAccount?.orgUserID}`}>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='symbol symbol-50px me-5'>
                                                                    {data?.result?.userAccount?.imageUrl ? <img src={data?.result?.userAccount?.imageUrl} alt="" /> :
                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.result?.userAccount?.firstName?.charAt(0)}</div>
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-start flex-column'>
                                                                    <p className='text-capitalize mb-0'>
                                                                        {data?.result?.userAccount?.firstName}  {data?.result?.userAccount?.middleName} {data?.result?.userAccount?.lastName}
                                                                    </p>
                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                        {data?.result?.userAccount?.email}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>{data?.result?.expenseType?.title}</td>
                                                    <td>{data?.result?.title}</td>
                                                    <td>{data?.result?.expenseDetail?.length > 60 ? `${data?.result?.expenseDetail.substring(0, 40)}...` : data?.result?.expenseDetail}</td>
                                                    <td>${data?.result?.amount?.toLocaleString('en-US')}</td>
                                                    <td>{moment(data?.result?.createdDate).format("DD MMM YYYY")}</td>
                                                    <td>
                                                        {data?.result?.approverStatus?.title === "Pending" ?
                                                            <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.pending}</span> :
                                                            data?.result?.approverStatus?.title === "Approved" ?
                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                data?.result?.approverStatus?.title === "Rejected" ?
                                                                    <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                    data?.result?.approverStatus?.title === "Clarification" ?
                                                                        <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                        data?.result?.approverStatus?.title === "Rejected" ?
                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                            data?.result?.approverStatus?.title === "Accounts Payable" ?
                                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.accountsPayable}</span> : ""
                                                        }
                                                    </td>
                                                    <td>{data?.result?.comment && data?.result?.comment.length > 20 ? `${data?.result?.comment.substring(0, 20)}...` : data?.result?.comment}</td>
                                                    <td className='text-end' >
                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                            <Tooltip id="my-tooltip" place="top" />
                                                            <Tooltip id="expense-violation" place="top" />
                                                            {data?.result?.isViolation === true &&
                                                                <div
                                                                    data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                    onClick={() => {
                                                                        setExpenseDetailData(data);
                                                                        expenseDetailWithOcrHandler();
                                                                    }}
                                                                >
                                                                    <IoIosWarning className='text-danger h-15px w-15px' />
                                                                </div>
                                                            }
                                                            {data?.result?.approverStatus?.id === 1 && (
                                                                // <div
                                                                //     data-tooltip-id="my-tooltip" data-tooltip-content={labelKey.updateExpenseStatus}
                                                                //     className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                //     onClick={() => {
                                                                //         setEditData(data?.result);
                                                                //         editApproverStatusHandler();
                                                                //     }}
                                                                // >
                                                                //     <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                // </div>
                                                                <>
                                                                    <div
                                                                        data-tooltip-id="my-tooltip"
                                                                        data-tooltip-content='Approve Expense Report'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                        onClick={() => {
                                                                            setEditData(data?.result);
                                                                            setApprovedExpense('approved')
                                                                            setRejectExpense('');
                                                                            editApproverStatusHandler();
                                                                        }}
                                                                    >
                                                                        <FaCheck className='h-14px w-14px' />
                                                                    </div>
                                                                    <div
                                                                        data-tooltip-id="my-tooltip"
                                                                        data-tooltip-content='Reject Expense Report'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                        onClick={() => {
                                                                            setEditData(data?.result);
                                                                            setRejectExpense('rejected')
                                                                            setApprovedExpense('');
                                                                            editApproverStatusHandler();
                                                                        }}
                                                                    >
                                                                        <IoMdClose className='h-15px w-15px' />
                                                                    </div>
                                                                </>
                                                            )}

                                                            <Tooltip id="expense-log" place="top" />

                                                            <div
                                                                data-tooltip-id="expense-log" data-tooltip-content={labelKey.expenseLogs}

                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                onClick={() => {
                                                                    setExpenseLogData(data?.result?.expenseId);
                                                                    expenseLogHandler();
                                                                }}
                                                            >
                                                                <FaHistory className='text-dark text-hover-primary' />
                                                            </div>

                                                            <Tooltip id="expense-detail" place="top" />

                                                            <div
                                                                data-tooltip-id="expense-detail" data-tooltip-content={labelKey.expenseDetails}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                onClick={() => {
                                                                    setExpenseDetailData(data?.result);
                                                                    expenseDetailHandler();
                                                                }}
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {showInnerTable === data?.result?.expenseId && (
                                                    <tr className={`inner-row ${showInnerTable === data?.result?.expenseId ? 'highlighted-row' : ''}`}>
                                                        <td className='pt-0 px-3' colSpan={10}>
                                                            <div className="table-responsive">
                                                                <table className="table align-middle gs-0 gy-4">
                                                                    <thead>
                                                                        <tr className='fw-bold text-muted bg-light'>
                                                                            <th className='ps-4'>{labelKey.itemDate}</th>
                                                                            <th>{labelKey.category}</th>
                                                                            <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                            <th className='w-150px pe-15'>{labelKey.reimbursableDistance}</th>
                                                                            <th>{labelKey.vendor}</th>
                                                                            <th>{labelKey.paymentMethod}</th>
                                                                            <th>{labelKey.attachment}</th>
                                                                            <th>{labelKey.description}</th>
                                                                            <th>{labelKey.source}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                                            <tr key={index}>
                                                                                <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                <td className='ps-5'>
                                                                                    <CategoriesWithIcons itemData={itemData} />
                                                                                </td>
                                                                                <td className='text-end pe-15'>
                                                                                    <CurrencyComp amount={itemData?.amount} />
                                                                                </td>
                                                                                <td className='text-end pe-15'>
                                                                                    {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                        <>
                                                                                            {itemData?.reimbursableDistance}
                                                                                        </>
                                                                                        : null
                                                                                    }
                                                                                </td>
                                                                                <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                <td>{itemData?.paymentMethod?.title}</td>
                                                                                <td>
                                                                                    {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null || itemData?.attachmentFile === "" ?
                                                                                        <span className='ps-9'>N/A</span> :
                                                                                        <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                    }

                                                                                    {lightboxOpen && (
                                                                                        <Lightbox
                                                                                            mainSrc={lightboxData.imageURL}
                                                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                                                            imageCaption="Attachment"
                                                                                            enableZoom={true}
                                                                                            imagePadding={50}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                                <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                <td>
                                                                                    <Tooltip id="web-source" place="top" />
                                                                                    <Tooltip id="mobile-source" place="top" />
                                                                                    <Tooltip id="bank-source" place="top" />
                                                                                    {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                        <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                            svgClassName='w-20px h-20px'
                                                                                            data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                        :
                                                                                        itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                            <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                    data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                : null}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleApproverPage