
import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import TextField from '../../../../components/textFields/TextField';
import { useDepartmentsLookupQuery } from '../../../../services/GeneralApi';
import { useAddDepartmentLocationMutation, useAddLocationMutation, useUpdateDepartmentLocationMutation, useUpdateLocationMutation } from '../../../../services/OrgSettingsApi';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";
import {baseUrl} from '../../../../services/baseUrl'
import {
    Autocomplete,
    Libraries,
    useJsApiLoader
} from '@react-google-maps/api';

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}

const AddUpdateLocationModal = ({ show, handleClose, data }: Props) => {
   
    const [addCard, { isLoading, isSuccess, isError, error }] = useAddLocationMutation()
    const [updateCard, { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateLocationMutation()

    const initialValues = {
        locationId: 0,
        locationName: '',
        description: '',
        latitude: '',
        longitude: '',
        isActive: true,
        locationCode: '',
        address: '',
    }

    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.locationId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);

    const validationSchema = Yup.object().shape({
        locationName: Yup.string().required('Location Name is required'),
        // latitude: Yup.string().nullable().required('Latitude is required'),
        // longitude: Yup.string().nullable().required('Longitude is required'),
        locationCode: Yup.string().nullable().required('Location code is required'),
        description: Yup.string().nullable().max(250, 'Description must be at most 250 characters long'),
        address: Yup.string().nullable().required('Address is required'),


    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateCard(values)
            } else {
                addCard(values)
            }
        }
    })
    const { resetForm } = formik;


    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);

    const libraries: Libraries = useMemo(() => ['places'], []);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: baseUrl.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });
    const originRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, ref: React.RefObject<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (ref.current && ref.current.value !== '') {
                if (ref === originRef) {

                    formik.setFieldValue(`address`, ref.current.value);
                    // console.log('Selected value (Start Location):', ref.current.value);
                }
            }
        }
    };

    const handleLocationChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        ref: React.RefObject<HTMLInputElement>
    ) => {
        // Update Formik values based on the location type (startLocation or endLocation)
        const updatedLocation = event.target.value;

        if (ref === originRef) {
            formik.setFieldValue(`address`, updatedLocation);
        }
    };
    const [selectedLatitude, setSelectedLatitude] = useState('');
    const [selectedLongitude, setSelectedLongitude] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');

    const originAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const handleStartPlaceChanged = () => {
        const place = originAutocompleteRef.current?.getPlace(); // Get the selected place

        // console.log('place', place);

        if (place && place.geometry && place.geometry.location) {
            const selectedLat = place.geometry.location.lat();
            const selectedLng = place.geometry.location.lng();
            const selectedAddressByMap = place?.formatted_address || '';


            // Update state variables
            setSelectedLatitude(selectedLat.toString());
            setSelectedLongitude(selectedLng.toString());
            setSelectedAddress(selectedAddressByMap);


            // Log to the console
            // console.log('Selected Latitude:', selectedLat);
            // console.log('Selected Longitude:', selectedLng);

            formik.setFieldValue('latitude', selectedLat.toString());
            formik.setFieldValue('longitude', selectedLng.toString());
            formik.setFieldValue(`address`, selectedAddressByMap);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.locationCreatedSuccessfully);
            handleClose();
            resetForm()
            setSelectedLatitude('')
            setSelectedLongitude('')
            setSelectedAddress('')
        }
    }, [isSuccess]);
    useEffect(() => {
        if (updateIsSuccess) {
            toast.success(tosterKey.locationUpdatedSuccessfully);
            handleClose();
        }
    }, [updateIsSuccess]);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
        setSelectedLatitude('')
        setSelectedLongitude('')
        setSelectedAddress('')
        
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateLocation} - ({formik.values.locationCode})</> : <>{labelKey.addLocation}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        formik.setTouched({
                            locationName: true,
                            // latitude: true,
                            // longitude: true,
                            locationCode: true,
                            description: true,
                            address:true

                        }, true); // Set touched for all fields to trigger validation display
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                           
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.locationCode}
                                    required={true}
                                    type='text'
                                    placeholder='Enter a Location Code'
                                    {...formik.getFieldProps('locationCode')}
                                    value={formik.values.locationCode || ''}
                                />
                                {formik.touched.locationCode && formik.errors.locationCode && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.locationCode}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.name}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Location Name'
                                    {...formik.getFieldProps('locationName')}
                                    value={formik.values.locationName || ''}
                                />
                                {formik.touched.locationName && formik.errors.locationName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.locationName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 mb-5'>

                                <label className='fs-5 fw-bold mb-2 required'>
                                    {labelKey.address}
                                </label>
                                {isLoaded && (
                                    <Autocomplete
                                        onLoad={(autocomplete) => {
                                            originAutocompleteRef.current = autocomplete;
                                            autocomplete.addListener('place_changed', handleStartPlaceChanged);
                                        }}
                                        onPlaceChanged={() => {
                                        }}>
                                        <input type='text'
                                            className='form-control form-control-lg form-control-solid default-input'
                                            placeholder='Enter a Address'
                                            ref={originRef}
                                            onKeyDown={(event) => handleKeyDown(event, originRef)}
                                            onChange={(event) => handleLocationChange(event, originRef)}
                                            value={formik.values.address || selectedAddress}
                                        />
                                    </Autocomplete>
                                )}
                                {formik.touched.address && formik.errors.address && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.address}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.latitude}
                                    // required={true}
                                    type='text'
                                    placeholder='Enter Latitude'
                                    {...formik.getFieldProps('latitude')}
                                    value={formik.values.latitude || selectedLatitude}
                                    disabled={true}
                                />
                                {/* {formik.touched.latitude && formik.errors.latitude && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.latitude}</span>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.longitude}
                                    // required={true}
                                    type='text'
                                    placeholder='Enter Longitude'
                                    {...formik.getFieldProps('longitude')}
                                    value={formik.values.longitude || selectedLongitude}
                                    disabled={true}
                                />
                                {/* {formik.touched.longitude && formik.errors.longitude && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.longitude}</span>
                                        </div>
                                    </div>
                                )} */}
                            </div>

                            <div className='col-md-12 mb-5'>
                                <TextAreaField
                                    label={labelKey.description}
                                    placeholder='Enter Discription'
                                    rows={5}
                                    {...formik.getFieldProps('description')}
                                    value={formik.values.description || ''}
                                />
                                {formik.touched.description && formik.errors.description && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.description}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

                            {updateId ?
                                <>
                                    {updateIsLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateLocationModal