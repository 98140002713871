import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetAllCountryQuery } from '../../../../services/OrgSettingsApi';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import labelKey from '../../../localization/label.json';
import AddUpdateCountriesModal from './AddUpdateCountriesModal';
import CountryActiveConfirmationModal from './CountryActiveConfirmationModal';

const CountriesPage = () => {

  const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'countries' });

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState(''); // debounce search
  const [sortBy, setSortBy] = useState("createDT");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedColumn, setSortedColumn] = useState("createDT");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [immediateSearchStr, setImmediateSearchStr] = useState('');
  const [isChecked, setIsChecked] = useState(false)

  const { data, isLoading, refetch } = useGetAllCountryQuery({
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchStr: searchStr,
    sortBy: sortBy,
    sortOrder: sortOrder,
    filterByStatus: filterByStatus
  })
  const [showAddUpdateCountryModal, setShowAddUpdateCountryModal] = useState(false)
  const [showCountryEdit, setShowCountryEdit] = useState({})
  const countryModalHandler = () => {
    setShowAddUpdateCountryModal((prev) => !prev);
  };
  const [showDepartmentActiveConfirmationModal, setShowDepartmentActiveConfirmationModal] = useState(false)
  const departmentActiveConfirmationHandler = () => {
    setShowDepartmentActiveConfirmationModal((prev) => !prev);
  };
  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPageNumber(1); // Reset page number to 1
  };
  // pagination
  const handlePageClick = (e: any) => {
    setPageNumber(e.selected + 1);
  };
  //per page record
  const handlePageRecords = (e: any) => {
    setPageNumber(1); // Reset the page to 1
  };
  const handleSort = (property: string) => {
    setSortBy(property);
    if (sortedColumn === property) {
      setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(property);
      setSortOrder("asc");
    }
    // Perform additional sorting logic...
  };
  const handleSearchChange = (e: any) => {
    const { value } = e.target;
    setImmediateSearchStr(value); // Update immediate search state
    delayedSearch(value); // Update debounced search state
  };

  const delayedSearch = useCallback(
    debounce((searchValue) => {
      setSearchStr(searchValue);
    }, 500),
    []
  );
  useEffect(() => {
    if (searchStr === "" ||
      sortOrder === "" ||
      sortBy === "") {
      refetch();
    }
  }, [searchStr, sortOrder, sortBy]);
  const [prevModalState, setPrevModalState] = useState({
    addUpdateCountryModal: false,
    departmentActiveConfirmationModal: false,
  });
  // Update previous modal state whenever the modals change
  useEffect(() => {
    setPrevModalState({
      addUpdateCountryModal: showAddUpdateCountryModal,
      departmentActiveConfirmationModal: showDepartmentActiveConfirmationModal,
    });
  }, [showAddUpdateCountryModal, showDepartmentActiveConfirmationModal]);

  // Check for modal closing and trigger refetch
  useEffect(() => {
    if (
      (prevModalState.addUpdateCountryModal && !showAddUpdateCountryModal) ||
      (prevModalState.departmentActiveConfirmationModal && !showDepartmentActiveConfirmationModal)
    ) {
      refetch();
    }
  }, [
    showAddUpdateCountryModal,
    showDepartmentActiveConfirmationModal,
    prevModalState.addUpdateCountryModal,
    prevModalState.departmentActiveConfirmationModal,
    refetch,
  ]);

  return (
    <>
      <AddUpdateCountriesModal
        show={showAddUpdateCountryModal}
        handleClose={() => setShowAddUpdateCountryModal(false)}
        data={showCountryEdit} />
      <CountryActiveConfirmationModal
        show={showDepartmentActiveConfirmationModal}
        handleClose={() => setShowDepartmentActiveConfirmationModal(false)}
        data={showCountryEdit}
        isChecked={isChecked} />

      <div className='card mb-5'>
        <div className='card-header border-0 pt-6'>
          <SearchBarComponent
            placeholder='Countries'
            value={immediateSearchStr}
            onChange={handleSearchChange}
          />
          <div className='d-flex align-items-center ms-auto'>
            <div className='card-toolbar'>
              <button className='btn btn-sm btn-light-primary'
                onClick={() => {
                  setShowCountryEdit(true);
                  countryModalHandler();
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                {labelKey.addCountry}
              </button>
            </div>
          </div>
        </div>
        <div className='card-body py-8'>
          <div className="col-md-12">
            <div className='table-responsive table-height'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    {hiddenColumns.includes('sr') && (
                      <TableHeading
                        label={labelKey.sr}
                        columnId='sr'
                        className="ps-4"
                      />
                    )}
                    {hiddenColumns.includes('countryName') && (
                      <TableHeading
                        label={labelKey.countryName}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='countryName'
                      />
                    )}
                    {hiddenColumns.includes('countryCode') && (
                      <TableHeading
                        label={labelKey.countryCode}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='countryCode'
                      />
                    )}
                    {hiddenColumns.includes('isActive') && (
                      <TableHeading
                        label={labelKey.status}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='isActive'
                      />
                    )}
                    <th className='text-end rounded-end pe-2 border-0'>
                      {hiddenColumns.includes('actions') && (
                        <>
                          {labelKey.actions}
                        </>
                      )}
                      <TableSettingMenu>
                        <TextField
                          rightLabel={labelKey.sr}
                          type="checkbox"
                          onChange={() => handleToggleColumn("sr")}
                          checked={hiddenColumns.includes('sr')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.countryName}
                          type="checkbox"
                          onChange={() => handleToggleColumn("countryName")}
                          checked={hiddenColumns.includes('countryName')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.countryCode}
                          type="checkbox"
                          onChange={() => handleToggleColumn("countryCode")}
                          checked={hiddenColumns.includes('countryCode')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.status}
                          type="checkbox"
                          onChange={() => handleToggleColumn("isActive")}
                          checked={hiddenColumns.includes('isActive')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.actions}
                          type="checkbox"
                          onChange={() => handleToggleColumn("actions")}
                          checked={hiddenColumns.includes('actions')}
                          fieldClass='mb-4'
                        />
                      </TableSettingMenu>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    <>
                      {data?.result?.data?.length > 0 ? (
                        <>
                          {data?.result?.data?.map((data: any, index: any) => (
                            <tr key={index}>
                              {hiddenColumns.includes('sr') && (
                                <td className='ps-4'>{(pageNumber - 1) * pageSize + index + 1}</td>
                              )}
                              {hiddenColumns.includes('countryName') && (
                                <td>{data?.countryName}</td>
                              )}
                              {hiddenColumns.includes('countryCode') && (
                                <td>{data?.countryCode}</td>
                              )}
                              {hiddenColumns.includes('isActive') && (
                                <td>
                                  <div className='d-flex align-items-center gap-2'>
                                    {data?.isActive === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                      <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        role="switch"
                                        id={`flexSwitchCheckChecked-${data?.countryId}`}
                                        checked={data?.isActive}
                                        onChange={(e) => {
                                          setIsChecked(e.target.checked);
                                          setShowCountryEdit(data);
                                          departmentActiveConfirmationHandler();
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.countryId}`}></label>
                                    </div>
                                  </div>
                                </td>
                              )}
                              {hiddenColumns.includes('actions') && (
                                <td>
                                  <div className='d-flex align-items-center justify-content-end gap-2' >
                                    <Tooltip id="update-country" place="bottom" />
                                    <div
                                      data-tooltip-id="update-country" data-tooltip-content={labelKey.updateCountry}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      onClick={() => {
                                        setShowCountryEdit(data);
                                        countryModalHandler();
                                      }}
                                    >
                                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </>) : (
                        <tr>
                          <td colSpan={7}>
                            <NoRecordFound />
                          </td>
                        </tr>
                      )}
                    </>}
                  {isLoading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        <LoadingComponent />
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            <Pagination
              totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
              toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
              ofResult={data?.result?.totalRecords}
              onChange={handlePageSizeChange}
              pageSize={pageSize}
              onPageChange={handlePageClick}
              pageCount={data?.result?.totalPages || 0}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CountriesPage