import { Suspense, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout, selectAuth, setUser } from '../features/authSlice';
import { useAppDispatch } from '../hooks';
import { useRenewTokenMutation } from '../services/authApi';
import { store } from '../store';
import RevertImpersonateUser from './pages/users/RevertImpersonateUser';
import { useFormik } from 'formik';

const App = () => {
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tokenRenew, { data, isSuccess }] = useRenewTokenMutation();
  const [userActive, setUserActive] = useState(true);

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    onSubmit: (values) => {
      // This is where you can handle token renewal
      if (userActive) {
        tokenRenew({ ...values });
      } else {
        // Handle the case where there's no user activity
        handleNoUserActivity();
      }
    },
  });

  // Function to handle logout when there's no user activity and the token has expired
  const handleNoUserActivity = () => {
    if (auth.expiresAt) {
      const currentTime = new Date();
      const expirationTime = new Date(auth.expiresAt);

      if (currentTime > expirationTime) {
        // Token has expired, perform logout and navigate to the home page
        store.dispatch(logout());
        navigate('/');
        toast.error('Session Expired, Please login again');
      }
    }
  };

  useEffect(() => {
    const handleUserActivity = () => {
      setUserActive(true);
    };

    const resetUserActivityTimer = () => {
      setUserActive(false);
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up event listeners
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // This is where you can handle token renewal based on the interval
      tokenRenew({ ...formik.values });
    }, 600000); // 10 minutes in milliseconds
    return () => {
      clearInterval(intervalId); // Clean up the interval
    };
  }, [formik.values, tokenRenew]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setUser({
          firstName: data.result.userAccountBasicInfo.firstName,
          lastName: data.result.userAccountBasicInfo.lastName,
          email: data.result.userAccountBasicInfo.email,
          role: data.result.userAccountBasicInfo.roleName,
          roleID: data.result.userAccountBasicInfo.roleID,
          contactPhone: data.result.userAccountBasicInfo.cellPhone,
          businessPhone: data.result.userAccountBasicInfo.businessPhone,
          businessExt: data.result.userAccountBasicInfo.businessExt,
          userAccountID: data.result.userAccountBasicInfo.userAccountID,
          userInOrgId: data.result.userAccountBasicInfo.userAccountInOrgs[0].userInOrgId,
          locationType: data.result.userAccountBasicInfo.locationType.id,
          expenseType: data.result.userAccountBasicInfo.expenseType.id,
          location: data.result.userAccountBasicInfo.location?.id,
          locationName: data.result.userAccountBasicInfo.location?.value,
          reportPermision: data.result.userAccountBasicInfo.reportPermision,
          timeZone: data.result?.userAccountBasicInfo?.userTimeZone?.title,
          token: data.result.token,
          expiresAt: data.result.expiresAt,
          baseURL: data.result.baseURL,
          microServiceURL: data.result.microServiceURL,
        })
      );
    }
  }, [isSuccess]);
  useEffect(() => {
    // Check if token has expired
    if (auth.expiresAt) {
      const currentTime = new Date();
      const expirationTime = new Date(auth.expiresAt);
      if (currentTime > expirationTime) {
        // Token has expired, perform logout and navigate to home page
        store.dispatch(logout());
        navigate('/');
        toast.error('Session Expired, Please login again');
      }
    }
  }, [auth.expiresAt, navigate]);

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logoutChannel');
    // Listen for logout messages from other tabs
    logoutChannel.addEventListener('message', (event) => {
      if (event.data === 'logout') {
        // Handle the logout action here
        // Example: dispatch(logout());
        dispatch(logout());
      }
    });
    // Clean up the channel when the component unmounts
    return () => {
      logoutChannel.close();
    };
  }, []);
  return (
    <>
      {auth.isImpersonating && <RevertImpersonateUser />}
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  );
};

export { App };