import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useUpdateStatusByApMutation } from '../../../../services/ExpenseApi'
import { KTSVG } from '../../../../_metronic/helpers'
import lablelKey from '../../../localization/label.json'
import { useSyncExpensesSSToBCMutation } from '../../../../services/BCIntegrationApi'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
}

const SynxExpenseWithBCModal = ({ show, handleClose, data }: Props) => {
    const [syncWithBC, {data:syncWithBCData ,isLoading, isSuccess, isError, error }] = useSyncExpensesSSToBCMutation()
    const apApproved = async (expenseId: any) => {
        try {
            // Make the API call to update the expense status
            const response = await syncWithBC({ expenseId: expenseId });

            // Handle the response, e.g., show a success message or update the expense list

            // Refetch the data to update the expense list
        } catch (error) {
            // Handle the error, e.g., show an error message
            console.error("Failed to update expense status", error);
        }
        handleClose();
    };
    useEffect(() => {
        if (isSuccess) {
            const responseData = (syncWithBCData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
        }
    }, [isSuccess, syncWithBCData]);


    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
  return (
    <>
    <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-md modal-dialog-centered'
        show={show}
        onHide={handleClose}
    >
        <div className='modal-header bg-light py-4'>
            <h2 className='mb-0'>{lablelKey.syncWithBc}</h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
            {/* end::Close */}
        </div>
        <div className='modal-body py-lg-6 px-lg-6'>
            <p className='fs-5 fw-semibold'>{lablelKey.areYouSureYouWantToSyncWithBc}.</p>
        </div>
        <div className='modal-footer justify-content-center py-4'>
            <button className='btn btn-light' onClick={handleClose}>{lablelKey.cancel}</button>
            <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-primary'
                onClick={() => apApproved(data)}
                disabled={isLoading}
            >
                {!isLoading && <span className='indicator-label'>{lablelKey.yes}</span>}
                {isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        {lablelKey.pleaseWait}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </button>
        </div>
    </Modal>
</>
  )
}

export default SynxExpenseWithBCModal