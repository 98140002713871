import React, { useEffect, useState } from 'react'
import { useGetUserDashboardQuery } from '../../../services/DashboardApi';
import { KTSVG } from '../../../_metronic/helpers';
import MixedStatsWidget from './MixedStatsWidget';
import BudgetPieChart from './BudgetPieChart';
import ApprovedPieChart from './ApprovedPieChart';
import PendingExpensesPieChart from './PendingExpensesPieChart';
import labelKey from "../../localization/label.json";
import { allRoutes } from '../../routing/All_Routes';

const DashboardByUser = (getUserDashboard: any) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        toState1={allRoutes.expenseReports}
                        title={labelKey.pendingExpenses}
                        titleIcon='/media/figma-download/dollarNote.svg'
                        stateIcon1='/media/icons/duotune/general/gen043.svg'
                        statTitle1={labelKey.numberOfExpenses}
                        statsNum1={`${getUserDashboard?.getUserDashboard?.result?.allExpense}`}
                        stateIcon2='/media/icons/duotune/finance/fin010.svg'
                        statTitle2={labelKey.totalExpensesAmount}
                        statsNum2={`$${getUserDashboard?.getUserDashboard?.result?.totalExpenseAmount}`}
                    >
                        <PendingExpensesPieChart getUserDashboard={getUserDashboard?.getUserDashboard} />
                    </MixedStatsWidget>
                </div>
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        toState1={`${allRoutes.expenseReports}/apApproved`}
                        title={labelKey.approvedExpenses}
                        titleIcon='/media/figma-download/bag.svg'
                        stateIcon1='/media/icons/duotune/general/gen043.svg'
                        statTitle1={labelKey.apApprovedExpenses}
                        statsNum1={`${getUserDashboard?.getUserDashboard?.result?.apApprovedExpense}`}
                        stateIcon2='/media/icons/duotune/finance/fin010.svg'
                        statTitle2={labelKey.totalApApprovedAmount}
                        statsNum2={`$${getUserDashboard?.getUserDashboard?.result?.totalAPApprovedAmount}`}
                    >
                        <ApprovedPieChart getUserDashboard={getUserDashboard?.getUserDashboard} />
                    </MixedStatsWidget>
                </div>
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        bothLink='/budget-history'
                        toState1='/budget-history'
                        toState2='/budget-history'
                        title={labelKey.budget}
                        titleIcon='/media/figma-download/dollar.svg'
                        stateIcon1='/media/icons/duotune/finance/fin010.svg'
                        statTitle1={labelKey.allocatedBudget}
                        statsNum1={`$${getUserDashboard?.getUserDashboard?.result?.totalAllocatedBudget}`}
                        stateIcon2='/media/icons/duotune/finance/fin010.svg'
                        statTitle2={labelKey.utilizedBudget}
                        statsNum2={`$${getUserDashboard?.getUserDashboard?.result?.totalUtilizedBudget}`}
                    >
                        <BudgetPieChart getUserDashboard={getUserDashboard?.getUserDashboard} />
                    </MixedStatsWidget>
                </div>
            </div>
        </>
    )
}

export default DashboardByUser