/* eslint-disable jsx-a11y/anchor-is-valid */
import { constraintConfig } from "../../../../../constraintConfig"
import labelKey from "../../../../localization/label.json"

type Props = {
  formik: any
  getAllRolesData: any
}
const Step4 = ({ formik, getAllRolesData }: Props) => {
  return (
    <>
      <div data-kt-stepper-element='content' className="overflow-auto overflow-x-hidden" style={{ height: "51vh" }}>
        <div className='w-100'>
          <h1 className='fw-bold text-gray-800 mb-15 text-center'>{labelKey.review}</h1>

          <div className="row">
            <div className="col-md-12">
              <h3 className="mb-5">User Details</h3>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-6">
              <div className="row d-flex align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.role}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.userRole.roleName}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.allowedExpenseType}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.expenseType?.value}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.allowedMileageType}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.locationType?.value}</span>
                </div>
              </div>
              {String(formik.values.userRole.roleID) !== constraintConfig.roleID.role2.toString() &&
                String(formik.values.userRole.roleID) !== constraintConfig.roleID.role10.toString() &&
                String(formik.values.userRole.roleID) !== constraintConfig.roleID.roleIDPortalSupport.toString() &&
                <div className="row align-items-center mb-3">
                  <div className="col-md-3 col-lg-4">
                    <p className="fw-bold fs-6 mb-0">{labelKey.manager}:</p>
                  </div>
                  <div className="col-md-8">
                    <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.managerName}</span>
                  </div>
                </div>
              }
              {String(formik.values.userRole.roleID) === constraintConfig.roleID.role4.toString() &&
                <div className="row align-items-center mb-3">
                  <div className="col-md-3 col-lg-4">
                    <p className="fw-bold fs-6 mb-0">{labelKey.approval$Limit}:</p>
                  </div>
                  <div className="col-md-8">
                    <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.mgrApproveMaxLimit}</span>
                  </div>
                </div>
              }
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.officeLocation}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.location?.value}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.department}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.department?.title}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.division}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.division?.title}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.designation}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.designation?.title}</span>
                </div>
              </div>
              {String(formik.values.userRole.roleID) === constraintConfig.roleID.role4.toString() ||
                String(formik.values.userRole.roleID) === constraintConfig.roleID.role10.toString() ?
                <div className="row align-items-center mb-3">
                  <div className="col-md-4">
                    <p className="fw-bold fs-6 mb-0">{labelKey.orgReportsPermision}:</p>
                  </div>
                  <div className="col-md-8">
                    <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.reportPermision === true ?
                      <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.allowed}</span> :
                      <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.notAllowed}</span>}</span>
                  </div>
                </div>
                : null
              }

            </div>
            <div className="col-md-12 col-lg-6 col-xl-6">
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.email}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.email}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.employeeId}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.employeeId}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.bcEmployeeId}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.bcEmployeeId}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.firstName}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.firstName}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.middleName}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.middleName}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.lastName}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.lastName}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.cellPhone}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.cellPhone}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.businessPhone}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.businessPhone}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.businessExt}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.businessExt}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4 col-xl-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.timeZone}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.userTimeZone?.title}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-6">
              <h3 className="mb-5">Address Detail</h3>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.findAddress}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.findAddress}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.addressLine1}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.addressLine1}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.addressLine2}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.addressLine2}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.latitude}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.latitude}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.longitude}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.longitude}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.country}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.country?.title}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.city}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values.city}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.state}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.state}</span>
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-md-3 col-lg-4">
                  <p className="fw-bold fs-6 mb-0">{labelKey.postalCode}:</p>
                </div>
                <div className="col-md-8">
                  <span className="fs-7 text-dark form-control form-control-sm form-control-solid default-input">{formik.values?.postalCode}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export { Step4 };

