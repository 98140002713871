import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const OrgSettingPages = () => {
    const location = useLocation()

    return (
        <div className='card' style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            <div className='d-flex overflow-auto h-55px px-10 border-bottom'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap'>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === '/settings/org-settings/email-configuration' && 'active')
                            }
                            to='/settings/org-settings/email-configuration'
                        >
                            Email Configuration
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === '/settings/org-settings/cache-clear' && 'active')
                            }
                            to='/settings/org-settings/cache-clear'
                        >
                           Cache Clear Setting
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default OrgSettingPages