import { PageTitle } from '../../../_metronic/layout/core';
import labelKey from "../../localization/label.json";
import ExpenseSubmission from './ExpenseSubmission';

const ExpenseSubmissionWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{labelKey.expenseWizard}</PageTitle>
      <ExpenseSubmission />
    </>
  )
}

export default ExpenseSubmissionWrapper