/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import { constraintConfig } from '../../../../constraintConfig'
import { selectAuth } from '../../../../features/authSlice'
import { useAppSelector } from '../../../../hooks'
import { useGetUserNotificationCountQuery } from '../../../../services/WorkFlowApi'
import { API_END_POINTS } from '../../../../services/apiEndpoints'
import { useGetSingleUserQuery } from '../../../../services/authApi'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  ThemeModeSwitcher
} from '../../../partials'
import { KTSVG } from '../../../helpers'
import { IoMdHelpCircleOutline } from 'react-icons/io'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { token, userAccountID, roleID, baseURL } = useAppSelector(selectAuth);
  const { data: userDetail, refetch } = useGetSingleUserQuery(userAccountID);
  const [notifications, setNotifications] = useState([]);

  const { data: getNotiCount, refetch: notiCountRefetch } = useGetUserNotificationCountQuery({ userAccountId: userAccountID });

  // Function to fetch notifications
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/Workflow${API_END_POINTS.getUserNotification}?userAccountId=${userAccountID}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Replace 'Bearer' with your actual authentication method if needed
        },
      });
      setNotifications(response.data); // Assuming the API response is an array of notifications
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  // Click event handler for the bell icon
  const handleBellIconClick = () => {
    fetchNotifications();
  };
  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/media/avatars/blank.png';
  };
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0 '>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}
        {/* Activities */}
        {/* <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
        </div> */}
        {/* System Setting */}
        {/* {roleID === constraintConfig.roleID.role2 &&
          <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
            <div
              className={clsx(
                'btn btn-icon btn-active-light-primary btn-custom hover-warning',
                toolbarButtonHeightClass
              )}
              id='kt_activities_toggle'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen062.svg'
                className={toolbarButtonIconSizeClass}
              />
            </div>
          </div>} */}

        {/* NOTIFICATIONS */}
        {(roleID !== constraintConfig.roleID.role1 &&
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <div
              className={clsx(
                'btn btn-icon btn-active-light-primary btn-custom hover-warning',
                toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={handleBellIconClick}
            >
              {/* <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            /> */}
              {/* <i className='fa fa-bell'></i> */}
              <FaBell className="fs-3 text-white" />
              {getNotiCount?.result !== 0 &&
                <div className='d-flex align-item-center justify-content-center text-white mb-5 '>{getNotiCount?.result ? getNotiCount?.result :
                  <span className='fs-6 text-white'>0</span>}</div>}
            </div>

            <HeaderNotificationsMenu notifications={notifications} notiCountRefetch={notiCountRefetch} />
          </div>
        )}


        {/* CHAT */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div>
        </div> */}

        {/* Quick links */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
        </div> */}

        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}

        {/* begin::User */}
        <div className='d-flex align-items-center'>
          <div className='ms-lg-3 ms-1 d-none d-md-block'>
            <p className='fw-bold fs-7 mb-0 username'>Welcome</p>
            <p className='fw-bold fs-7 mb-0 username'>{userDetail && userDetail.result.firstName} </p>
            {/* <p className='fw-bold fs-8 mb-0 username'>{userDetail && userDetail.result.userRole?.roleName} </p> */}
          </div>
          <div
            className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
            id='kt_header_user_menu_toggle'
          >
            {/* begin::Toggle */}
            <div
              className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <img
                className='h-30px w-30px rounded'
                src={userDetail?.result?.imageUrl ? userDetail?.result?.imageUrl : '/media/avatars/blank.png'} alt='' onError={handleError}
              />
            </div>
            <HeaderUserMenu />
            {/* end::Toggle */}
          </div>
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export { Topbar }

