
export const topics = [
    {
        id: 'Question',
        value: 'Question'
    },
    {
        id: 'Issue',
        value: 'Issue'
    }
]

