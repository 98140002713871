import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/ReportAndAnalytics${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const ReportAndAnalyticsApi = createApi({
    reducerPath: 'ReportAndAnalyticsApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + '/api/ReportAndAnalytics/',
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }
    //         return headers;
    //     },
    // }),
    endpoints: (builder) => ({
        expenseSummary: builder.query({
            query: ({ userAccountId, startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseSummaryByUserId}?userAccountId=${userAccountId}&startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        expenseByCategory: builder.query({
            query: ({ userAccountId, categoryId, startDate, endDate, pageNumber, pageSize, searchstr }) => {
                return {
                    url: `${API_END_POINTS.getExpenseByCategory}?userAccountId=${userAccountId}&categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchstr=${searchstr}`,
                    method: "GET"
                }
            },
        }),
        expenseByPayment: builder.query({
            query: ({ userAccountId, paymentMethod, startDate, endDate, pageNumber, pageSize, searchstr }) => {
                return {
                    url: `${API_END_POINTS.getExpenseByPaymentMethod}?userAccountId=${userAccountId}&paymentMethod=${paymentMethod}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchstr=${searchstr}`,
                    method: "GET"
                }
            },
        }),
        expenseApproverSummery: builder.query({
            query: ({ managerId, startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseApproverSummery}?managerId=${managerId}&startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByUser: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByUser}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByDepartment: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByDepartment}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByStatus: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByStatus}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByPayment: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByPayment}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByDepartmentWithAmount: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByDepartmentWithAmount}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByUserWithAmount: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByUserWithAmount}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET"
                }
            },
        }),
        getExpensesReportByUser: builder.query({
            query: ({ userAccountId, pageNumber, pageSize, sortBy, sortOrder, searchStr, filterByExpType, startDate, endDate, filterByStatus }) => {
                return {
                    url: `${API_END_POINTS.getExpensesReportByUser}?userAccountId=${userAccountId}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByExpType=${filterByExpType}&startDate=${startDate}&endDate=${endDate}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        getReportIronePDF: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getReportIronePDF}`,
                    method: "GET",
                }
            },
        }),
        getReport: builder.query({
            query: ({ expenseId }) => {
                return {
                    url: `${API_END_POINTS.getReport}?expenseId=${expenseId}`,
                    method: "GET"
                }
            },
        }),
        getExpenseReportByPaymentV2: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByPaymentV2}`,
                    method: "GET",
                    params
                }
            },
        }),
        getExpensesReportByUserV2: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpensesReportByUserV2}`,
                    method: "GET",
                    params
                }
            },
        }),
        getExpenseByCategoryV2: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenseByCategoryV2}`,
                    method: "GET",
                    params
                }
            },
        }),
        getExpenseReportByDepartmentAndUser: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenseReportByDepartmentAndUser}`,
                    method: "GET",
                    params
                }
            },
        }),
        getExpenseViolationReport: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenseViolationReport}`,
                    method: "GET",
                    params
                }
            },
        }),
        getExpenseCatagoryBudgetReport: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenseCatagoryBudgetReport}`,
                    method: "GET",
                    params
                }
            },
        }),
    }),
})


export const {
    useExpenseSummaryQuery,
    useExpenseByCategoryQuery,
    useExpenseByPaymentQuery,
    useExpenseApproverSummeryQuery,
    useGetExpenseReportByUserQuery,
    useGetExpenseReportByDepartmentQuery,
    useGetExpenseReportByStatusQuery,
    useGetExpenseReportByPaymentQuery,
    useGetExpenseReportByDepartmentWithAmountQuery,
    useGetExpenseReportByUserWithAmountQuery,
    useGetExpensesReportByUserQuery,
    useGetReportIronePDFQuery,
    useGetReportQuery,
    useGetExpenseReportByPaymentV2Query,
    useGetExpensesReportByUserV2Query,
    useGetExpenseByCategoryV2Query,
    useGetExpenseReportByDepartmentAndUserQuery,
    useGetExpenseViolationReportQuery,
    useGetExpenseCatagoryBudgetReportQuery

} = ReportAndAnalyticsApi