import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useClarificationNoteMutation } from '../../../services/ExpenseApi'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import labelKey from "../../localization/label.json";
import TextAreaField from '../../../components/textFields/TextAreaField';

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const ClarificationModal = ({ show, handleClose, data, }: Props) => {
    const [wordCount, setWordCount] = useState(0);
    const [clarificationNote, { isLoading, isSuccess, isError, error }] = useClarificationNoteMutation()
    const initialValues = {
        expenseId: data?.expenseId,
        clarificationNote: ''
    }
    const [id, setId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setId(data?.expenseId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setId(0);
            formik.resetForm();
        }
    }, [show, data]);
    const validationSchema = Yup.object().shape({
        clarificationNote: Yup.string()
            .nullable() // Allow null values
            .required('Clarification is required')
            .test(
                'no-spaces',
                'Clarification cannot contain only spaces',
                function (value) {
                    if (value && typeof value === 'string') {
                        return value.trim().length > 0;
                    }
                    return false;
                }
            )
            .max(500, 'Clarification must not exceed 500 characters')
            .matches(
                /^[^#@!$%&*]+$/,
                'Clarification cannot contain special characters like #@!$%&*'
            ),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            clarificationNote({ ...values, expenseId: data?.expenseId })
        }
    })
    const { resetForm } = formik;
    useEffect(() => {
        if (isSuccess) {
            toast.success('Clarification Added Successfully');
            handleClose();
            resetForm()

        }
    }, [isSuccess]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);

    const handleTextAreaChange = (event: any) => {
        const text = event.target.value;
        const words = text.trim().split('');
        const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
        setWordCount(wordCount);
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header py-4'>
                    <h2 className='mb-0'>{labelKey.addClarificationforExpense}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-5 px-lg-10'>
                        <div>
                            <TextAreaField
                                label={labelKey.addClarificationNote}
                                rows={5}
                                placeholder='Enter Clarification'
                                fieldClass={` ${formik.touched.clarificationNote && formik.errors.clarificationNote ? 'is-invalid' : ''}`}
                                {...formik.getFieldProps('clarificationNote')}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    handleTextAreaChange(e);
                                }}
                            />
                            <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                        </div>
                        {formik.touched.clarificationNote && formik.errors.clarificationNote && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.clarificationNote}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='modal-footer py-4'>
                        <Button className='btn btn-light-primary' onClick={handleClose}>{labelKey.cancel}</Button>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >

                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default ClarificationModal