import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import { selectAuth, setOriginalEmail, setUser, startImpersonating } from '../../../features/authSlice'
import { useAppDispatch } from '../../../hooks'
import { useImpersonateUserMutation } from '../../../services/authApi'
import { useSelector } from 'react-redux'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const ImpersonateUserModal = ({ show, handleClose, data }: Props) => {
    const auth = useSelector(selectAuth);
    const [impersonateUser, { data: imersonateData, isLoading, isSuccess, isError, error }] = useImpersonateUserMutation()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const initialValues = {
        email: ''
    }
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Email is required'),
    })
    const [updateId, setUpdateId] = useState(0);

    useEffect(() => {
        if (data) {
            setUpdateId(data?.userAccountID);
            formik.setValues({ ...data })
        } else {
            setUpdateId(0);
            formik.resetForm();
        }
    }, [data]);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            dispatch(setOriginalEmail(auth.email || ''));
            impersonateUser(values)
            formik.resetForm();
        }
    })
    useEffect(() => {
        if (isSuccess) {
            dispatch(
                setUser({
                    firstName: imersonateData.result.userAccountBasicInfo.firstName,
                    lastName: imersonateData.result.userAccountBasicInfo.lastName,
                    email: imersonateData.result.userAccountBasicInfo.email,
                    role: imersonateData.result.userAccountBasicInfo.roleName,
                    roleID: imersonateData.result.userAccountBasicInfo.roleID,
                    contactPhone: imersonateData.result.userAccountBasicInfo.cellPhone,
                    businessPhone: imersonateData.result.userAccountBasicInfo.businessPhone,
                    businessExt: imersonateData.result.userAccountBasicInfo.businessExt,
                    userAccountID: imersonateData.result.userAccountBasicInfo.userAccountID,
                    userInOrgId: imersonateData.result.userAccountBasicInfo.userAccountInOrgs[0].userInOrgId,
                    locationType: imersonateData.result.userAccountBasicInfo.locationType.id,
                    expenseType: imersonateData.result.userAccountBasicInfo.expenseType.id,
                    location: imersonateData.result.userAccountBasicInfo.location?.id,
                    locationName: imersonateData.result.userAccountBasicInfo.location?.value,
                    reportPermision: imersonateData.result.userAccountBasicInfo.reportPermision,
                    timeZone: imersonateData.result?.userAccountBasicInfo?.userTimeZone?.title,
                    token: imersonateData.result.token,
                    expiresAt: imersonateData.result.expiresAt,
                    baseURL: imersonateData.result.baseURL,
                    microServiceURL: imersonateData.result.microServiceURL,

                })
            );
            dispatch(startImpersonating());
            // if (imersonateData.result.userAccountBasicInfo.roleID === 10) {
            //     navigate("/ap-approved");
            // } else {
            //     navigate("/dashboard");
            // }
            if (imersonateData.result.userAccountBasicInfo.roleID === 10) {
                setTimeout(() => {
                    navigate("/ap-approved");
                }, 0);
            } else if (imersonateData.result.userAccountBasicInfo.roleID === 13) {
                setTimeout(() => {
                    navigate("/portal-support-requests");
                }, 0);
            } else {
                setTimeout(() => {
                    navigate("/dashboard");
                }, 0);
            }

            toast.success(tosterKey.impersonateLoginSuccessfully);
            handleClose();
        }
    }, [isSuccess, isError]);


    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header py-5'>
                    <h2 className='mb-0'>{labelKey.impersonateLogin}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>
                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-3'>
                        <div className="row">
                            <div className='col-md-12 mb-5'>
                                <p className='fs-5 fw-semibold mb-0'>{labelKey.areYouSureToImpersonateLogin} with <span className='text-primary'>{data?.firstName} {data?.middleName} {data?.lastName}.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center py-3'>
                        <p className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</p>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.yes}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default ImpersonateUserModal