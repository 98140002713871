import React from 'react'
import './TextField.css'
import { FaLock } from 'react-icons/fa6';

interface props {
    labelClass?: string;
    leftLabel?: string;
    required?: boolean;
    label: string;
    selectClass?: string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    // placeholder: string;
    children: React.ReactNode;
    disabled?: boolean;
    onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value?: string;
    name?: string;
}
const SelectField = ({ labelClass, leftLabel, required, label, selectClass, onChange, children, disabled, name, onBlur, value, ...fieldProps }: props) => {
    return (
        <>
            <div className={`${leftLabel ? 'd-md-flex w-100 gap-2' : ''}`}>
                <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                    {label}{leftLabel}
                    {required && <span className="required-star text-danger ms-1">*</span>}
                    {disabled && <FaLock className='fs-7 ms-1' />}
                </label>
                <select className={`form-select form-select-solid default-input ${selectClass}`}
                    onChange={onChange}
                    disabled={disabled}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    {...fieldProps}>
                    {/* <option>Select {placeholder}</option> */}
                    {children}
                </select>
            </div>
        </>
    )
}

export default SelectField