import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { baseUrl } from '../../../services/baseUrl'
import labelKey from "../../localization/label.json"
import { API_END_POINTS } from '../../../services/apiEndpoints'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import { constraintConfig } from '../../../constraintConfig'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const UserDetailModal = ({ show, handleClose, data }: Props) => {

    const { token, baseURL } = useAppSelector(selectAuth);
    const [singleUserData, setSingleUserData] = useState<any>(null); // Initialize with null
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (show && data) {
                setIsLoading(true); // Set loading state to true
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };

                    const getExpenseLogResponse = await axios.get(
                        `${baseURL}/api/Account${API_END_POINTS.userAccountDetailById}?id=${data}`,
                        {
                            headers,
                        }
                    );
                    const singleUserData = getExpenseLogResponse.data;
                    setSingleUserData(singleUserData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Set loading state to false after fetching data
                }
            }
        };
        fetchData();
    }, [show, data, token]);

    const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = '/media/avatars/blank.png';
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-xl modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.userDetails}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <div className='modal-body py-lg-10 px-lg-10'>
                    {isLoading && <h4 className='d-flex align-items-center justify-content-center text-dark'>{labelKey.loading}...</h4>}
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body pt-9 pb-0'>
                            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                                <div className='me-7 mb-4'>
                                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                        <img src={singleUserData?.result?.imageUrl ? singleUserData?.result?.imageUrl : '/media/avatars/blank.png'} alt='' onError={handleError} />
                                        <div className={`position-absolute translate-middle bottom-0 start-100 mb-6  rounded-circle border border-4 border-white h-20px w-20px
                                            ${singleUserData && singleUserData?.result?.active === true ? 'bg-success' : 'bg-danger'}
                                            `}></div>
                                    </div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='d-flex gap-20 align-items-start flex-wrap mb-2'>
                                        <div className='d-flex flex-column'>
                                            <div className='d-flex align-items-center mb-2'>
                                                <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1 mb-0'>
                                                    {singleUserData && singleUserData?.result?.firstName}
                                                    {singleUserData && singleUserData?.result?.middleName}
                                                    {singleUserData && singleUserData?.result?.lastName}
                                                </p>
                                                <span>
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen026.svg'
                                                        className='svg-icon-1 svg-icon-primary'
                                                    />
                                                </span>
                                            </div>

                                            <div className='fw-semibold fs-6 mb-4 pe-2'>
                                                <p
                                                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.role}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.userRole?.roleName}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.email}: &nbsp;</b>
                                                    <KTSVG
                                                        path='/media/icons/duotune/communication/com011.svg'
                                                        className='svg-icon-4 me-1'
                                                    />
                                                    {singleUserData && singleUserData?.result?.email}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.cellPhone}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.cellPhone}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.businessPhone}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.businessPhone}
                                                </p>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column'>
                                            <div className='fw-semibold fs-6 mb-4 pe-2 mt-10'>
                                                <p
                                                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.ssUserId}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.userAccountID}
                                                </p>
                                                <p
                                                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.employeeId}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.employeeId}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.bcEmployeeId}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.bcEmployeeId}
                                                </p>
                                                {singleUserData?.result?.userRole?.roleID === constraintConfig.roleID.role4 &&
                                                    <p className='d-flex align-items-center text-gray-400 mb-2'
                                                    >
                                                        <b className='text-dark'>{labelKey.approval$Limit}: &nbsp;</b>
                                                        {singleUserData && singleUserData?.result?.mgrApproveMaxLimit}
                                                    </p>}
                                                {singleUserData?.result?.userRole?.roleID === constraintConfig.roleID.role4 && constraintConfig.roleID.role10 ?
                                                    <p className='d-flex align-items-center text-gray-400 mb-2'
                                                    >
                                                        <b className='text-dark'>{labelKey.orgReportsPermision}: &nbsp;</b>
                                                        <span className="fs-7 text-dark">{singleUserData && singleUserData?.result?.reportPermision === true ?
                                                            <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.allowed}</span> :
                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.notAllowed}</span>}</span>
                                                    </p> : null}
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column'>


                                            <div className='fw-semibold fs-6 mb-4 pe-2 mt-10'>
                                                <p
                                                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.organization}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.userOrg?.userInOrgName}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.manager}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.managerName}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.department}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.department?.title}
                                                </p>
                                                <p className='d-flex align-items-center text-gray-400 mb-2'
                                                >
                                                    <b className='text-dark'>{labelKey.designation}: &nbsp;</b>
                                                    {singleUserData && singleUserData?.result?.designation?.title}
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-5">
                                        <div className="border border-gray-300 border-dashed rounded w-100 min-w-125px py-3 px-4 mx-3 mb-3">
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.allowedExpenseType}:</p>
                                                <span className="fw-semibold">{singleUserData && singleUserData?.result?.expenseType?.value}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.allowedMileageType}:</p>
                                                <span className="fw-semibold">{singleUserData && singleUserData?.result?.locationType?.value}</span>
                                            </div>

                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.officeLocation}:</p>
                                                <span className="fw-semibold w-50">{singleUserData?.result?.location?.value ? singleUserData?.result?.location?.value : "N/A"}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.locationType}:</p>
                                                <span className="fw-semibold">{singleUserData?.result?.locationType?.value ? singleUserData?.result?.locationType?.value : "N/A"}</span>
                                            </div>

                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.division}:</p>
                                                <span className="fw-semibold">{singleUserData?.result?.division?.title ? singleUserData?.result?.division?.title : "N/A"}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.businessExt}:</p>
                                                <span className="fw-semibold"> {singleUserData?.result?.businessExt ? singleUserData?.result?.businessExt : "N/A"}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-175px">{labelKey.timeZone}:</p>
                                                <span className="fw-semibold"> {singleUserData?.result?.userTimeZone?.title ? singleUserData?.result?.userTimeZone?.title : "N/A"}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-5">
                                        <div className="border border-gray-300 border-dashed rounded w-100 min-w-125px py-3 px-4 mx-3 mb-3">
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.findAddress}:</p>
                                                <span className="fw-semibold w-50"> {singleUserData && singleUserData?.result?.findAddress}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.addressLine1}:</p>
                                                <span className="fw-semibold"> {singleUserData && singleUserData?.result?.addressLine1}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.addressLine2}:</p>
                                                <span className="fw-semibold"> {singleUserData?.result?.addressLine2 ? singleUserData?.result?.addressLine2 : "N/A"}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.longitude}:</p>
                                                <span className="fw-semibold"> {singleUserData?.result?.longitude ? singleUserData?.result?.longitude : "N/A"}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.latitude}:</p>
                                                <span className="fw-semibold"> {singleUserData?.result?.latitude ? singleUserData?.result?.latitude : "N/A"}</span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.officeDistanceSingleTrip}:</p>
                                                <span className="fw-semibold">
                                                    {singleUserData?.result?.homeToOfficeDistanceSingleTrip
                                                        ? parseFloat(singleUserData?.result?.homeToOfficeDistanceSingleTrip).toFixed(2)
                                                        : "N/A"}
                                                </span>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-200px">{labelKey.officeDistanceRoundTrip}:</p>
                                                <span className="fw-semibold">
                                                    {singleUserData?.result?.homeToOfficeDistanceRoundTrip
                                                        ? parseFloat(singleUserData?.result?.homeToOfficeDistanceRoundTrip).toFixed(2)
                                                        : "N/A"}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* {!isLoading &&
                        <div className="row m-0">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-9 pt-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                                    <div className="mb-4">
                                                        <div className="symbol symbol-75px symbol-circle">
                                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">
                                                                {singleUserData?.result?.imageUrl ?
                                                                    <img src={singleUserData?.result?.imageUrl} alt="" className='w-75px h-75px rounded-circle' /> :
                                                                    singleUserData?.result?.firstName.charAt(0)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0 d-flex gap-1">
                                                        <span>{singleUserData && singleUserData?.result?.firstName}</span>
                                                        <span>{singleUserData && singleUserData?.result?.middleName}</span>
                                                        <span>{singleUserData && singleUserData?.result?.lastName}</span>
                                                    </p>
                                                    <div className="fw-semibold text-gray-400 mb-3">{singleUserData && singleUserData?.result?.email}</div>
                                                    <div className="fw-semibold mb-5"> {singleUserData && singleUserData?.result?.active === true ?
                                                        <span className="badge badge-light-success fs-7 fw-semibold">Active</span> :
                                                        <span className="badge badge-light-danger fs-7 fw-semibold">In Active</span>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-5">
                                                    <div className="border border-gray-300 border-dashed rounded w-100 min-w-125px py-3 px-4 mx-3 mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.organization}:</p>
                                                            <span className="fw-semibold"> {singleUserData && singleUserData?.result?.userOrg?.userInOrgName}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.employeeId}:</p>
                                                            <span className="fw-semibold"> {singleUserData && singleUserData?.result?.employeeId}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.bcEmployeeId}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.bcEmployeeId ? singleUserData?.result?.bcEmployeeId : "N/A"}</span>
                                                        </div>
                                                        {singleUserData?.result?.userRole?.roleID === constraintConfig.roleID.role4 &&
                                                            <div className="d-flex align-items-center">
                                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.approval$Limit}:</p>
                                                                <span className="fw-semibold"><CurrencyComp amount={singleUserData && singleUserData?.result?.mgrApproveMaxLimit} /></span>
                                                            </div>}
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.allowedExpenseType}:</p>
                                                            <span className="fw-semibold">{singleUserData && singleUserData?.result?.expenseType?.value}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.allowedMileageType}:</p>
                                                            <span className="fw-semibold">{singleUserData && singleUserData?.result?.locationType?.value}</span>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.role}:</p>
                                                            <span className="fw-semibold"> {singleUserData && singleUserData?.result?.userRole?.roleName}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.manager}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.managerName ? singleUserData?.result?.managerName : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.officeLocation}:</p>
                                                            <span className="fw-semibold w-50">{singleUserData?.result?.location?.value ? singleUserData?.result?.location?.value : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.locationType}:</p>
                                                            <span className="fw-semibold">{singleUserData?.result?.locationType?.value ? singleUserData?.result?.locationType?.value : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.department}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.department?.title ? singleUserData?.result?.department?.title : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.division}:</p>
                                                            <span className="fw-semibold">{singleUserData?.result?.division?.title ? singleUserData?.result?.division?.title : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.designation}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.designation?.title ? singleUserData?.result?.designation?.title : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.orgReportsPermision}:</p>
                                                            <span className="fs-7 text-dark">{singleUserData && singleUserData?.result?.reportPermision === true ?
                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.allowed}</span> :
                                                                <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.notAllowed}</span>}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-5">
                                                    <div className="border border-gray-300 border-dashed rounded w-100 min-w-125px py-3 px-4 mx-3 mb-3">
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.findAddress}:</p>
                                                            <span className="fw-semibold w-50"> {singleUserData && singleUserData?.result?.findAddress}</span>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.addressLine1}:</p>
                                                            <span className="fw-semibold w-50"> {singleUserData && singleUserData?.result?.addressLine1}</span>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.addressLine2}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.addressLine2 ? singleUserData?.result?.addressLine2 : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.longitude}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.longitude ? singleUserData?.result?.longitude : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.latitude}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.latitude ? singleUserData?.result?.latitude : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.officeDistanceSingleTrip}:</p>
                                                            <span className="fw-semibold">
                                                                {singleUserData?.result?.homeToOfficeDistanceSingleTrip
                                                                    ? parseFloat(singleUserData?.result?.homeToOfficeDistanceSingleTrip).toFixed(2)
                                                                    : "N/A"}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex ">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.officeDistanceRoundTrip}:</p>
                                                            <span className="fw-semibold">
                                                                {singleUserData?.result?.homeToOfficeDistanceRoundTrip
                                                                    ? parseFloat(singleUserData?.result?.homeToOfficeDistanceRoundTrip).toFixed(2)
                                                                    : "N/A"}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.cellPhone}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.cellPhone ? singleUserData?.result?.cellPhone : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.businessPhone}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.businessPhone ? singleUserData?.result?.businessPhone : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.businessExt}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.businessExt ? singleUserData?.result?.businessExt : "N/A"}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.timeZone}:</p>
                                                            <span className="fw-semibold"> {singleUserData?.result?.userTimeZone?.title ? singleUserData?.result?.userTimeZone?.title : "N/A"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>} */}

                </div>
                {/* <div className='modal-footer'>
                    <button className='btn btn-light-primary' onClick={handleClose}>Cancel</button>
                </div> */}

            </Modal>
        </>
    )
}

export default UserDetailModal