import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import labelKey from '../../localization/label.json';
import EmailSetting from './EmailSetting';
import { PageTitle } from '../../../_metronic/layout/core';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import OrgSettingPages from './OrgSettingPages';
import CacheClearSetting from '../../modules/accounts/components/settings/cards/CacheClearSetting';

const myReportBreadCrumbs = [
    {
        title: 'Org Settings',
        path: 'settings/org-settings/email-configuration',
        isSeparator: false,
        isActive: true,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: false,
        isActive: false,
    },

]
const OrgSettings = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.configurationSetting}</PageTitle>
            <Routes>
                <Route
                    element={
                        <>

                            <OrgSettingPages />
                            <Outlet />
                        </>
                    }
                >
                    <Route
                        path='email-configuration'
                        element={
                            <>
                                <PageTitle breadcrumbs={myReportBreadCrumbs}>Email Configuration</PageTitle>
                                <EmailSetting />
                            </>
                        }
                    />
                    <Route
                        path='cache-clear'
                        element={
                            <>
                                <PageTitle breadcrumbs={myReportBreadCrumbs}>Cache Clear Setting</PageTitle>
                                <CacheClearSetting />
                            </>
                        }
                    />
                    <Route index element={<Navigate to='/settings/org-settings/email-configuration' />} />
                </Route>
            </Routes>
        </>
    )
}

export default OrgSettings