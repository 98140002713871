import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import labelKey from '../../../localization/label.json'
type Props = {
    show: boolean
    handleClose: () => void
    data: any
    formik: any
    fileInputRef: any
}

const AddRequestDeleteAttachmentModal = ({ show, handleClose, data, formik, fileInputRef }: Props) => {
    const deleteAttachmentHandler = (index: number) => {
        const updatedAttachments = formik.values?.attachments.filter((_: any, i: any) => i !== index);
        formik.setFieldValue('attachments', updatedAttachments);
        const fileInput = fileInputRef.current;

        if (fileInput) {
            fileInput.value = ''; // Clear the input value
        }
        handleClose()
    };

    return (
        <Modal
            aria-hidden='true'
            dialogClassName='modal-dialog modal-md modal-dialog-centered'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header bg-light py-4'>
                <h2 className='mb-0'>{labelKey.deleteConfirmation}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>


            <div className='modal-body py-lg-6 px-lg-6'>

                <p className='fs-5 fw-semibold'>{labelKey.areYouSureYouDelete} <span className='text-danger'>"{labelKey.attachment}"</span>.</p>
            </div>
            <div className='modal-footer justify-content-center py-4'>
                <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-danger'
                    onClick={() => deleteAttachmentHandler(data)}
                // onClick={() => handleDelete(data)}

                >
                    {labelKey.delete}
                </button>
            </div>
        </Modal>
    )
}

export default AddRequestDeleteAttachmentModal