import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { allRoutes } from '../../../routing/All_Routes'

const QueryReportTabs = () => {
    const location = useLocation()
    return (
        <div className='card' style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            <div className='d-flex overflow-auto h-55px px-10 border-bottom'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap'>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `${allRoutes.analytics}/org-report/expense-summary` && 'active')
                            }
                            to={`${allRoutes.analytics}/org-report/expense-summary`}
                        >
                            Expense Summary
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `${allRoutes.analytics}/org-report/expense-detail` && 'active')
                            }
                            to={`${allRoutes.analytics}/org-report/expense-detail`}
                        >
                            Expense Detail
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `${allRoutes.analytics}/org-report/expense-by-category` && 'active')
                            }
                            to={`${allRoutes.analytics}/org-report/expense-by-category`}
                        >
                            Expense By Category
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `${allRoutes.analytics}/org-report/expense-violations` && 'active')
                            }
                            to={`${allRoutes.analytics}/org-report/expense-violations`}
                        >
                            Expense Violations
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={
                                `nav-link text-active-primary me-6 ` +
                                (location.pathname === `${allRoutes.analytics}/org-report/category-budget` && 'active')
                            }
                            to={`${allRoutes.analytics}/org-report/category-budget`}
                        >
                            Category Budget
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default QueryReportTabs