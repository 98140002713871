import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import DateTimeComp from '../../../../components/dateComponent/DateTimeComp';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import labelKey from '../../../localization/label.json';
import { useGetAutomationBankFilesFeedParsingStatsQuery } from '../../../../services/AutomationApi';
import BankCCFeedDetailViewModal from './BankCCFeedDetailViewModal';
import { PageTitle } from '../../../../_metronic/layout/core';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import { API_END_POINTS } from '../../../../services/apiEndpoints';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IoIosRepeat } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

const BankCCFeeds = () => {
  const { token, microServiceURL } = useAppSelector(selectAuth);
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState(''); // debounce search
  const [immediateSearchStr, setImmediateSearchStr] = useState('');
  const [sortBy, setSortBy] = useState("createDT");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedColumn, setSortedColumn] = useState("createDT");
  const [filterByStatus, setFilterByStatus] = useState("");

  // Debounced search state
  // const [searchStr, setSearchStr] = useState('');

  const { data, isLoading, refetch } = useGetAutomationBankFilesFeedParsingStatsQuery({
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchStr: searchStr,
    sortBy: sortBy,
    sortOrder: sortOrder,
    filterByStatus: filterByStatus
  })

  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPageNumber(1); // Reset page number to 1
  };
  // pagination
  const handlePageClick = (e: any) => {
    setPageNumber(e.selected + 1);
  };
  //per page record
  const handlePageRecords = (e: any) => {
    setPageNumber(1); // Reset the page to 1
  };
  const handleSort = (property: string) => {
    setSortBy(property);
    if (sortedColumn === property) {
      setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(property);
      setSortOrder("asc");
    }
  };

  const handleSearchChange = (e: any) => {
    const { value } = e.target;
    setImmediateSearchStr(value); // Update immediate search state

    delayedSearch(value); // Update debounced search state
  };

  const delayedSearch = useCallback(
    debounce((searchValue) => {
      setSearchStr(searchValue);
    }, 500),
    []
  );

  useEffect(() => {
    if (searchStr !== "" && sortOrder !== "" && sortBy !== "") {
      // Trigger the API call after the debounce time
      refetch();
    }
  }, [searchStr, sortOrder, sortBy, refetch]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(['sr', 'fileName', 'totalAmountOfDebits', 'totalParsedAmount', 'totalCountLineItemDetailEntity', 'totalCountLineItemDetailEntityParsed', 'totalCountCCLineItemsToSSExpItemsCreated', 'totalLineItemCreatedAmount', 'parsingStatus', 'parsingStartTime', 'parsingEndTime', 'actions']);
  const handleToggleColumn = (columnId: string) => {
    if (hiddenColumns.includes(columnId)) {
      setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
    } else {
      setHiddenColumns([...hiddenColumns, columnId]);
    }
  };

  const [showBankFeedDetailModal, setShowBankFeedDetailModal] = useState(false)
  const [bankFeedData, setBankFeedData] = useState();

  const bankFeedDetailHandler = () => {
    setShowBankFeedDetailModal((prev) => !prev);
  };
  useEffect(() => {
    if (showBankFeedDetailModal === false) {
      refetch()
    }
  }, [showBankFeedDetailModal])

  const [creatingFileIsLoading, setCreatingFileIsLoading] = useState(false);
  const importCCFilesHandler = async () => {
    setCreatingFileIsLoading(true); // Set loading state to true
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const getCreatingFileResponse = await axios.post(
        `${microServiceURL}/api/BackgroundJob${API_END_POINTS.automationCreditCardFeedsFilesManualCall}`,
        {
          headers,
        }
      );
      // const getExpenseLog = getExpenseLogResponse.data;
      // setGetExpenseLog(getExpenseLog);
      refetch()
      toast.success('CC Files imported successfully!');
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setCreatingFileIsLoading(false); // Set loading state to false after fetching data
    }
  }
  const [creatingLineItemIsLoading, setCreatingLineItemIsLoading] = useState(false);
  const creatingLineItemHandler = async () => {
    setCreatingLineItemIsLoading(true); // Set loading state to true
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const getCCLineItemResponse = await axios.post(
        `${microServiceURL}/api/BackgroundJob${API_END_POINTS.automationCreaditCardFeedsToLineItemManualCall}`,
        {
          headers,
        }
      );
      refetch()
      toast.success('CC Line Item Created successfully!');
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setCreatingLineItemIsLoading(false); // Set loading state to false after fetching data
    }
  }

  const [creatingReportIsLoading, setCreatingReportIsLoading] = useState(false);
  const creatingReportHandler = async () => {
    setCreatingReportIsLoading(true); // Set loading state to true
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const getCCCreateReportResponse = await axios.post(
        `${microServiceURL}/api/BackgroundJob${API_END_POINTS.automationCreaditCardFeedsToCreateExpReportManualCall}`,
        {
          headers,
        }
      );
      refetch()
      toast.success('CC Report Created successfully!');
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setCreatingReportIsLoading(false); // Set loading state to false after fetching data
    }
  }
  const handleRefresh = () => {
    refetch()
      .then(() => {
        toast.success('Data Refresh successfully!');
      });
  };

  return (
    <>
      <BankCCFeedDetailViewModal
        show={showBankFeedDetailModal}
        handleClose={() => setShowBankFeedDetailModal(false)}
        bankFeedData={bankFeedData}
      />
      <PageTitle breadcrumbs={[]}>{labelKey.automationBankCCFeeds}</PageTitle>

      <div className='card mb-5'>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex align-items-center '>
            <SearchBarComponent
              placeholder='File Parsing'
              value={immediateSearchStr}
              onChange={handleSearchChange}
            />
            <Tooltip id="refreshData" place="top" />
            <div
              data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
              <IoIosRepeat className='h-20px w-20px' />
            </div>
          </div>
          <div className='d-flex align-items-center ms-auto'>
            <div className='card-toolbar gap-3'>
              <button className='btn btn-sm btn-primary'
                onClick={importCCFilesHandler}
                disabled={creatingFileIsLoading} >
                {creatingFileIsLoading ? 'Loading...' : labelKey.importCCFiles}
              </button>
              <button className='btn btn-sm btn-primary'
                onClick={creatingLineItemHandler}
                disabled={creatingLineItemIsLoading} >
                {creatingLineItemIsLoading ? 'Loading...' : labelKey.linkedCCLineItem}
              </button>
              <button className='btn btn-sm btn-primary'
                onClick={creatingReportHandler}
                disabled={creatingReportIsLoading} >
                {creatingReportIsLoading ? 'Loading...' : labelKey.createCCReports}
              </button>
            </div>
          </div>
        </div>
        <div className='card-body py-8'>
          <div className="col-md-12">
            <div className='table-responsive table-height'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    {hiddenColumns.includes('sr') && (
                      <TableHeading
                        label={labelKey.sr}
                        columnId='sr'
                        className="w-60px ps-2"
                      />
                    )}
                    {hiddenColumns.includes('fileName') && (
                      <TableHeading
                        label={labelKey.fileName}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='fileName'
                        className="w-400px"
                      />
                    )}
                    {hiddenColumns.includes('parsingStatus') && (
                      <TableHeading
                        label={labelKey.importStatus}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='parsingStatus'
                      />
                    )}
                    {hiddenColumns.includes('parsingStartTime') && (
                      <TableHeading
                        label={labelKey.parsingStartDate}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='parsingStartTime'
                      />
                    )}
                    {hiddenColumns.includes('parsingEndTime') && (
                      <TableHeading
                        label={labelKey.parsingEndDate}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='parsingEndTime'
                      />
                    )}
                    {hiddenColumns.includes('totalCountLineItemDetailEntity') && (
                      <TableHeading
                        label={labelKey.feedItemsCount}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='totalCountLineItemDetailEntity'
                        className="w-100px"
                      />
                    )}
                    {hiddenColumns.includes('totalCountLineItemDetailEntityParsed') && (
                      <TableHeading
                        label={labelKey.feedItemsParsedCount}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='totalCountLineItemDetailEntityParsed'
                        className="w-100px"
                      />
                    )}
                    {hiddenColumns.includes('totalCountCCLineItemsToSSExpItemsCreated') && (
                      <TableHeading
                        label={labelKey.linkedLineItemsCount}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='totalCountCCLineItemsToSSExpItemsCreated'
                        className="w-100px"
                      />
                    )}
                    <th className='text-end rounded-end pe-2 border-0'>
                      {hiddenColumns.includes('actions') && (
                        <>
                          {labelKey.actions}
                        </>
                      )}
                      <TableSettingMenu>
                        <TextField
                          rightLabel={labelKey.sr}
                          type="checkbox"
                          onChange={() => handleToggleColumn("sr")}
                          checked={hiddenColumns.includes('sr')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.fileName}
                          type="checkbox"
                          onChange={() => handleToggleColumn("fileName")}
                          checked={hiddenColumns.includes('fileName')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.importStatus}
                          type="checkbox"
                          onChange={() => handleToggleColumn("parsingStatus")}
                          checked={hiddenColumns.includes('parsingStatus')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.parsingStartDate}
                          type="checkbox"
                          onChange={() => handleToggleColumn("parsingStartTime")}
                          checked={hiddenColumns.includes('parsingStartTime')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.parsingEndDate}
                          type="checkbox"
                          onChange={() => handleToggleColumn("parsingEndTime")}
                          checked={hiddenColumns.includes('parsingEndTime')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.feedItemsCount}
                          type="checkbox"
                          onChange={() => handleToggleColumn("totalCountLineItemDetailEntity")}
                          checked={hiddenColumns.includes('totalCountLineItemDetailEntity')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.feedItemsParsedCount}
                          type="checkbox"
                          onChange={() => handleToggleColumn("totalCountLineItemDetailEntityParsed")}
                          checked={hiddenColumns.includes('totalCountLineItemDetailEntityParsed')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.linkedLineItemsCount}
                          type="checkbox"
                          onChange={() => handleToggleColumn("totalCountCCLineItemsToSSExpItemsCreated")}
                          checked={hiddenColumns.includes('totalCountCCLineItemsToSSExpItemsCreated')}
                          fieldClass='mb-4'
                        />
                      </TableSettingMenu>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    <>
                      {data?.result?.data?.length > 0 ? (
                        <>
                          {data?.result?.data?.map((data: any, index: any) => (
                            <tr key={index}>
                              {hiddenColumns.includes('sr') && (
                                <td className='ps-4'>
                                  {(pageNumber - 1) * pageSize + index + 1}
                                </td>
                              )}
                              {hiddenColumns.includes('fileName') && (
                                <td>{data?.fileName}</td>
                              )}
                              {hiddenColumns.includes('parsingStatus') && (
                                <td className='ps-11'> {data?.parsingStatus === true ?
                                  <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.parsed}</span> :
                                  <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.failed}</span>}
                                </td>
                              )}
                              {hiddenColumns.includes('parsingStartTime') && (
                                <td>
                                  <DateTimeComp formattedDate={data?.parsingStartTime} />
                                </td>
                              )}
                              {hiddenColumns.includes('parsingEndTime') && (
                                <td>
                                  <DateTimeComp formattedDate={data?.parsingEndTime} />
                                </td>
                              )}

                              {hiddenColumns.includes('totalCountLineItemDetailEntity') && (
                                <td className='text-end'>{data?.totalCountLineItemDetailEntity ? data?.totalCountLineItemDetailEntity : "N/A"}</td>
                              )}
                              {hiddenColumns.includes('totalCountLineItemDetailEntityParsed') && (
                                <td className='text-end'>{data?.totalCountLineItemDetailEntityParsed ? data?.totalCountLineItemDetailEntityParsed : "N/A"}</td>
                              )}
                              {hiddenColumns.includes('totalCountCCLineItemsToSSExpItemsCreated') && (
                                <td className='text-end'>{data?.totalCountCCLineItemsToSSExpItemsCreated ? data?.totalCountCCLineItemsToSSExpItemsCreated : "N/A"}</td>
                              )}
                              <td>
                                {hiddenColumns.includes('actions') && (
                                  <p className='text-end pe-9 text-primary mb-0'
                                    onClick={() => {
                                      setBankFeedData(data);
                                      bankFeedDetailHandler();
                                    }}>
                                    View Detail
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>) : (
                        <tr>
                          <td colSpan={11}>
                            <NoRecordFound />
                          </td>
                        </tr>
                      )}
                    </>}
                  {isLoading && (
                    <tr>
                      <td colSpan={11} className="text-center">
                        <LoadingComponent />
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            <Pagination
              totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
              toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
              ofResult={data?.result?.totalRecords}
              onChange={handlePageSizeChange}
              pageSize={pageSize}
              onPageChange={handlePageClick}
              pageCount={data?.result?.totalPages || 0}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BankCCFeeds