import { allRoutes } from "../../routing/All_Routes"

export const myReportPaths = [
    `${allRoutes.analytics}/my-report/expense-summary`,
    `${allRoutes.analytics}/my-report/expense-detail`,
    `${allRoutes.analytics}/my-report/expense-by-category`,
    `${allRoutes.analytics}/my-report/expense-violations`,
    `${allRoutes.analytics}/my-report/category-budget`
];

export const teamReportPaths = [
    `${allRoutes.analytics}/team-report/expense-summary`,
    `${allRoutes.analytics}/team-report/expense-detail`,
    `${allRoutes.analytics}/team-report/expense-by-category`,
    `${allRoutes.analytics}/team-report/expense-violations`,
    `${allRoutes.analytics}/team-report/category-budget`
];
export const queryReportPaths = [
    `${allRoutes.analytics}/org-report/expense-summary`,
    `${allRoutes.analytics}/org-report/expense-detail`,
    `${allRoutes.analytics}/org-report/expense-by-category`,
    `${allRoutes.analytics}/org-report/expense-violations`,
    `${allRoutes.analytics}/org-report/category-budget`
];