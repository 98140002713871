import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import DateComp from '../../../components/dateComponent/DateComp';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetUserAccountDetailQuery } from '../../../services/authApi';
import { useGetExpenseForReportQuery, useGetExpenseItemByExpenseIdQuery, useGetExpenseLogQuery } from '../../../services/ExpenseApi';
import labelKey from "../../localization/label.json";
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import { allRoutes } from '../../routing/All_Routes';

const Report = () => {
    const { id } = useParams();
    const { userAccountID } = useAppSelector(selectAuth);

    const { data: expenseItemData, refetch: expenseItemRefetch, } = useGetExpenseItemByExpenseIdQuery({ ExpenseId: id })
    const { data: getExpenseLog, isLoading: logIsLoading } = useGetExpenseLogQuery({ expenseId: id })
    const { data: getExpenseForReport, refetch: getExpenseForReportRefetch } = useGetExpenseForReportQuery({ expenseId: id })
    const { data: userDetail, refetch: userDetailRefetch } = useGetUserAccountDetailQuery(getExpenseForReport?.result?.userAccount?.orgUserID)
    const componentRef = useRef<HTMLDivElement | null>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${getExpenseForReport?.result?.title}.pdf`,
    });

    useEffect(() => {
        expenseItemRefetch()
        getExpenseForReportRefetch()
        if (getExpenseForReport?.result?.userAccount?.orgUserID) {
            userDetailRefetch()
        }
    }, [getExpenseForReport?.result?.userAccount?.orgUserID])

    return (
        <>
            <div className="container mb-5">
                <div className='d-flex gap-3'>
                    <Link to={allRoutes.analytics} className='ms-auto'>
                        <button className=' btn btn-light mb-2'>{labelKey.back}</button>
                    </Link>
                    <button className=' btn btn-primary mb-2' onClick={handlePrint}>{labelKey.download}</button>
                </div>
                <div className='card'>
                    <div className='card-body p-9 py-5'>
                        <div className="row" ref={componentRef}>
                            <div className="col-md-12">
                                <div className='bg-light py-6 rounded' >
                                    <h1 className='text-center fw-bold'>{labelKey.report}</h1>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div>
                                    <h3 className='py-3 ms-4 text-upper-case'>{labelKey.employeeDetail}</h3>
                                </div>
                                <div className='bg-light p-5'>
                                    <div className="row report-file">
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.employeeName}</label>
                                                <p className='d-flex align-items-center gap-1'>
                                                    <span>{userDetail?.result?.firstName}</span>
                                                    <span>{userDetail?.result?.middleName}</span>
                                                    <span>{userDetail?.result?.lastName}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.organization}</label>
                                                <p>{userDetail?.result?.userOrg?.userInOrgName}</p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.designation}</label>
                                                <p>{userDetail?.result?.designation?.title}</p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.department}</label>
                                                <p>{userDetail?.result?.department?.title}</p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.phoneNo}.</label>
                                                <p>{userDetail?.result?.cellPhone}</p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.joiningDate}</label>
                                                <p><DateComp formattedDate={userDetail?.result?.createDT} /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div>
                                    <h3 className='py-3 ms-4'>{labelKey.expenseSummary}</h3>
                                </div>
                                <div className='bg-light p-5'>
                                    <div className="row report-file">
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.expenseType}</label>
                                                <p>{getExpenseForReport?.result?.expenseType?.title}</p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.reportDate}</label>
                                                <p><DateComp formattedDate={getExpenseForReport?.result?.reportDate} /></p>
                                            </div>
                                        </div>
                                        <div className="report-file-w-25">
                                            <div>
                                                <label className='fs-5 fw-bold'>{labelKey.expenseStatus}</label>
                                                <p>{getExpenseForReport?.result?.expenseStatus.title}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label className='fs-5 fw-bold'> {labelKey.expenseDescription}</label>
                                            <p>{getExpenseForReport?.result?.expenseDetail}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div>
                                    <h3 className='py-3 ms-4'>{labelKey.expenseItemDetail}</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className='table-responsive'>
                                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4'>{labelKey.invoiceDate}</th>
                                                    <th>{labelKey.invoiceAmount}</th>
                                                    <th>{labelKey.expenseCategory}</th>
                                                    <th>{labelKey.vendor}</th>
                                                    <th>{labelKey.paymentMethod}</th>
                                                    <th className='w-300px'>{labelKey.description}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                    <tr key={index}>
                                                        <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                        <td><CurrencyComp amount={itemData?.amount} /></td>
                                                        <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                                                        <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                        <td>{itemData?.paymentMethod?.title}</td>
                                                        <td className='w-200px text-wrap'>{itemData?.itemDetail}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div>
                                    <h3 className='py-3 ms-4'>{labelKey.comment}</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className='table-responsive'>
                                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4'>{labelKey.user}</th>
                                                    <th className='w-200px'>{labelKey.commentByApprovers}</th>
                                                    <th className='w-200px'>{labelKey.commentByUsers}</th>
                                                    <th>{labelKey.status}</th>
                                                    <th>{labelKey.dateTime}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!logIsLoading && (<>
                                                    {getExpenseLog?.result?.length > 0 ?
                                                        <>
                                                            {getExpenseLog?.result?.map((data: any, index: any) => (
                                                                <tr key={index}>
                                                                    <td className='ps-4'>{data?.approver?.firstName} {data?.approver?.middleName} {data?.approver?.lastName}</td>
                                                                    <td className='w-200px text-wrap'>
                                                                        <p>{data?.commentByApprover ? data?.commentByApprover : "N/A"}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='w-200px text-wrap'>{data?.commentByUsers ? data?.commentByUsers : "N/A"}</p>
                                                                    </td>
                                                                    <td>
                                                                        {data?.status?.title === "Pending" ?
                                                                            <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.pending}</span> :
                                                                            data?.status?.title === "Clarification" ?
                                                                                <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                                data?.status?.title === "Approved" ?
                                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                                    data?.status?.title === "Rejected" ?
                                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                                        data?.status?.title === "AP Approved" ?
                                                                                            <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.accountsPayable}</span> : ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {data?.approvedDate && moment(data.approvedDate).isValid() ? (
                                                                            <span>{moment(data.approvedDate).format("DD-MM-YYYY - HH:mm:ss A")}</span>
                                                                        ) : (
                                                                            <span>_</span>
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan={5} className='text-center'>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    }
                                                </>)}
                                                {logIsLoading && (
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <LoadingComponent />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Report