import { BiDotsVerticalRounded } from 'react-icons/bi'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
const TableSettingMenu = ({ children }: any) => {
    return (
        <>
            <Tooltip id="setting-menu" place="top" />
            <button
                type='button'
                className='btn btn-sm pe-0 py-0'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <BiDotsVerticalRounded
                    style={{ color: "#000" }}
                    data-tooltip-id="setting-menu"
                    data-tooltip-content='Display Menu' />
            </button>
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px text-start' data-kt-menu='true'>
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bold'>Display Menu</div>
                </div>

                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    <div className='overflow-auto sm-scroll mh-350px'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableSettingMenu