import { useFormik } from 'formik';
import TextField from '../../../../../../components/textFields/TextField';
import { useClearAllLookupsCacheMutation, useGetAllLookupsCacheListQuery } from '../../../../../../services/GeneralApi';
import Select from "react-dropdown-select";
import labelKey from '../../../../../localization/label.json'
import tosterKey from '../../../../../localization/toster.json'

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
type User = {
  value: string;
  label: string;
};
const CacheClearSetting = () => {
  const [updatePrefrences, { data, isLoading, isSuccess, isError, error }] = useClearAllLookupsCacheMutation();
  const { data: getAllLookupsCache } = useGetAllLookupsCacheListQuery('');

  const initialValues = {
    isClrearAll: true,
    lookUpKey: ['']
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      updatePrefrences(values);
      // console.log(values);

    }
  });

  const [selected, setSelected] = useState<User[]>([]);

  let options: User[] = [];

  if (getAllLookupsCache && Array.isArray(getAllLookupsCache)) {
    options = getAllLookupsCache.map((user: any) => ({
      value: user.value,
      label: user.key,
    }));
  }
  const { resetForm } = formik;

  useEffect(() => {
    if (isSuccess) {
      const responseData = (data as any)?.message;
      const successMessage = responseData;
      toast.success(successMessage);
      resetForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  return (
    <div className='card mb-5 mb-xl-10' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      {/* <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#cache_clear_setting'
        aria-expanded='true'
        aria-controls='cache_clear_setting'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Cache Clear</h3>
        </div>
      </div> */}
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
      >
        <div id='cache_clear_setting' className='collapse show '>
          <div className='card-body border-top px-9 py-10 '>
            <div className='row justify-content-end'>
              <div className="col-md-3 col-lg-6">
                <p className='fs-6 fw-bold mt-md-10'>Lookup cache clear</p>
              </div>
              <div className={`${formik.values.isClrearAll === true ? 'col-md-6' : 'col-md-2'}`}>
                <div className='d-flex justify-content-md-end align-items-md-end w-100'>
                  <div>
                    <label className='form-label fs-5 fw-bold mb-0'>{labelKey.clearAll}</label>
                    <div className="form-check form-switch mt-3">
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheckChecked`}
                        {...formik.getFieldProps('isClrearAll')}
                        checked={formik.values.isClrearAll}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.isClrearAll === false &&
                <div className='col-md-7 col-lg-4'>
                  <label className='form-label fw-bold text-dark fs-6 mt-5 mt-md-0'>{labelKey.lookups}</label>

                  <Select
                    options={options}
                    multi
                    selectAll={true}
                    className="multi-select text-dark w-100 mb-5 mb-md-0"
                    onChange={(selectedList: any) => {
                      setSelected(selectedList);
                      formik.setFieldValue('lookUpKey', selectedList.map((option: any) => option.label));
                    }} />
                </div>
              }
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isLoading}>
              {!isLoading && 'Save Changes'}
              {isLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};



export default CacheClearSetting;
