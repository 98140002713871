import React from 'react';
import Select from 'react-select';
import './TextField.css';
import { FaLock } from 'react-icons/fa6';

interface Option {
    value: string | number;
    label: string;
}

interface Props {
    labelClass?: string;
    leftLabel?: string;
    required?: boolean;
    label?: string;
    selectClass?: string;
    onChange: (selectedOption: Option | null) => void;
    disabled?: boolean;
    onBlur?: () => void;
    value?: Option | null;
    name?: string;
    options: Option[];
    placeholder?: string;
    customLabel?: string;
    customLabelClick?: any;
    customLabelClass?: any;
    getOptionLabel?:any
}

const SearchSelect = ({
    labelClass,
    leftLabel,
    required,
    label,
    selectClass,
    onChange,
    disabled,
    name,
    onBlur,
    value,
    options,
    placeholder,
    customLabel,
    customLabelClick,
    customLabelClass,
    getOptionLabel,
    ...fieldProps
}: Props) => {
    return (
        <div className={`${leftLabel ? 'd-md-flex w-100 gap-2' : ''}`}>
            <div className='d-flex justify-content-between align-items-center'>
                <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                    {label}{leftLabel}
                    {required && <span className="required-star text-danger ms-1">*</span>}
                    {disabled && <FaLock className='fs-7 ms-1' />}
                </label>
                <label className={`form-label fw-bold text-primary text-nowrap ${customLabelClass}`} onClick={customLabelClick} style={{ fontSize: '10px' }}>
                    {customLabel}
                </label>

            </div>
            <Select
                className={`${selectClass}`}
                onChange={onChange}
                isDisabled={disabled}
                onBlur={onBlur}
                value={value}
                name={name}
                options={options}
                placeholder={placeholder}
                getOptionLabel={getOptionLabel}
                {...fieldProps}
            />
        </div>
    );
};

export default SearchSelect;
