import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import BGBlur from '../../../../components/loadingComponent/BGBlur';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import TextField from '../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../constraintConfig';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import { useGetAppRequestSeverityLookupQuery, useGetAppResolutionTypeLookupQuery, useGetSupportRequestStatusLookupQuery, useGetSupportUserLookupQuery } from '../../../../services/GeneralApi';
import { useUploadFileToServerForSupportAttachmentMutation } from '../../../../services/IntegrartionApi';
import { useUpdatePortalSupportRequestMutation } from '../../../../services/PortalSupportApi';
import labelKey from '../../../localization/label.json';
import tosterKey from '../../../localization/toster.json';
import DeleteAttachmentModal from './DeleteAttachmentModal';
import { topics } from './HelpCenterDropdowns';
import UserDetailModal from '../../users/UserDetailModal';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
    viewOnlyData?: boolean
}
interface LightboxData {
    imageURL: string;
}
interface Attachment {
    url: string;
    fileType: string;
    supportRequestAttachmentId: number,
    supportRequestId: number,
}

const UpdateSupportRequestModal = ({ show, handleClose, data, viewOnlyData }: Props) => {
    const [updatePortalRequest, { data: helpCenterData, isLoading, isSuccess, isError, error }] = useUpdatePortalSupportRequestMutation()
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const [uploadFileToASupportAttacment, { data: azurefileData, isLoading: fileIsloading }] = useUploadFileToServerForSupportAttachmentMutation();
    const { data: getSupportUserLookup, isLoading: getSupportUserLookupIsLoading, refetch } = useGetSupportUserLookupQuery('')
    const { data: getSupportStatusLookup, isLoading: getSupportStatusLookupIsLoading } = useGetSupportRequestStatusLookupQuery('')
    const { data: getSeverityLookup } = useGetAppRequestSeverityLookupQuery('')
    const { data: getResolutionTypeLookup } = useGetAppResolutionTypeLookupQuery('')


    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const adminFileInputRef = useRef<HTMLInputElement | null>(null);

    const { roleID } = useAppSelector(selectAuth)

    const initialValues = {
        supportRequestId: 0,
        topic: '',
        subject: '',
        description: '',
        supportRequestStatusId: 0,
        requestTrackingID: '',
        assignedToUserDate: '',
        assignedToOrgUser: {
            id: 0,
            value: ''
        },
        appResolutionType: {
            id: 0,
            value: ''
        },
        appRequestSeverity: {
            id: 0,
            value: ''
        },
        estimatedResolvedTime: '',
        section: '',
        resolutionNotes: '',
        supportRequestStatus: {
            id: 0,
            value: ''
        },
        requester: {
            id: 0,
            value: ''
        },
        createdAt: '',
        lastUpdated: '',
        internalNotes: '',
        actualResolutionDate: '',
        attachments: [] as Attachment[],
        adminAttachments: [] as Attachment[],
        isEmailNotificationSentToRequester: true,
        qaCompleted: true,
        deploymentNeeded: true,
        deploymentReady: true,
        deployed: true,

    }
    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.supportRequestId);
            formik.setValues({
                ...data,
                resolutionNotes: data.resolutionNotes ?? '',
            });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);
    const today = new Date().toISOString().split('T')[0];
    const validationSchema = Yup.object().shape({
        topic: Yup.string().required('Topic is required'),
        section: Yup.string().required('Section is required'),
        subject: Yup.string()
            .required('Subject is required')
            .max(240, 'Subject must be at most 240 characters'),
        description: Yup.string()
            .required('Description is required')
            .max(1000, 'Description must be at most 1000 characters'),
        assignedToOrgUser: Yup.object().shape({
            id: Yup.number().min(1, 'Assigned To User is required').required('Assigned To User is required'),
        }),
        supportRequestStatus: Yup.object().shape({
            id: Yup.number().min(1, 'Status is required').required('Status is required'),
        }),
        appRequestSeverity: Yup.object().shape({
            id: Yup.number().min(1, 'Severity is required').required('Severity is required'),
        }),
        appResolutionType: Yup.object().shape({
            id: Yup.number().min(1, 'Resolution Type is required').required('Resolution Type is required'),
        }),
        estimatedResolvedTime: Yup.date()
            .nullable()
            .required('Estimated Resolved Time is required')
            .min(today, 'Date cannot be in the past'),
        resolutionNotes: Yup.string()
            .nullable()
            .when('supportRequestStatus.id', {
                is: (id: any) => id === 4,
                then: Yup.string().required('Resolution Notes are required when status is Resolved'),
                otherwise: Yup.string().nullable(),
            }),
    })
    const handleImageChangeFirst = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const updatedAttachments = [...formik.values.attachments];  // Copy current attachments

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onload = async () => {
                    // Process file and get URL
                    const formData = new FormData();
                    formData.append('file', file);
                    try {
                        const response = await uploadFileToASupportAttacment(formData);
                        const uploadedFileUrl = (response as { data?: any })?.data?.result;

                        // Add new attachment to the array
                        updatedAttachments.push({
                            url: uploadedFileUrl,
                            fileType: file.type,
                            supportRequestAttachmentId: 0,
                            supportRequestId: 0,
                        });

                        // Update Formik field value
                        formik.setFieldValue('attachments', updatedAttachments);
                    } catch (error) {
                        console.error('Error uploading file:', error);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };
    const handleImageforAdminAttachments = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const updatedAttachments = [...formik.values.adminAttachments];  // Copy current attachments

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onload = async () => {
                    // Process file and get URL
                    const formData = new FormData();
                    formData.append('file', file);
                    try {
                        const response = await uploadFileToASupportAttacment(formData);
                        const uploadedFileUrl = (response as { data?: any })?.data?.result;

                        // Add new attachment to the array
                        updatedAttachments.push({
                            url: uploadedFileUrl,
                            fileType: file.type,
                            supportRequestAttachmentId: 0,
                            supportRequestId: 0,
                        });

                        // Update Formik field value
                        formik.setFieldValue('adminAttachments', updatedAttachments);
                    } catch (error) {
                        console.error('Error uploading file:', error);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            updatePortalRequest(values)
        }
    })
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    const { resetForm } = formik;


    useEffect(() => {
        if (isSuccess) {
            // const responseData = (helpCenterData as any)?.message;
            // const successMessage = responseData;
            // toast.success(successMessage);
            toast.success(tosterKey.portalSupportRequestUpdatedSuccesfully);
            resetForm();
            handleClose();
        }

    }, [isSuccess]);
    useEffect(() => {
        if (show) {
            refetch();
        }
    }, [show]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);


    const modules = [
        ...(roleID !== constraintConfig.roleID.role10 ? [
            {
                id: 'Dashboard',
                value: 'Dashboard'
            },
            {
                id: 'Expense Wizard',
                value: 'Expense Wizard'
            },
            {
                id: 'Expense Line Items',
                value: 'Expense Line Items'
            },
            {
                id: 'Expense Reports',
                value: 'Expense Reports'
            },
            {
                id: 'Receipt Vault',
                value: 'Receipt Vault'
            },
            {
                id: 'Cards',
                value: 'Cards'
            },
            {
                id: 'Report Analytics',
                value: 'Report Analytics'
            },
        ] : []),
        ...(roleID !== constraintConfig.roleID.role5 && roleID !== constraintConfig.roleID.role10 ? [
            {
                id: 'Approval Queue',
                value: 'Approval Queue'
            },
        ] : []),
        ...(roleID === constraintConfig.roleID.role5 ? [
            {
                id: 'My Budget History',
                value: 'My Budget History'
            },
        ] : []),

        ...(roleID === constraintConfig.roleID.role4 ? [
            {
                id: 'Team Management',
                value: 'Team Management'
            },
            {
                id: 'Budget Allocation',
                value: 'Budget Allocation'
            },
        ] : []),
        ...(roleID === constraintConfig.roleID.role2 ? [
            {
                id: 'Expense Users',
                value: 'Expense Users'
            },
            {
                id: 'Org Settings',
                value: 'Org Settings'
            },
            {
                id: 'Vendor',
                value: 'Vendor'
            },
            {
                id: 'Workflow',
                value: 'Workflow'
            },
            {
                id: 'Mileage',
                value: 'Mileage'
            },
            {
                id: 'User Flex Budget',
                value: 'User Flex Budget'
            },
            {
                id: 'Category Budget Allocation',
                value: 'Category Budget Allocation'
            },
            {
                id: 'Categories',
                value: 'Categories'
            },
            {
                id: 'Locations',
                value: 'Locations'
            },
            {
                id: 'Departments',
                value: 'Departments'
            },
            {
                id: 'Divisions',
                value: 'Divisions'
            },
            {
                id: 'Countries',
                value: 'Countries'
            },
            {
                id: 'Expense Violation',
                value: 'Expense Violation'
            },
            {
                id: 'Automation - Bank CC Feed',
                value: 'Automation - Bank CC Feed'
            }
        ] : []),
        ...(roleID === constraintConfig.roleID.role10 ? [
            {
                id: 'Expense Approval',
                value: 'Expense Approval'
            },

        ] : [])
    ];

    const [showAttachmentDeleteModal, setShowAttachmentDeleteModal] = useState(false)
    const [attachmentDeleteData, setAttachmentDeleteData] = useState<number | null>(null);
    const [adminAttachmentDeleteData, setAdminAttachmentDeleteData] = useState<number | null>(null);

    const deleteHandler = () => {
        setShowAttachmentDeleteModal((prev) => !prev);
    };
    const [showUserDetailModal, setShowUserDetailModal] = useState(false)
    const [userDetailData, setuserDetailData] = useState({});
    const userDetailHandler = () => {
        setShowUserDetailModal((prev) => !prev);
    };
    return (
        <>
            <DeleteAttachmentModal
                show={showAttachmentDeleteModal}
                handleClose={() => setShowAttachmentDeleteModal(false)}
                data={attachmentDeleteData}
                adminAttachmentDeleteData={adminAttachmentDeleteData}
                formik={formik}
                fileInputRef={fileInputRef}
                adminFileInputRef={adminFileInputRef} />
            <UserDetailModal show={showUserDetailModal} handleClose={() => setShowUserDetailModal(false)} data={userDetailData} />

            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{viewOnlyData === true ? "Portal Support Request Detail" : "Update Portal Support Request"}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>
                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10 overflow-auto sm-scroll' style={{ height: '70vh' }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.requestTrackingID}
                                            type='text'
                                            {...formik.getFieldProps('requestTrackingID')}
                                            value={formik.values.requestTrackingID}
                                            disabled={viewOnlyData || true}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <label className='form-label fw-bold text-dark fs-6 text-nowrap'>{labelKey.requester}</label>
                                        <div
                                            className='form-control form-control-lg form-control-solid default-input text-primary'
                                            onClick={() => {
                                                setuserDetailData(formik.values?.requester?.id);
                                                userDetailHandler();
                                            }}>
                                            {formik.values?.requester?.value}
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.requestDate}
                                            type='datetime-local'
                                            {...formik.getFieldProps('createdAt')}
                                            value={formik.values.createdAt ? moment(formik.values.createdAt).format('YYYY-MM-DDTHH:mm') : ''}
                                            disabled={viewOnlyData || true}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <SearchSelect
                                            label={labelKey.topic}
                                            required
                                            options={[
                                                { value: '', label: 'Select Topic' },
                                                ...(topics || []).map((option: any) => ({
                                                    value: option?.id,
                                                    label: option.value,
                                                    key: option.id,
                                                }))]}
                                            placeholder="Select Topic"
                                            value={
                                                formik.values?.topic
                                                    ? {
                                                        value: formik.values?.topic,
                                                        label: formik.values?.topic || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('topic', selectedOption.value);
                                            }}
                                            disabled={viewOnlyData || true}
                                        />
                                        {formik.touched.topic && formik.errors.topic && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.topic}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <SearchSelect
                                            label={labelKey.section}
                                            required
                                            options={[
                                                { value: '', label: 'Select Section' },
                                                ...(modules || []).map((option: any) => ({
                                                    value: option?.id,
                                                    label: option.value,
                                                    key: option.id,
                                                }))]}
                                            placeholder="Select Section"
                                            value={
                                                formik.values?.section
                                                    ? {
                                                        value: formik.values?.section,
                                                        label: formik.values?.section || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('section', selectedOption.value);
                                            }}
                                            disabled={viewOnlyData || true}
                                        />
                                        {formik.touched.section && formik.errors.section && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.section}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-12 mb-5'>
                                        <TextField
                                            label={labelKey.subject}
                                            required={true}
                                            type='text'
                                            placeholder='Enter Subject'
                                            {...formik.getFieldProps('subject')}
                                            disabled={viewOnlyData || true}
                                        />
                                        {formik.touched.subject && formik.errors.subject && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.subject}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-12 mb-5'>
                                        <TextAreaField
                                            label={labelKey.requestDescription}
                                            required={true}
                                            placeholder='Enter Description'
                                            rows={5}
                                            {...formik.getFieldProps('description')}
                                            disabled={viewOnlyData || true}

                                        />
                                        {formik.touched.description && formik.errors.description && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.description}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12 position-relative">
                                        <div className="row">
                                            <div className='col-md-12 mb-5'>
                                                <label className='form-label fs-5 fw-bold'>{labelKey.requestAttachment}</label>
                                                <div >
                                                    {lightboxOpen && (
                                                        <Lightbox
                                                            mainSrc={lightboxData?.imageURL}
                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                            imageCaption="Attachment"
                                                            enableZoom={true}
                                                            imagePadding={50}
                                                        />
                                                    )}
                                                </div>
                                                {formik.values?.attachments?.length > 0 ? (
                                                    <div className="d-flex overflow-auto sm-scroll gap-5">
                                                        {formik.values?.attachments?.map((attachment, index) => (
                                                            <div className="col-md-6 col-lg-4 position-relative">
                                                                <img
                                                                    className="w-100 my-3"
                                                                    src={attachment.url} width="100%" height="200px"
                                                                    onClick={() => openPdfInBrowserOrLightbox(attachment?.url)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : <p className='text-center fw-bold fs-6 pt-20 p-20'>No Attachments were added by the Requester.</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.assignedToUserDate}
                                            type='date'
                                            placeholder='Enter Subject'
                                            {...formik.getFieldProps('assignedToUserDate')}
                                            value={formik.values.assignedToUserDate ? moment(formik.values.assignedToUserDate).format('YYYY-MM-DD') : ''}
                                            disabled={viewOnlyData}
                                            min={today}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <SearchSelect
                                            label={labelKey.assignedTo}
                                            required
                                            options={[
                                                { value: '', label: 'Select User' },
                                                ...(getSupportUserLookup?.result || []).map((option: any) => ({
                                                    value: option?.id,
                                                    label: option.value,
                                                    key: option.id,
                                                }))]}
                                            placeholder="Select User"
                                            value={
                                                formik.values?.assignedToOrgUser?.id
                                                    ? {
                                                        value: formik.values?.assignedToOrgUser?.id,
                                                        label: formik.values?.assignedToOrgUser?.value || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('assignedToOrgUser.id', selectedOption.value);
                                                formik.setFieldValue('assignedToOrgUser.value', selectedOption.label);
                                            }}
                                            disabled={viewOnlyData || roleID === constraintConfig.roleID.roleIDPortalSupport}
                                        />
                                        {formik.touched.assignedToOrgUser?.id && formik.errors.assignedToOrgUser?.id && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.assignedToOrgUser?.id}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <SearchSelect
                                            label={labelKey.status}
                                            required
                                            options={[
                                                { value: '', label: 'Select Status' },
                                                ...(getSupportStatusLookup?.result || []).map((option: any) => ({
                                                    value: option?.id,
                                                    label: option.value,
                                                    key: option.id,
                                                }))]}
                                            placeholder="Select Status"
                                            value={
                                                formik.values?.supportRequestStatus?.id
                                                    ? {
                                                        value: formik.values?.supportRequestStatus?.id,
                                                        label: formik.values?.supportRequestStatus?.value || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('supportRequestStatus.id', selectedOption.value);
                                                formik.setFieldValue('supportRequestStatus.value', selectedOption.label);
                                            }}
                                            disabled={viewOnlyData}
                                        />
                                        {formik.touched.supportRequestStatus?.id && formik.errors.supportRequestStatus?.id && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.supportRequestStatus?.id}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.estimateResolutionDate}
                                            required={true}
                                            type='date'
                                            placeholder='Enter Subject'
                                            {...formik.getFieldProps('estimatedResolvedTime')}
                                            value={formik.values.estimatedResolvedTime ? moment(formik.values.estimatedResolvedTime).format('YYYY-MM-DD') : ''}
                                            disabled={viewOnlyData}
                                            min={today}
                                        />

                                        {formik.touched.estimatedResolvedTime && formik.errors.estimatedResolvedTime && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.estimatedResolvedTime}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.actualResolutionDate}
                                            type='date'
                                            placeholder='Enter Subject'
                                            {...formik.getFieldProps('actualResolutionDate')}
                                            value={formik.values.actualResolutionDate ? moment(formik.values.actualResolutionDate).format('YYYY-MM-DD') : ''}
                                            disabled={viewOnlyData || true}
                                            min={today}
                                        />
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <SearchSelect
                                            label={labelKey.appResolutionType}
                                            required
                                            options={[
                                                { value: '', label: 'Select Resolution Type' },
                                                ...(getResolutionTypeLookup?.result || []).map((option: any) => ({
                                                    value: option?.id,
                                                    label: option.value,
                                                    key: option.id,
                                                }))]}
                                            placeholder="Select Resolution Type"
                                            value={
                                                formik.values?.appResolutionType?.id
                                                    ? {
                                                        value: formik.values?.appResolutionType?.id,
                                                        label: formik.values?.appResolutionType?.value || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('appResolutionType.id', selectedOption.value);
                                                formik.setFieldValue('appResolutionType.value', selectedOption.label);
                                            }}
                                            disabled={viewOnlyData}
                                        />
                                        {formik.touched.appResolutionType?.id && formik.errors.appResolutionType?.id && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.appResolutionType?.id}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-6 mb-5'>
                                        <SearchSelect
                                            label={labelKey.appRequestSeverity}
                                            required
                                            options={[
                                                { value: '', label: 'Select Request Severity' },
                                                ...(getSeverityLookup?.result || []).map((option: any) => ({
                                                    value: option?.id,
                                                    label: option.value,
                                                    key: option.id,
                                                }))]}
                                            placeholder="Select Request Severity"
                                            value={
                                                formik.values?.appRequestSeverity?.id
                                                    ? {
                                                        value: formik.values?.appRequestSeverity?.id,
                                                        label: formik.values?.appRequestSeverity?.value || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('appRequestSeverity.id', selectedOption.value);
                                                formik.setFieldValue('appRequestSeverity.value', selectedOption.label);
                                            }}
                                            disabled={viewOnlyData}
                                        />
                                        {formik.touched.appRequestSeverity?.id && formik.errors.appRequestSeverity?.id && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.appRequestSeverity?.id}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-md-12 mb-5'>
                                        <TextAreaField
                                            label={labelKey.internalNotes}
                                            placeholder='Enter Internal Notes'
                                            rows={5}
                                            {...formik.getFieldProps('internalNotes')}
                                            disabled={viewOnlyData}
                                        />
                                    </div>
                                    <div className='col-md-12 mb-5'>
                                        <TextAreaField
                                            label={labelKey.resolutionNotes}
                                            placeholder='Enter Resolution Notes'
                                            required={formik.values?.supportRequestStatus?.id === 4 ? true : false}
                                            rows={5}
                                            {...formik.getFieldProps('resolutionNotes')}
                                            disabled={viewOnlyData}
                                        />
                                        {formik.touched.resolutionNotes && formik.errors.resolutionNotes && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.resolutionNotes}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <label className='form-label fs-5 fw-bold'>{labelKey.resolutionStatusSendToRequester}</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input cursor-pointer mt-3"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheckChecked`}
                                                {...formik.getFieldProps('isEmailNotificationSentToRequester')}
                                                checked={formik.values.isEmailNotificationSentToRequester}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <label className='form-label fs-5 fw-bold'>{labelKey.qaCompleted}</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input cursor-pointer mt-3"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheckChecked`}
                                                {...formik.getFieldProps('qaCompleted')}
                                                checked={formik.values.qaCompleted}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <label className='form-label fs-5 fw-bold'>{labelKey.deploymentNeeded}</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input cursor-pointer mt-3"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheckChecked`}
                                                {...formik.getFieldProps('deploymentNeeded')}
                                                checked={formik.values.deploymentNeeded}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <label className='form-label fs-5 fw-bold'>{labelKey.deploymentReady}</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input cursor-pointer mt-3"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheckChecked`}
                                                {...formik.getFieldProps('deploymentReady')}
                                                checked={formik.values.deploymentReady}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-5">
                                        <label className='form-label fs-5 fw-bold'>{labelKey.deployed}</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input cursor-pointer mt-3"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheckChecked`}
                                                {...formik.getFieldProps('deployed')}
                                                checked={formik.values.deployed}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 position-relative">
                                        {fileIsloading &&
                                            <BGBlur />
                                        }
                                        <div className="row">
                                            <div className='col-md-12 mb-5'>
                                                <label className='form-label fs-5 fw-bold'>{labelKey.resolverUploadImages}</label>
                                                {viewOnlyData === false &&
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        accept=".png, .jpg, .jpeg"
                                                        ref={fileInputRef}
                                                        onChange={handleImageforAdminAttachments}
                                                        multiple
                                                        className='form-control form-control-solid default-input'
                                                        disabled={viewOnlyData}
                                                    />}
                                                <div >
                                                    {lightboxOpen && (
                                                        <Lightbox
                                                            mainSrc={lightboxData?.imageURL}
                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                            imageCaption="Attachment"
                                                            enableZoom={true}
                                                            imagePadding={50}
                                                        />
                                                    )}
                                                </div>
                                                {formik.values?.adminAttachments?.length > 0 ? (
                                                    <div className="d-flex overflow-auto sm-scroll gap-5">
                                                        {formik.values?.adminAttachments.map((attachment, index) => (
                                                            <div className="col-md-6 col-lg-4 position-relative">
                                                                {viewOnlyData !== true &&
                                                                    <div
                                                                        className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4'
                                                                        style={{ right: 0, zIndex: 12 }}
                                                                        // onClick={() => deleteAttachmentHandler(index)}
                                                                        onClick={() => {
                                                                            setAdminAttachmentDeleteData(index);
                                                                            setAttachmentDeleteData(attachment?.supportRequestAttachmentId)
                                                                            deleteHandler();
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                                                    </div>}
                                                                <img
                                                                    className="w-100 my-3"
                                                                    style={{ border: '1px solid #afafaf' }}
                                                                    src={attachment.url} width="100%" height="200px"
                                                                    onClick={() => openPdfInBrowserOrLightbox(attachment?.url)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) :
                                                    <p className='text-center fw-bold fs-6 pt-20 p-20'>No Attachments Attached</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        {viewOnlyData === false &&
                            <button
                                type='submit'
                                className='btn btn-primary mb-5 mt-5'
                                disabled={isLoading}
                            >
                                {!isLoading && <span className='indicator-label'>{labelKey.edit}</span>}
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            </button>
                        }
                    </div>
                </form>


            </Modal>
        </>
    )
}

export default UpdateSupportRequestModal