import React, { useEffect, useState } from 'react'
import { useUpdateStatusToApproveExpenseMutation } from '../../../services/WorkFlowApi'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useWorkflowApproverStatusQuery } from '../../../services/GeneralApi'
import { toast } from 'react-toastify'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import { useAppSelector } from '../../../hooks'
import { selectAuth } from '../../../features/authSlice'
import { constraintConfig } from '../../../constraintConfig'


type Props = {
    show: boolean
    handleClose: () => void
    data: any
    handleCloseModals?: any
    rejectExpense?: any
    approvedExpense?: any
}

const validationSchema = Yup.object().shape({
    approverStatus: Yup.object().shape({
        id: Yup.number().min(1, 'Status is required').required('Status is required'),
    }),
});

const UpdateAprroverStatusModal = ({ show, handleClose, data, handleCloseModals, rejectExpense, approvedExpense }: Props) => {
    const [wordCount, setWordCount] = useState(0);
    const { data: workflowApproverStatus, refetch: workflowApproverStatusRefetch } = useWorkflowApproverStatusQuery('')
    const [updateAprroverStatus, { isLoading, isSuccess, isError, error }] = useUpdateStatusToApproveExpenseMutation()
    const { roleID } = useAppSelector(selectAuth);


    const initialValues = {
        approverId: 0,
        approverStatus: {
            id: 0,
            title: ''
        },
        commentByApprover: ''
    }
    const [id, setId] = useState(0);
    useEffect(() => {
        if (data) {
            setId(data?.approverId);
            formik.setValues({ ...data })
        }
    }, [data]);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (String(values.approverStatus?.id) === '2') {
                updateAprroverStatus(values);
            }
            else {
                if (!formik.values.commentByApprover) {
                    toast.error(tosterKey.commentIsRequired);
                } else if (values.commentByApprover.length > 500) {
                    toast.error('Comment cannot exceed 500 characters.');
                }
                else {
                    updateAprroverStatus(values);
                }
            }

        },
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.statusUpdatedSuccessfully);
            if (handleCloseModals) {
                handleCloseModals();
            }
            handleClose()
            resetForm()
        }
    }, [isSuccess]);

    useEffect(() => {
        if (show) {
            workflowApproverStatusRefetch()
        }

    }, [show]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (rejectExpense) {
            formik.setFieldValue('approverStatus.id', 4);
        }
    }, [rejectExpense]);
    useEffect(() => {
        if (approvedExpense) {
            formik.setFieldValue('approverStatus.id', 2);
        }
    }, [approvedExpense]);
    const handleTextAreaChange = (event: any) => {
        const text = event.target.value;
        const words = text.trim().split('');
        const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
        setWordCount(wordCount);
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>
                        {approvedExpense === 'approved' ?
                            <>{labelKey.approvedExpenseReport}</> :
                            rejectExpense === 'rejected' ?
                                <>{labelKey.rejectExpenseReport}</> : ''}
                    </h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-4 px-lg-10'>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='text-semibold fs-4 text-muted'> Are you sure you want to 
                                    {approvedExpense?' approve':' reject'} the expense report? <span className='text-primary'>"{data?.trackingId}"</span></p>
                            </div>
                            {/* <div className='col-md-12 mb-5'>
                                <label className='d-flex align-items-center fs-5 fw-bold mb-2 required'>
                                    {labelKey.expenseStatus}
                                </label>
                                <select
                                    className='form-select form-select-solid'
                                    {...formik.getFieldProps('approverStatus.id')}
                                    disabled={!!rejectExpense || !!approvedExpense}
                                >
                                    <option value="">{labelKey.selectStatus}</option>
                                    {workflowApproverStatus?.result.map((option: any, index: any) => (
                                        <option key={option.id} value={option.id}
                                        >{option?.value}</option>
                                    ))}
                                </select>
                                {formik.touched.approverStatus?.id && formik.errors.approverStatus?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.approverStatus?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div> */}
                            {rejectExpense &&
                                <div className="col-md-12">
                                    <label className={`d-flex align-items-center fs-5 fw-bold mb-2 ${(String(formik.values.approverStatus?.id) === '2') ? "" : "required"}  `}>
                                        {labelKey.comment}
                                    </label>
                                    <div>
                                        <textarea className='form-control form-control-solid'
                                            rows={5}
                                            placeholder='Enter a Comment'
                                            {...formik.getFieldProps('commentByApprover')}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleTextAreaChange(e);
                                            }}
                                        ></textarea>
                                        <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-lg btn-light'
                            onClick={handleClose}
                        >
                            {labelKey.cancel}
                        </button>
                        <button
                            type='submit'
                            className='btn btn-lg btn-primary'
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.yes}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default UpdateAprroverStatusModal