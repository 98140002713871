import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import TextField from '../../../../components/textFields/TextField';
import { useUploadFileToServerForSupportAttachmentMutation } from '../../../../services/IntegrartionApi';
import { usePortalSupportRequestMutation } from '../../../../services/PortalSupportApi';
import labelKey from '../../../localization/label.json';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import { constraintConfig } from '../../../../constraintConfig';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import DeleteAttachmentModal from './DeleteAttachmentModal';
import { useGetAppRequestSeverityLookupQuery } from '../../../../services/GeneralApi';
import AddRequestDeleteAttachmentModal from './AddRequestDeleteAttachmentModal';

interface LightboxData {
    imageURL: string;
}
interface Attachment {
    url: string;
    fileType: string;
}

const validationSchema = Yup.object().shape({
    topic: Yup.string().required('Topic is required'),
    section: Yup.string().required('Section is required'),
    subject: Yup.string()
        .required('Subject is required')
        .max(240, 'Subject must be at most 240 characters'),
    description: Yup.string()
        .required('Description is required')
        .max(1000, 'Description must be at most 1000 characters'),
    appRequestSeverity: Yup.object().shape({
        id: Yup.number().min(1, 'Severity is required').required('Severity is required'),
    }),
})
const HelpCenter = ({ ktHelpClose }: { ktHelpClose: string }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const [helpCenter, { data: helpCenterData, isLoading, isSuccess, isError, error }] = usePortalSupportRequestMutation();
    const [uploadFileToASupportAttacment, { data: azurefileData, isLoading: fileIsloading }] = useUploadFileToServerForSupportAttachmentMutation();
    const { data: getSeverityLookup } = useGetAppRequestSeverityLookupQuery('')
    const { roleID } = useAppSelector(selectAuth)
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const initialValues = {
        supportRequestId: 0,
        topic: '',
        subject: '',
        description: '',
        supportRequestStatusId: 0,
        section: '',
        assignedToOrgUser: {
            id: 0,
            value: ''
        },
        appRequestSeverity: {
            id: 0,
            value: ''
        },
        attachments: [] as Attachment[]
        // attachments: [
        //     // {
        //     //     url: '',
        //     //     fileType: ''
        //     // }
        // ]
    }

    const handleImageChangeFirst = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const updatedAttachments = [...formik.values.attachments];  // Copy current attachments

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onload = async () => {
                    // Process file and get URL
                    const formData = new FormData();
                    formData.append('file', file);
                    try {
                        const response = await uploadFileToASupportAttacment(formData);
                        const uploadedFileUrl = (response as { data?: any })?.data?.result;

                        // Add new attachment to the array
                        updatedAttachments.push({
                            url: uploadedFileUrl,
                            fileType: file.type
                        });

                        // Update Formik field value
                        formik.setFieldValue('attachments', updatedAttachments);
                    } catch (error) {
                        console.error('Error uploading file:', error);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };

    // const deleteAttachmentHandler = (index: number) => {
    //     const updatedAttachments = formik.values.attachments.filter((_, i) => i !== index);
    //     formik.setFieldValue('attachments', updatedAttachments);
    // };
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            helpCenter(values)
            // console.log('values', values);
        }
    })

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    const { resetForm } = formik;


    useEffect(() => {
        if (isSuccess) {
            const responseData = (helpCenterData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            resetForm();

            // Close the drawer
            const closeButton = document.querySelector(ktHelpClose) as HTMLElement;
            if (closeButton) {
                closeButton.click();
            }
        }
    }, [isSuccess, helpCenterData, ktHelpClose]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        const closeButton = document.querySelector(ktHelpClose);
        if (closeButton) {
            // Create a wrapper function for the event listener
            const handleClick = () => {
                resetForm(); // Call the resetForm function
                const fileInput = fileInputRef.current;

                if (fileInput) {
                    fileInput.value = ''; // Clear the input value
                }
            };
            closeButton.addEventListener('click', handleClick);
            return () => {
                closeButton.removeEventListener('click', handleClick);
            };
        }
    }, [ktHelpClose, resetForm]);

    const topics = [
        {
            id: 'Question',
            value: 'Question'
        },
        {
            id: 'Issue',
            value: 'Issue'
        }
    ]
    const modules = [
        ...(roleID !== constraintConfig.roleID.role10 ? [
            {
                id: 'Dashboard',
                value: 'Dashboard'
            },
            {
                id: 'Expense Wizard',
                value: 'Expense Wizard'
            },
            {
                id: 'Expense Line Items',
                value: 'Expense Line Items'
            },
            {
                id: 'Expense Reports',
                value: 'Expense Reports'
            },
            {
                id: 'Receipt Vault',
                value: 'Receipt Vault'
            },
            {
                id: 'Cards',
                value: 'Cards'
            },
            {
                id: 'Report Analytics',
                value: 'Report Analytics'
            },
        ] : []),
        ...(roleID !== constraintConfig.roleID.role5 && roleID !== constraintConfig.roleID.role10 ? [
            {
                id: 'Approval Queue',
                value: 'Approval Queue'
            },
        ] : []),
        ...(roleID === constraintConfig.roleID.role5 ? [
            {
                id: 'My Budget History',
                value: 'My Budget History'
            },
        ] : []),

        ...(roleID === constraintConfig.roleID.role4 ? [
            {
                id: 'Team Management',
                value: 'Team Management'
            },
            {
                id: 'Budget Allocation',
                value: 'Budget Allocation'
            },
        ] : []),
        ...(roleID === constraintConfig.roleID.role2 ? [
            {
                id: 'Expense Users',
                value: 'Expense Users'
            },
            {
                id: 'Org Settings',
                value: 'Org Settings'
            },
            {
                id: 'Vendor',
                value: 'Vendor'
            },
            {
                id: 'Workflow',
                value: 'Workflow'
            },
            {
                id: 'Mileage',
                value: 'Mileage'
            },
            {
                id: 'User Flex Budget',
                value: 'User Flex Budget'
            },
            {
                id: 'Category Budget Allocation',
                value: 'Category Budget Allocation'
            },
            {
                id: 'Categories',
                value: 'Categories'
            },
            {
                id: 'Locations',
                value: 'Locations'
            },
            {
                id: 'Departments',
                value: 'Departments'
            },
            {
                id: 'Divisions',
                value: 'Divisions'
            },
            {
                id: 'Countries',
                value: 'Countries'
            },
            {
                id: 'Expense Violation',
                value: 'Expense Violation'
            },
            {
                id: 'Automation - Bank CC Feed',
                value: 'Automation - Bank CC Feed'
            }
        ] : []),
        ...(roleID === constraintConfig.roleID.role10 ? [
            {
                id: 'Expense Approval',
                value: 'Expense Approval'
            },

        ] : [])
    ];
    const [showAttachmentDeleteModal, setShowAttachmentDeleteModal] = useState(false)
    const [attachmentDeleteData, setAttachmentDeleteData] = useState({});

    const deleteHandler = () => {
        setShowAttachmentDeleteModal((prev) => !prev);
    };

    return (
        <>
            <AddRequestDeleteAttachmentModal
                show={showAttachmentDeleteModal}
                handleClose={() => setShowAttachmentDeleteModal(false)}
                data={attachmentDeleteData}
                formik={formik}
                fileInputRef={fileInputRef} />
            <div>
                <form
                    className='form w-100 px-5'
                    onSubmit={formik.handleSubmit}
                >
                    <div className="row">
                        <div className='col-md-6 mb-5'>
                            <SearchSelect
                                label={labelKey.topic}
                                required
                                options={[
                                    { value: '', label: 'Select Topic' },
                                    ...(topics || []).map((option: any) => ({
                                        value: option?.id,
                                        label: option.value,
                                        key: option.id,
                                    }))]}
                                placeholder="Select Topic"
                                value={
                                    formik.values?.topic
                                        ? {
                                            value: formik.values?.topic,
                                            label: formik.values?.topic || '',
                                        }
                                        : null
                                }
                                onChange={(selectedOption: any) => {
                                    formik.setFieldValue('topic', selectedOption.value);
                                }}
                            />
                            {formik.touched.topic && formik.errors.topic && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.topic}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-5">
                            <SearchSelect
                                label={labelKey.section}
                                required
                                options={[
                                    { value: '', label: 'Select Section' },
                                    ...(modules || []).map((option: any) => ({
                                        value: option?.id,
                                        label: option.value,
                                        key: option.id,
                                    }))]}
                                placeholder="Select Section"
                                value={
                                    formik.values?.section
                                        ? {
                                            value: formik.values?.section,
                                            label: formik.values?.section || '',
                                        }
                                        : null
                                }
                                onChange={(selectedOption: any) => {
                                    formik.setFieldValue('section', selectedOption.value);
                                }}
                            />
                            {formik.touched.section && formik.errors.section && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.section}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='col-md-12 mb-5'>
                            <SearchSelect
                                label={labelKey.appRequestSeverity}
                                required
                                options={[
                                    { value: '', label: 'Select Request Severity' },
                                    ...(getSeverityLookup?.result || []).map((option: any) => ({
                                        value: option?.id,
                                        label: option.value,
                                        key: option.id,
                                    }))]}
                                placeholder="Select Request Severity"
                                value={
                                    formik.values?.appRequestSeverity?.id
                                        ? {
                                            value: formik.values?.appRequestSeverity?.id,
                                            label: formik.values?.appRequestSeverity?.value || '',
                                        }
                                        : null
                                }
                                onChange={(selectedOption: any) => {
                                    formik.setFieldValue('appRequestSeverity.id', selectedOption.value);
                                    formik.setFieldValue('appRequestSeverity.value', selectedOption.label);
                                }}
                            />
                            {formik.touched.appRequestSeverity?.id && formik.errors.appRequestSeverity?.id && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.appRequestSeverity?.id}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='col-md-12 mb-5'>
                            <TextField
                                label={labelKey.subject}
                                required={true}
                                type='text'
                                placeholder='Enter Subject'
                                {...formik.getFieldProps('subject')}
                            />
                            {formik.touched.subject && formik.errors.subject && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.subject}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='col-md-12 mb-5'>
                            <TextAreaField
                                label={labelKey.description}
                                required={true}
                                placeholder='Enter Description'
                                rows={5}
                                {...formik.getFieldProps('description')}
                            />
                            {formik.touched.description && formik.errors.description && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.description}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='col-md-12 mb-5'>
                            {/* <TextField
                                label={labelKey.attachments}
                                type='file'
                                id="file"
                                name="file"
                                accept=".png, .jpg, .jpeg"
                                ref={fileInputRef}
                                onChange={handleImageChangeFirst}
                                multiple={true}
                            /> */}
                            <label className='form-label fs-5 fw-bold'>{labelKey.uploadImages}</label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                accept=".png, .jpg, .jpeg"
                                ref={fileInputRef}
                                onChange={handleImageChangeFirst}
                                multiple
                                className='form-control form-control-solid default-input'
                            />
                            {fileIsloading &&
                                <div className='text-center'>
                                    <LoadingComponent />
                                </div>
                            }
                            <div >
                                {lightboxOpen && (
                                    <Lightbox
                                        mainSrc={lightboxData.imageURL}
                                        onCloseRequest={() => setLightboxOpen(false)}
                                        imageCaption="Attachment"
                                        enableZoom={true}
                                        imagePadding={50}
                                    />
                                )}
                            </div>
                            {formik.values.attachments.length > 0 && (
                                <div className="row">
                                    {formik.values.attachments.map((attachment, index) => (
                                        <div className="col-md-6 position-relative">
                                            <div
                                                className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4'
                                                style={{ right: 0, zIndex: 12 }}
                                                // onClick={() => deleteAttachmentHandler(index)}
                                                onClick={() => {
                                                    setAttachmentDeleteData(index);
                                                    deleteHandler();
                                                }}
                                            >
                                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                            </div>
                                            <img
                                                className="w-100 mt-3"
                                                src={attachment.url} width="100%" height="200px"
                                                onClick={() => openPdfInBrowserOrLightbox(attachment?.url)}
                                            />
                                        </div>
                                    ))}
                                    {/* <div className="col-md-12">
                                <Carousel className='mt-3'>
                                    {formik.values.attachments.map((attachment, index) => (
                                        <Carousel.Item key={index}>
                                            <div
                                                className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4'
                                                style={{ right: 0, zIndex: 12 }}
                                                // onClick={() => deleteAttachmentHandler(index)}
                                                onClick={() => {
                                                    setAttachmentDeleteData(index);
                                                    deleteHandler();
                                                }}
                                            >
                                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                            </div>
                                            <img
                                                className="d-block w-100"
                                                src={attachment.url} width="75%" height="300px"
                                                onClick={() => openPdfInBrowserOrLightbox(attachment?.url)}
                                            />
                                        </Carousel.Item>
                                        
                                    ))}
                                </Carousel>
                            </div> */}
                                </div>
                            )}
                        </div>

                    </div>
                    <div className='modal-footer'>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading || fileIsloading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}

                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>

                </form>
            </div>
        </>

    )
}

export default HelpCenter