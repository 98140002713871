// import moment from 'moment';
import moment from 'moment-timezone';

import React from 'react';
import { useAppSelector } from '../../hooks';
import { selectAuth } from '../../features/authSlice';

interface Props {
    formattedDate?: string;
}

const TimeComp: React.FC<Props> = ({ formattedDate }) => {
    const { timeZone } = useAppSelector(selectAuth);
    
    const safeTimeZone = timeZone || 'UTC';

    const parsedDate = formattedDate
        ? moment.utc(formattedDate) // Parses date as UTC to avoid local time misinterpretations
        : null;
    const formattedDateTimeString = parsedDate
        ? parsedDate.tz(safeTimeZone).format('hh:mm:ss A')
        : '';
    return (
        <>{formattedDateTimeString}</>
    );
  
}

export default TimeComp