import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../../components/dateComponent/DateComp';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import { useGetAllBankFilesLineItemsQuery } from '../../../../services/AutomationApi';
import labelKey from '../../../localization/label.json';
import { constraintConfig } from '../../../../constraintConfig';

type Props = {
    bankFeedData?: any
}
const AutomationCCFeedsStatsEnhancements = ({ bankFeedData }: Props) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("itemImportDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("itemImportDate");
    const [filterByStatus, setFilterByStatus] = useState(true);
    const [activeTab, setActiveTab] = useState(true);
    const [isLoading, setIsLoading] = useState(false); // Track loading state

    // Debounced search state
    // const [searchStr, setSearchStr] = useState('');

    const { data, refetch } = useGetAllBankFilesLineItemsQuery({
        fileInfoId: bankFeedData?.fileInfoId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        sortBy: sortBy,
        sortOrder: sortOrder,
        IslinkedItems: filterByStatus
    })
    useEffect(() => {
        setIsLoading(true); // Set loading to true when filter changes
        refetch().finally(() => setIsLoading(false)); // Finally, set loading to false when refetch is done
    }, [filterByStatus, pageNumber, pageSize, refetch]);

    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    useEffect(() => {
        if (searchStr !== "" && sortOrder !== "" && sortBy !== "") {
            // Trigger the API call after the debounce time
            refetch();
        }
    }, [searchStr, sortOrder, sortBy, refetch]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['cCTransactionID', 'lineItemDetailEntityId', 'bankVendorName', 'employeeId', 'itemImportDate', 'merchantType', 'itemDescription', 'transactionDate', 'productCode', 'ccTotalAmount', 'userFullName', 'expenseReportId', 'expenseCategoryName', 'vendorName', 'itemDate', 'itemAmount', 'currency', 'itemDebitOrCreditIndicator', 'debitOrCreditIndicator']);
    const handleToggleColumn = (columnId: string) => {
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };
    const handleTabChange = (status: boolean) => {
        setActiveTab(status);
        setFilterByStatus(status);
        setTimeout(() => {
            refetch();
        }, 200);
    };
    return (
        <>
            <div className='card mb-5'>

                <div className='card-header border-0 pt-6'>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='btn-group overflow-auto' style={{ minWidth: "max-content" }}>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === true ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(true)}
                            >
                                {labelKey.lineItems}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === false ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(false)}
                            >
                                {labelKey.lineItemsPending}
                            </button>
                        </div>
                        <SearchBarComponent
                            placeholder='User Name'
                            value={immediateSearchStr}
                            onChange={handleSearchChange} // Use immediate search handler
                        />
                    </div>
                </div>
                <div className='card-body py-8'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>

                                        <>
                                            {hiddenColumns.includes('cCTransactionID') && (
                                                <TableHeading
                                                    label={labelKey.cCTransactionID}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='cCTransactionID'
                                                    className="ps-4"
                                                />
                                            )}

                                            {hiddenColumns.includes('merchantType') && (
                                                <TableHeading
                                                    label={labelKey.merchantType}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='merchantType'
                                                    className="ps-4"
                                                />
                                            )}
                                            {/* {hiddenColumns.includes('productCode') && (
                                                <TableHeading
                                                    label={labelKey.productCode}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='productCode'
                                                />
                                            )} */}
                                            {hiddenColumns.includes('itemDescription') && (
                                                <TableHeading
                                                    label={labelKey.itemDescription}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='itemDescription'
                                                />
                                            )}
                                            {hiddenColumns.includes('itemImportDate') && (
                                                <TableHeading
                                                    label={labelKey.itemImportDate}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='itemImportDate'
                                                    className="w-100px text-end pe-15"
                                                />
                                            )}
                                            {hiddenColumns.includes('transactionDate') && (
                                                <TableHeading
                                                    label={labelKey.transactionDate}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='transactionDate'
                                                    className="w-100px text-end"
                                                />
                                            )}
                                            {hiddenColumns.includes('bankVendorName') && (
                                                <TableHeading
                                                    label={labelKey.vendor}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='bankVendorName'
                                                    className="w-100px text-end"
                                                />
                                            )}
                                            {hiddenColumns.includes('debitOrCreditIndicator') && (
                                                <TableHeading
                                                    label={labelKey.debitOrCreditIndicator}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='debitOrCreditIndicator'
                                                    className="w-100px text-end"
                                                />
                                            )}
                                            {hiddenColumns.includes('ccTotalAmount') && (
                                                <TableHeading
                                                    label={labelKey.ccTotalAmount}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='ccTotalAmount pe-4'
                                                    labelClassName="justify-content-end"
                                                />
                                            )}
                                        </>
                                        {activeTab === true &&
                                            <>
                                                {/* {hiddenColumns.includes('lineItemDetailEntityId') && (
                                                    <TableHeading
                                                        label={labelKey.lineItemDetailEntityId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='lineItemDetailEntityId'
                                                        className="ps-4"
                                                        labelClassName="text-primary"
                                                    />
                                                )} */}
                                                {hiddenColumns.includes('employeeId') && (
                                                    <TableHeading
                                                        label={labelKey.employeeId}
                                                        columnId='employeeId'
                                                        className="ps-4"
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('userFullName') && (
                                                    <TableHeading
                                                        label={labelKey.userName}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='userFullName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('expenseReportId') && (
                                                    <TableHeading
                                                        label={labelKey.reportId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='expenseReportId'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('expenseCategoryName') && (
                                                    <TableHeading
                                                        label={labelKey.category}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='expenseCategoryName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('vendorName') && (
                                                    <TableHeading
                                                        label={labelKey.vendor}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='vendorName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('itemDate') && (
                                                    <TableHeading
                                                        label={labelKey.itemDate}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='itemDate'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('currency') && (
                                                    <TableHeading
                                                        label={labelKey.currency}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='currency'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('itemDebitOrCreditIndicator') && (
                                                    <TableHeading
                                                        label={labelKey.debitOrCreditIndicator}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='itemDebitOrCreditIndicator'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('itemAmount') && (
                                                    <TableHeading
                                                        label={labelKey.itemAmount}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='itemAmount'
                                                        className="pe-4"
                                                        labelClassName="justify-content-end text-primary"
                                                    />
                                                )}
                                            </>
                                        }


                                        {/* <th className='text-end rounded-end pe-2 border-0 d-none'>
                                            <TableSettingMenu>

                                                <TextField
                                                    rightLabel={labelKey.merchantType}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("merchantType")}
                                                    checked={hiddenColumns.includes('merchantType')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.productCode}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("productCode")}
                                                    checked={hiddenColumns.includes('productCode')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemDescription}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemDescription")}
                                                    checked={hiddenColumns.includes('itemDescription')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemImportDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemImportDate")}
                                                    checked={hiddenColumns.includes('itemImportDate')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.transactionDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("transactionDate")}
                                                    checked={hiddenColumns.includes('transactionDate')}
                                                    fieldClass='mb-4'
                                                />

                                                <TextField
                                                    rightLabel={labelKey.ccTotalAmount}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("ccTotalAmount")}
                                                    checked={hiddenColumns.includes('ccTotalAmount')}
                                                    fieldClass='mb-4'
                                                />
                                                {activeTab === true &&
                                                    <>
                                                        <TextField
                                                            rightLabel={labelKey.employeeId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("employeeId")}
                                                            checked={hiddenColumns.includes('employeeId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.userName}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("userFullName")}
                                                            checked={hiddenColumns.includes('userFullName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.reportId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("expenseReportId")}
                                                            checked={hiddenColumns.includes('expenseReportId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.category}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("expenseCategoryName")}
                                                            checked={hiddenColumns.includes('expenseCategoryName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.vendor}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("vendorName")}
                                                            checked={hiddenColumns.includes('vendorName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.itemDate}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("itemDate")}
                                                            checked={hiddenColumns.includes('itemDate')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.currency}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("currency")}
                                                            checked={hiddenColumns.includes('currency')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.itemAmount}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("itemAmount")}
                                                            checked={hiddenColumns.includes('itemAmount')}
                                                            fieldClass='mb-4'
                                                        />
                                                    </>}
                                            </TableSettingMenu>
                                        </th> */}

                                    </tr>
                                </thead>
                                <tbody>

                                    {!isLoading &&
                                        <>
                                            {data?.result?.data?.length > 0 ? (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('cCTransactionID') && (
                                                                <td className='ps-4'>{data?.processorTransactionId ? data?.processorTransactionId : "N/A"}</td>
                                                            )}

                                                            {hiddenColumns.includes('merchantType') && (
                                                                <td>{data?.merchantType ? data?.merchantType : "N/A"}</td>
                                                            )}
                                                            {/* {hiddenColumns.includes('productCode') && (
                                                                <td>
                                                                    {data?.productCode}
                                                                </td>
                                                            )} */}
                                                            {hiddenColumns.includes('itemDescription') && (
                                                                <td>
                                                                    <div className='w-175px text-wrap'>
                                                                        {data?.itemDescription ? data?.itemDescription : "N/A"}
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('itemImportDate') && (
                                                                <td className='text-end pe-15'><DateComp formattedDate={data?.itemImportDate} /> </td>
                                                            )}
                                                            {hiddenColumns.includes('transactionDate') && (
                                                                <td className='text-end'><DateComp formattedDate={data?.transactionDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('bankVendorName') && (
                                                                <td>{data?.bankVendorName}</td>
                                                            )}
                                                            {hiddenColumns.includes('debitOrCreditIndicator') && (
                                                                <td className={`text-center ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>{data?.debitOrCreditIndicator}</td>
                                                            )}
                                                            {hiddenColumns.includes('ccTotalAmount') && (
                                                                <td className={`text-end pe-4 ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                    <CurrencyComp amount={data?.ccTotalAmount} />
                                                                </td>
                                                            )}
                                                            {activeTab === true &&
                                                                <>
                                                                    {/* {hiddenColumns.includes('lineItemDetailEntityId') && (
                                                                        <td>{data?.lineItemDetailEntityId ? data?.lineItemDetailEntityId : "N/A"}</td>
                                                                    )} */}
                                                                    {hiddenColumns.includes('employeeId') && (
                                                                        <td className='ps-4'>{data?.employeeId}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('userFullName') && (
                                                                        <td>
                                                                            {data?.userFullName}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseReportId') && (
                                                                        <td>
                                                                            {data?.expenseReportId}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseCategoryName') && (
                                                                        <td>
                                                                            {data?.expenseCategory?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('vendorName') && (
                                                                        <td>
                                                                            {data?.vendor?.name}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemDate') && (
                                                                        <td className='text-center'>
                                                                            <DateComp formattedDate={data?.itemDate} />
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('currency') && (
                                                                        <td className='text-center'>
                                                                            {data?.currency?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemDebitOrCreditIndicator') && (
                                                                        <td className={`text-center ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                            {data?.itemDebitOrCreditIndicator}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemAmount') && (
                                                                        <td className={`text-end pe-4 ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                            <CurrencyComp amount={data?.itemAmount} />
                                                                        </td>
                                                                    )}
                                                                </>}
                                                        </tr>
                                                    ))}
                                                </>) : (
                                                <tr>
                                                    <td colSpan={20}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={20} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AutomationCCFeedsStatsEnhancements