import axios from 'axios'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactReadMoreReadLess from "react-read-more-read-less"
import { KTSVG } from '../../../_metronic/helpers'
import DateTimeComp from '../../../components/dateComponent/DateTimeComp'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import labelKey from "../../localization/label.json"
type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const ExpenseLogModal = ({ show, handleClose, data, }: Props) => {

    const { token, baseURL } = useAppSelector(selectAuth);
    const [getExpenseLog, setGetExpenseLog] = useState<any>(null); // Initialize with null
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            if (show && data) {
                setIsLoading(true); // Set loading state to true
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };
                    const getExpenseLogResponse = await axios.get(
                        `${baseURL}/api/Expense${API_END_POINTS.expenseLog}?expenseId=${data?.expenseId}`,
                        {
                            headers,
                        }
                    );
                    const getExpenseLog = getExpenseLogResponse.data;
                    setGetExpenseLog(getExpenseLog);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Set loading state to false after fetching data
                }
            }
        };
        fetchData();
    }, [show, data, token]);

    const exportToExcel = async () => {
        if (getExpenseLog?.result) {
            // Create a new workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Expense Log');

            // Define columns with header names and set column widths
            worksheet.columns = [
                { header: 'Activity By', key: 'activityBy', width: 30 }, // Roughly equivalent to 300px
                { header: 'Activity Message', key: 'activityMessage', width: 50 }, // Adjust as needed
                { header: 'Comment By Approvers', key: 'commentByApprovers', width: 50 }, // Adjust as needed
                { header: 'Comment By Users', key: 'commentByUsers', width: 50 }, // Adjust as needed
                { header: 'Status', key: 'status', width: 20 }, // Adjust as needed
                { header: 'Activity Date and Time', key: 'activityDateTime', width: 30 }, // Adjust as needed
            ];

            // Apply bold styling to header row
            worksheet.getRow(1).font = { bold: true };

            // Add rows from data
            getExpenseLog.result.forEach((item: any) => {
                worksheet.addRow({
                    activityBy: item?.approver ? `${item?.approver?.firstName} ${item?.approver?.lastName}` : 'N/A',
                    activityMessage: item?.comments || 'N/A',
                    commentByApprovers: item?.commentByApprover || 'N/A',
                    commentByUsers: item?.commentByUsers || 'N/A',
                    status: item?.status?.title || 'N/A',
                    activityDateTime: item?.approvedDate ? moment(item?.approvedDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
                });
            });

            // Generate Excel file and save
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), `ExpenseLog_${data?.trackingId}.xlsx`);
        }
    };

    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header py-4'>
                    <h2 className='mb-0'>{labelKey.expenseLogs} ({data?.trackingId})</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <div className='modal-body py-lg-5 px-lg-10'>
                    <div className='row'>
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr>
                                        {/* <th className='ps-4 rounded-start'>{labelKey.sr}</th> */}
                                        <th className='ps-4 rounded-start'>{labelKey.activityBy}</th>
                                        <th className='w-300px'>{labelKey.activityMessage}</th>
                                        <th className='w-300px'>{labelKey.commentByApprovers}</th>
                                        <th className='w-300px'>{labelKey.commentByUsers}</th>
                                        <th>{labelKey.status}</th>
                                        <th className='rounded-end text-end pe-4'>{labelKey.activityDateAndTime}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading && (<>
                                        {getExpenseLog?.result?.length > 0 ?
                                            <>
                                                {getExpenseLog?.result?.toReversed()?.map((data: any, index: any) => (
                                                    <tr key={index}>
                                                        {/* <td className='ps-4'>{index + 1}</td> */}
                                                        <td className='ps-4'>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    {data?.approver?.imageUrl ? <img src={data?.approver?.imageUrl} alt="" /> :
                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.approver?.firstName.charAt(0)}</div>
                                                                    }
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <p className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.approver?.firstName} {data?.approver?.middleName} {data?.approver?.lastName}</p>
                                                                    <span className='text-lowercase'>{data?.approver?.email}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='w-300px text-wrap'>
                                                            {data && data.comments && <ReactReadMoreReadLess
                                                                charLimit={30}
                                                                readMoreText={"Read more"}
                                                                readLessText={"Read less"}
                                                                readMoreClassName="readMore"
                                                                readLessClassName="readLess"
                                                            >
                                                                {data?.comments ? data?.comments : "N/A"}
                                                            </ReactReadMoreReadLess>}
                                                        </td>
                                                        <td className='w-300px text-wrap'>
                                                            {data && data.commentByApprover && <ReactReadMoreReadLess
                                                                charLimit={30}
                                                                readMoreText={"Read more"}
                                                                readLessText={"Read less"}
                                                                readMoreClassName="readMore"
                                                                readLessClassName="readLess"
                                                            >
                                                                {data?.commentByApprover ? data?.commentByApprover : "N/A"}
                                                            </ReactReadMoreReadLess>}
                                                        </td>
                                                        <td className='w-300px text-wrap'>
                                                            {data && data.commentByUsers && <ReactReadMoreReadLess
                                                                charLimit={30}
                                                                readMoreText={"Read more"}
                                                                readLessText={"Read less"}
                                                                readMoreClassName="readMore"
                                                                readLessClassName="readLess"
                                                            >
                                                                {data?.commentByUsers ? data?.commentByUsers : "N/A"}
                                                            </ReactReadMoreReadLess>}
                                                        </td>
                                                        <td>
                                                            {data?.status?.id === constraintConfig.approverStatus.submitted ?
                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.submitted}</span> :
                                                                data?.status?.id === constraintConfig.approverStatus.pending ?
                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.pending}</span> :
                                                                    // data?.status?.title == "Clarification" ?
                                                                    //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                    data?.status?.id === constraintConfig.approverStatus.approved ?
                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                        data?.status?.id === constraintConfig.approverStatus.rejected ?
                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                            data?.status?.id === constraintConfig.approverStatus.accountsPayable ?
                                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.accountsPayable}</span> : ""
                                                            }
                                                        </td>
                                                        <td className='text-end pe-4'>
                                                            <DateTimeComp formattedDate={data?.approvedDate} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={11} className='text-center'>
                                                    <NoRecordFound />
                                                </td>
                                            </tr>
                                        }
                                    </>)}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={11} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className='modal-footer py-4'>
                    <button className='btn btn-light-primary' onClick={handleClose}>{labelKey.close}</button>
                    <button type='button' className='btn btn-light-info me-3' onClick={exportToExcel}>
                        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                        Export
                    </button>
                </div>

            </Modal>
        </>
    )
}

export default ExpenseLogModal