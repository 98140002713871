import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../features/authSlice';
import axios from 'axios';
import { API_END_POINTS } from '../../../services/apiEndpoints';
import { toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa6';
import labelKey from "../../localization/label.json";
import { Tooltip } from 'react-tooltip';

const DownloadReport = ({ data, label }: any) => {
    const { token, baseURL } = useAppSelector(selectAuth);

    const [isLoadingPdf, setIsLoadingPdf] = useState<Record<string, boolean>>({});
    const pdfRefetchAndDownload = async (data: any) => {
        setIsLoadingPdf((prevLoading) => ({ ...prevLoading, [data.expenseId]: true }));

        try {
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const getReportIronePDFResponse = await axios.get(
                `${baseURL}/api/ReportAndAnalytics${API_END_POINTS.getReport}?expenseId=${data.expenseId}`,
                {
                    headers,
                }
            );
            const getReportIronePDF = getReportIronePDFResponse.data;
            downloadPdf(getReportIronePDF.result, data); // Download PDF directly
            toast.success('Report downloaded successfully!');
        } catch (error) {
            toast.error('Report Not downloaded');
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingPdf((prevLoading) => ({ ...prevLoading, [data.expenseId]: false }));
        }
    };
    // Function to convert base64 to Blob
    const base64toBlob = (base64Data: string, contentType: string) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    };

    const downloadPdf = (base64Data: string, data: any) => {
        const blob = base64toBlob(base64Data, 'application/pdf');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Expense ${data?.trackingId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Release the blob URL
    };
    const handleDownloadPdf = (data: any) => {
        // Initiate PDF refetch
        pdfRefetchAndDownload(data);
    };
    return (
        <>
            <Tooltip id="report-download" />
            <div className='d-flex gap-2 align-items-center'
                onClick={() => {
                    handleDownloadPdf(data);
                }}>
                <div
                    data-tooltip-id="report-download" data-tooltip-content='Report Download'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                    {!isLoadingPdf[data.expenseId] ?
                        <FaDownload className='text-dark text-hover-primary' />
                        :
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">{labelKey.loading}...</span>
                        </div>
                    }
                </div>
                {label &&
                    <span>{labelKey.reportDownload}</span>
                }
            </div>

        </>
    )
}

export default DownloadReport