
import ReactApexChart from 'react-apexcharts'
import { FaInfoCircle } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'
import labelKey from "../../localization/label.json"
const ExpensePaymentMethodChart = ({ reportPaymentData }: any) => {
    const pieChartSeries = reportPaymentData?.result?.expenseByPaymentReponse?.map((status: any) => status?.amount) || [];
    const pieChartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
        },
        labels: reportPaymentData?.result?.expenseByPaymentReponse?.map((status: any) => status?.paymentName) || [],
        legend: {
            show: true,
            labels: {
                colors: 'var(--bs-heading-color)', // Apply CSS variable to legend labels
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        value: {
                            show: true,
                            color: 'var(--bs-heading-color)', // Apply CSS variable to donut value label
                            formatter: function (val) {
                                return `$${Number(val).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                            },
                        },
                        total: {
                            show: true,
                            label: labelKey.total,
                            color: 'var(--bs-heading-color)', // Apply CSS variable to total label
                            formatter: function (w) {
                                const total = w.globals.seriesTotals.reduce((a: any, b: any) => {
                                    return a + b;
                                }, 0);
                                return `${'$' + Number(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                            },
                        },
                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250,
                    },
                    legend: {
                        position: 'bottom',
                        labels: {
                            colors: 'var(--bs-heading-color)', // Apply CSS variable to responsive legend labels
                        },
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (value: any) {
                    return `$${value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`;
                },
            },
        },
    };


    // console.log("Pie Chart Series:", pieChartSeries);

    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className='mb-10'>
                <h2>{labelKey.expenseByPaymentMethod}
                    <FaInfoCircle className='text-gray cursor-pointer ms-2'
                        data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.' />
                </h2>
            </div>
            {reportPaymentData && reportPaymentData?.result?.expenseByPaymentReponse && reportPaymentData?.result?.expenseByPaymentReponse.length > 0 ? (
                <div className='approver-report mt-12 mb-10 d-flex d-md-inline justify-content-center'>
                    <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
                </div>
            ) : (
                <p className='text-center mt-5'>No data available for the chart.</p>
            )}
        </>
    )
}

export default ExpensePaymentMethodChart