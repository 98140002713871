import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import TextField from '../../../components/textFields/TextField';
import labelKey from '../../localization/label.json';
import tosterKey from '../../localization/toster.json'
import SearchSelect from '../../../components/textFields/SearchSelect';
import { useAddUpdateEmailSettingMutation, useGetEmailSettingBySenderTypeQuery } from '../../../services/SystemSettingsApi';
import { useFormik } from 'formik';
import { useGetEmailSenderTypeQuery } from '../../../services/GeneralApi';
import { API_END_POINTS } from '../../../services/apiEndpoints';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../features/authSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup'



const EmailSetting = () => {
    const { token, baseURL } = useAppSelector(selectAuth);
    const [addEmailSetting, { isLoading, isSuccess, isError, error }] = useAddUpdateEmailSettingMutation();
    const { data: getSenderType } = useGetEmailSenderTypeQuery('')
    const [getEmailSettingDetail, setGetEmailSettingDetail] = useState<any>(null); // Initialize with null
    const initialValues = {
        emailSettingId: 0,
        serverHost: '',
        port: 0,
        senderEmail: '',
        senderName: '',
        username: '',
        password: '',
        cc: '',
        bcc: '',
        emailSenderType: {
            id: 0,
            value: ''
        },
        defaultEmailSetting: true,
        emailEnabled: false
    }

    const validationSchema = Yup.object().shape({
        emailSenderType: Yup.object().shape({
            id: Yup.number().min(1, 'Sender Email is required').required('Sender Email is required'),
        }),
        senderEmail: Yup.string().required('Sender Email is required'),
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
        serverHost: Yup.string().when('emailSenderType.id', {
            is: 1, // or the specific id for which you need to apply this validation
            then: Yup.string().required('Host is required'),
        }),
        port: Yup.string().when('emailSenderType.id', {
            is: 1, // or the specific id for which you need to apply this validation
            then: Yup.string().required('Port is required'),
        }),
    })

    useEffect(() => {
        if (getEmailSettingDetail?.result) {
            formik.setValues({ ...getEmailSettingDetail.result })
        }
        else {
            formik.setValues(initialValues)
        }
    }, [getEmailSettingDetail]);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            addEmailSetting(values)
            // console.log('values', values);
        }
    })
    const emailSettingDetailRefetch = async (EmailSenderTypeId: any) => {
        try {
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const getExpenseLogResponse = await axios.get(
                `${baseURL}/api/SystemSetting${API_END_POINTS.getEmailSettingBySenderType}?EmailSenderTypeId=${EmailSenderTypeId}`,
                {
                    headers,
                }
            );
            const getEmailSettingDetail = getExpenseLogResponse.data;
            setGetEmailSettingDetail(getEmailSettingDetail);
        } catch (error) {
            console.error(`Error fetching data for : `, error);
        } finally {
        }
    };
    const { resetForm } = formik;

    const handleSelectChange = (selectedOption: any) => {
        resetForm(); // Reset the form first
        formik.setFieldValue('emailSenderType.id', selectedOption.value);
        formik.setFieldValue('emailSenderType.value', selectedOption.label);
        emailSettingDetailRefetch(selectedOption.value);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.emailConfigurationUpdateSuccessfully);
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);

    const sandgrid = formik.values.emailSenderType?.id === 2
    return (
        <div className="card" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <form
                            className='form w-100'
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="row">
                                <div className='col-md-12 mb-5'>
                                    <SearchSelect
                                        label={labelKey.defaultSenderEmail}
                                        required
                                        options={[
                                            { value: 0, label: 'Select Type' },
                                            ...(getSenderType?.result || []).map((option: any) => ({
                                                value: option?.id,
                                                label: option.value,
                                                key: option.id,
                                            }))]}
                                        placeholder="Select Type"
                                        value={
                                            formik.values?.emailSenderType?.id
                                                ? {
                                                    value: formik.values?.emailSenderType?.id,
                                                    label: formik.values?.emailSenderType?.value || '',
                                                }
                                                : null
                                        }
                                        onChange={handleSelectChange}
                                    />
                                    {formik.touched.emailSenderType?.id && formik.errors.emailSenderType?.id && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.emailSenderType?.id}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10 mb-5'>
                                    <TextField
                                        label={labelKey.senderEmail}
                                        required={true}
                                        type='text'
                                        placeholder='Enter Sender Email'
                                        {...formik.getFieldProps('senderEmail')}
                                    />
                                    {formik.touched.senderEmail && formik.errors.senderEmail && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.senderEmail}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-2 mb-5'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.enableEmail}</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input cursor-pointer mt-3"
                                            type="checkbox"
                                            role="switch"
                                            id={`flexSwitchCheckChecked`}
                                            {...formik.getFieldProps('emailEnabled')}
                                            checked={formik.values.emailEnabled}
                                        />
                                    </div>
                                </div>
                                {!sandgrid &&
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.host}
                                            required={true}
                                            type='text'
                                            placeholder='Enter Host'
                                            {...formik.getFieldProps('serverHost')}
                                        />
                                        {formik.touched.serverHost && formik.errors.serverHost && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.serverHost}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                                <div className='col-md-6 mb-5'>
                                    <TextField
                                        label={labelKey.userName}
                                        required={true}
                                        type='text'
                                        placeholder='Enter UserName'
                                        {...formik.getFieldProps('username')}
                                    />
                                    {formik.touched.username && formik.errors.username && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.username}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-6 mb-5'>
                                    <TextField
                                        label={sandgrid ? labelKey?.apiKey : labelKey?.password}
                                        required={true}
                                        type='text'
                                        placeholder='Enter Password'
                                        {...formik.getFieldProps('password')}
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.password}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!sandgrid &&
                                    <div className='col-md-6 mb-5'>
                                        <TextField
                                            label={labelKey.port}
                                            required={true}
                                            type='text'
                                            placeholder='Enter Port'
                                            {...formik.getFieldProps('port')}
                                        />
                                        {formik.touched.port && formik.errors.port && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.port}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                                <div className='col-md-6 mb-5'>
                                    <TextField
                                        label={labelKey.defaultCC}
                                        // required={true}
                                        type='text'
                                        placeholder='Enter CC'
                                        {...formik.getFieldProps('cc')}
                                    />
                                </div>
                                <div className='col-md-6 mb-5'>
                                    <TextField
                                        label={labelKey.defaultBCC}
                                        // required={true}
                                        type='text'
                                        placeholder='Enter BCC'
                                        {...formik.getFieldProps('bcc')}
                                    />
                                </div>

                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='submit'
                                    className='btn btn-primary mb-5 mt-5'
                                    disabled={isLoading}
                                >
                                    {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}

                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailSetting