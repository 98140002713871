import ReactApexChart from 'react-apexcharts';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import labelKey from "../../localization/label.json";

const ExpenseByCategoryChart = (expensecategoryData: any) => {

  const barChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
    },
    colors: ['#4B5675'],
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: expensecategoryData?.expensecategoryData?.result?.categoriesSubtottal.map((category: any) => category?.categoryName) || [],
      title: {
        text: 'Categories',  // X-axis label
        style: {
          color: 'var(--bs-heading-color)', // Apply CSS variable to x-axis title
        },
      },
      labels: {
        style: {
          colors: 'var(--bs-heading-color)', // Apply the CSS variable for x-axis labels
        }
      }
    },
    yaxis: {
      title: {
        text: 'Expense Items Amount',  // Y-axis label
        style: {
          color: 'var(--bs-heading-color)', // Apply CSS variable to y-axis title
        },
      },
      labels: {
        style: {
          colors: 'var(--bs-heading-color)', // Apply the CSS variable for y-axis labels
        },
        formatter: function (value: any) {
          return `$${value.toLocaleString('en-US')}`;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 8,
        dataLabels: {
          position: 'top', // Display labels on top of each bar
        },
      },
    },
    annotations: {
      xaxis: [
        {
          x: 0, // x-axis index where the line will be drawn
          borderColor: '#8c8c8c',
        },
      ],
    },
    tooltip: {
      y: {
        formatter: function (value: any) {
          return `$${value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
    },
  };
  

  const barChartSeries = [
    {
      name: 'Category Expenses Amount ',
      data: expensecategoryData?.expensecategoryData?.result?.categoriesSubtottal.map((category: any) => category?.categoryExpenseAmount) || [],
    },
  ];

  return (
    <>
      <Tooltip id="chart" place="top" />
      <div className='d-flex justify-content-between align-items-center'>
        <h3>{labelKey.categoryChart}
          <FaInfoCircle className='text-gray cursor-pointer ms-2'
            data-tooltip-id="chart" data-tooltip-content='The chart display data for the last 7 days.' />
        </h3>
      </div>
      <div className='d-flex d-md-inline justify-content-center mt-5'>
        <ReactApexChart options={barChartOptions} series={barChartSeries} type='bar' height={400} className="category-barchart h-450px" />
      </div>
    </>
  )
}

export default ExpenseByCategoryChart