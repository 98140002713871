/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { selectAuth } from '../../features/authSlice'
import { useAppSelector } from '../../hooks'
import { App } from '../App'
import { Logout } from '../modules/auth'
import { AuthPage } from '../modules/auth/AuthPage'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { PrivateRoutes } from './PrivateRoutes'


const AppRoutes: FC = () => {

  // const dispatch = useAppDispatch();
  // const user = JSON.parse(localStorage.getItem("user") || "{}")

  // useEffect(() => {
  //   dispatch(setUser(user));
  // }, [])


  const { email } = useAppSelector(selectAuth);
  const isAuthenticated = !!email; // Check if the user is authenticated

  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={3000}
      />
      {/* <div className='d-none'>
        <Toaster />
      </div> */}
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }

