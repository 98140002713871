import React from 'react'
import './TextField.css'
import { Tooltip } from 'react-tooltip';
import { FaLock } from 'react-icons/fa6';
interface props {
    label: string;
    leftLabel?: string;
    labelClass?: string;
    cols?: number;
    rows?: number;
    fieldClass?: string;
    placeholder?: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    required?: boolean;
    value?: string;
    readOnly?: boolean
    iconTooltip?: string;
    disabled?: boolean;


}
const TextAreaField = ({ label, leftLabel, labelClass, cols, rows, fieldClass, placeholder, onChange, required, value,disabled, readOnly, iconTooltip, ...fieldProps }: props) => {
    return (
        <>
            <div className={`${leftLabel ? 'd-md-flex w-100 gap-2' : ''}`}>
                <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass} ${required ? 'required' : ''}`}>
                    {label}{leftLabel}
                    {required && <span className="required-star text-danger ms-1"></span>}
                    {disabled && <FaLock className='fs-7 ms-1' />}

                    {iconTooltip && (
                        <>
                            <i className="fa fa-info-circle ms-2 cursor-pointer"
                                data-tooltip-id={`${label}-tooltip`}
                                data-tooltip-content={iconTooltip}></i>
                            <Tooltip id={`${label}-tooltip`} place="top" />
                        </>
                    )}
                </label>
                <textarea cols={cols} rows={rows}
                    className={`form-control form-control-solid default-input ${fieldClass}`}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    {...fieldProps}
                ></textarea>

            </div>
        </>
    )
}

export default TextAreaField