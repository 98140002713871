import { PageTitle } from '../../../_metronic/layout/core'
import ExpenseItemPage from './ExpenseItemPage'
import labelKey from '../../localization/label.json';
const ExpenseItemWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.expenseReports}</PageTitle>
            <ExpenseItemPage />
        </>
    )
}

export default ExpenseItemWrapper