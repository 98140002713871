import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-bootstrap-daterangepicker'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { useGetExpenseReportByDepartmentAndUserQuery, useGetExpenseReportByPaymentV2Query } from '../../../services/ReportAndAnalyticsApi'
import labelKey from "../../localization/label.json"
import ExpenseCategoryChart from './ExpenseCategoryChart'
import ExpenseDepartmentChart from './ExpenseDepartmentChart'
import ExpensePaymentMethodChart from './ExpensePaymentMethodChart'
import ExpenseReportByUser from './ExpenseReportByUser'
import ExpensesAmountChart from './ExpensesAmountChart'
import { timePeriodOptions } from './ReportFilterOptions'
import { myReportPaths, queryReportPaths, teamReportPaths } from './ReportPaths'

const ExpenseSummary = () => {
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [filterByTimePeriod, setFilterByTimePeriod] = useState('');

    const location = useLocation()



    const myReportPath = myReportPaths.includes(location.pathname);
    const queryFeportPath = queryReportPaths.includes(location.pathname);
    const teamFeportPath = teamReportPaths.includes(location.pathname);

    const { data: reportPaymentData, refetch: reportPaymentDataRefetch } = useGetExpenseReportByPaymentV2Query({
        reportFilter: myReportPath ? 0 : queryFeportPath ? 2 : 1,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        timePeriod: filterByTimePeriod

    })
    const { data: reportByDeptAndUsersData, refetch: reportByUsersDataRefetch } = useGetExpenseReportByDepartmentAndUserQuery({
        reportFilter: myReportPath ? 0 : queryFeportPath ? 2 : 1,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        timePeriod: filterByTimePeriod
    })


    useEffect(() => {
        reportPaymentDataRefetch();
        reportByUsersDataRefetch();
    }, []);

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        // setLocalFilterByStartDate(startDateFormatted);
        // setLocalFilterByEndDate(endDateFormatted);
        setFilterByStartDate(startDateFormatted);
        setFilterByEndDate(endDateFormatted);
        setFilterByTimePeriod('')
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
    };
    const handleTimePeriodChange = (selectedOption: any) => {
        setFilterByTimePeriod(selectedOption.value);
        setFilterByStartDate('')
        setFilterByEndDate('')
    };

    return (
        <>
            <div className='card mb-5 mb-xl-10' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                <div className='card-body px-9 py-5 collapse show' id='expenseSummary' >
                    <div className="row justify-content-end">
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <label className='form-label fs-6 fw-bold text-dark'>{labelKey.date}:</label>
                                    <div className='position-relative mb-2'>
                                        <label className='position-absolute text-primary'>Custom Date Range</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className='h-10px mw-100px opacity-0 cursor-pointer'
                                                value={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <Select
                                    // customLabelClick={() => setDateRangeHide(true)}
                                    options={[
                                        { value: 0, label: 'All' }, // Add the empty option
                                        ...(timePeriodOptions?.map((option: any) => ({
                                            value: option.id,
                                            label: option?.value,
                                        })))
                                    ]}
                                    placeholder={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : 'Select Date'}
                                    value={filterByTimePeriod && filterByTimePeriod !== ''
                                        ? {
                                            label: timePeriodOptions?.find((option: any) => option.id === filterByTimePeriod)?.value || '',
                                            value: filterByTimePeriod,
                                        }
                                        : null
                                    }
                                    onChange={handleTimePeriodChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ExpensesAmountChart data={reportPaymentData} />
                        <ExpenseCategoryChart data={reportPaymentData} />
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-6">
                            <div className='p-9' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                <ExpensePaymentMethodChart />
                            </div>
                        </div>
                    </div> */}

                    <div className="row">

                        <div className="col-lg-6 mb-10">
                            <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                <ExpensePaymentMethodChart reportPaymentData={reportPaymentData} />
                            </div>
                        </div>

                        {!myReportPath && <>
                            {/* {roleID === constraintConfig?.roleID?.role4 && */}
                            <>
                                <div className="col-lg-6 mb-10">
                                    <div className='p-9' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                        <ExpenseReportByUser reportByDeptAndUsersData={reportByDeptAndUsersData} />
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-10">
                                    <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                        <ExpenseDepartmentChart reportByDeptAndUsersData={reportByDeptAndUsersData} />
                                    </div>
                                </div>
                            </>
                            {/* } */}
                        </>}
                    </div>


                    {/* {roleID !== constraintConfig?.roleID?.role5 &&
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mb-5 mb-xl-10 mt-10">
                                <div className='p-3' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                    <ExpenseReportStatusChart />
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div>

        </>
    )
}

export default ExpenseSummary