import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../_metronic/helpers'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
import { useReadDraftInvoicesMutation } from '../../../services/BackgroundJobApi'
import { useUploadOCRInvoiceMutation } from '../../../services/InvoiceDataDraftApi'

import { IoIosCloudUpload } from 'react-icons/io'
import tosterKey from '../../localization//toster.json'
import labelKey from '../../localization/label.json'

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}

const AddInvoiceModal = ({ show, handleClose, data }: Props) => {
    const [uploadFile, { isLoading: uploadFileIsLoading, isSuccess: uploadFileIsSuccess, isError, error }] = useUploadOCRInvoiceMutation()
    const [readDraftInvoices, { isLoading: readLoading }] = useReadDraftInvoicesMutation()

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const [imagePreviewFirst, setImagePreviewFirst] = useState<string | null>(null)
    const [isPdf, setIsPdf] = useState<boolean>(false)

    
    const [selectedFile, setSelectedFile] = useState<File | null>(null) // Store the selected file

    const handleImageChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            setSelectedFile(file) // Store the file in state
            setIsPdf(file.type === 'application/pdf')


            const reader = new FileReader()
            reader.onload = () => {
                setImagePreviewFirst(reader.result as string) // Set the image preview
            }
            reader.readAsDataURL(file) // Read file as Data URL
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (selectedFile) {
            const formData = new FormData()
            formData.append('file', selectedFile)

            const response = await uploadFile(formData)

            const uploadedFileUrl = (response as { data?: any })?.data?.result
            if (uploadedFileUrl) {
                await readDraftInvoices({ fileId: uploadedFileUrl })
            }
        }
    }

    const [fileRemoved, setFileRemoved] = useState(false);
    const handleRemoveFile = () => {
        // Reset the uploaded file and related states
        setFileRemoved(true);

        // Reset the file input value
        const fileInput = fileInputRef.current;
        if (fileInput) {
            fileInput.value = ''; // Clear the input value
        }
    };
    useEffect(() => {
        // Check if the file was removed and update UI accordingly
        if (fileRemoved) {
            // Reset file-related states
            setImagePreviewFirst(null);
            // Reset file removed state
            setFileRemoved(false);
        }
    }, [fileRemoved]);

    useEffect(() => {
        if (uploadFileIsSuccess) {
            const timeoutId = setTimeout(() => {
                toast.success(tosterKey.receiptAddedSuccessfully)
                handleClose();
                setImagePreviewFirst(null);
                setSelectedFile(null); // Reset the file after successful upload
            }, 5000); // 2000 milliseconds (2 seconds) delay

            // Clear timeout if the component unmounts before the timeout completes
            return () => clearTimeout(timeoutId);
        }
    }, [uploadFileIsSuccess])

    useEffect(() => {
        if (isError && error) {
            let errorMessage = 'Something went wrong'

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message
            }
            toast.error(errorMessage)
        }
    }, [isError, error])
    const handleModalClose = () => {
        setImagePreviewFirst(null); // Clear image preview
        handleClose();
      };
    return (
        <Modal
            aria-hidden='true'
            dialogClassName='modal-dialog modal-md modal-dialog-centered'
            show={show}
            onHide={handleModalClose}
        >
            <form className='form w-100' onSubmit={handleSubmit}>

                <div className='modal-header'>
                    <h2 className='mb-0'>Add Receipt</h2>
                    <div className='d-flex gap-3'>
                        <span className='btn btn-light btn-sm' onClick={handleModalClose}>
                            {labelKey.cancel}
                        </span>
                        <button
                            type='submit'
                            className='btn btn-primary btn-sm'
                            disabled={readLoading || uploadFileIsLoading}
                        >
                            {!readLoading && !uploadFileIsLoading && (
                                <span className='indicator-label'>{labelKey.submit}</span>
                            )}
                            {(readLoading || uploadFileIsLoading) && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>

                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className='row'>
                        <div className='col-md-12 mb-5'>
                            {/* <div className='position-relative'>
                                <div className='border border-dashed border-secondary border-4 w-100 h-400px d-flex flex-column justify-content-center align-items-center'>
                                    <IoIosCloudUpload className='text-muted' style={{ fontSize: '40px' }} />
                                    <h3 className='text-muted text-center'>Drag & Drop File Here</h3>
                                </div>
                                <input
                                    type='file'
                                    id='file'
                                    name='file'
                                    accept='.png, .jpg, .jpeg'
                                    ref={fileInputRef}
                                    onChange={handleImageChangeFirst}
                                    className='form-control form-control-solid default-input h-400px position-absolute top-0 opacity-0'
                                />

                                {imagePreviewFirst && (
                                    <>
                                        <div
                                            className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4 top-0'
                                            style={{ right: 0, zIndex: 12 }}
                                            // onClick={() => deleteAttachmentHandler(index)}
                                            onClick={handleRemoveFile}
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                        </div>
                                        <img
                                            src={imagePreviewFirst}
                                            alt='Preview'
                                            className='h-400px position-absolute top-0 w-100 p-2'
                                        />
                                    </>

                                )}
                            </div> */}
                            {!imagePreviewFirst && (
                                <div className='position-relative'>
                                    <div className='border border-dashed border-secondary border-4 w-100 h-100px d-flex flex-column justify-content-center align-items-center'>
                                        <IoIosCloudUpload className='text-muted' style={{ fontSize: '40px' }} />
                                        <h3 className='text-muted text-center'>Drag & Drop Image Here</h3>
                                    </div>
                                    <input
                                        type='file'
                                        id='file'
                                        name='file'
                                        accept='.png, .jpg, .jpeg, .pdf'
                                        ref={fileInputRef}
                                        onChange={handleImageChangeFirst}
                                        className='form-control form-control-solid default-input h-100px position-absolute top-0 opacity-0'
                                    />
                                </div>
                            )}
                            {imagePreviewFirst && (
                                <div className='position-relative'>
                                    <div
                                        className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4 '
                                        style={{ left: 10, zIndex: 12 ,top:'13px'}}
                                        // onClick={() => deleteAttachmentHandler(index)}
                                        onClick={handleRemoveFile}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                    </div>
                                    {isPdf ? (
                                        <embed
                                            // src={imagePreviewFirst}
                                            src={`${imagePreviewFirst}#toolbar=0&navpanes=0&scrollbar=0`}
                                            type='application/pdf'
                                            className='w-100 p-2'
                                            style={{ height: '400px' }}
                                        />
                                    ) : (
                                        <img
                                            src={imagePreviewFirst}
                                            alt='Preview'
                                            className='w-100 p-2'
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className='modal-footer justify-content-center'>
                    <span className='btn btn-light' onClick={handleClose}>
                        {labelKey.cancel}
                    </span>
                    <button
                        type='submit'
                        className='btn btn-primary mb-5 mt-5'
                        disabled={readLoading || uploadFileIsLoading}
                    >
                        {!readLoading && !uploadFileIsLoading && (
                            <span className='indicator-label'>{labelKey.submit}</span>
                        )}
                        {(readLoading || uploadFileIsLoading) && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {labelKey.pleaseWait}...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div> */}
            </form>
        </Modal>
    )
}

export default AddInvoiceModal
