import React, { useEffect, useState } from 'react';
import { FaUpload } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useUploadOCRInvoiceWithItemsIdMutation } from '../../../services/BackgroundJobApi';
import labelKey from '../../localization/label.json';
import tosterKey from '../../localization/toster.json';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../features/authSlice';
import { baseUrl } from '../../../services/baseUrl';
import { API_END_POINTS } from '../../../services/apiEndpoints';


type Props = {
    data: any,
    refetch?: any,
    fetchExpenseItemData?: any,
    setExpenseItemData?: any
}

const UploadDirectReceipt = ({ data, refetch, fetchExpenseItemData, setExpenseItemData }: Props) => {
    const { token } = useAppSelector(selectAuth);
    const [uploadFileToAzureWithItemId, { data: azurefileData, isLoading: fileIsloading, isError, error, isSuccess }] = useUploadOCRInvoiceWithItemsIdMutation();
    const [imagePreviewFirst, setImagePreviewFirst] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    const handleImageChangeFirst = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                setImagePreviewFirst(reader.result as string);
                const formData = new FormData();
                formData.append('file', file);
                formData.append('expenseItemId', data?.expenseItemId);

                const fileName = file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name;
                // setFileName(fileName)
                try {
                    const response = await uploadFileToAzureWithItemId(formData);
                    refetch();

                } catch (error) {
                    console.error(error);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (fileName !== null && imagePreviewFirst !== null) {
            setFileName(null);
            setImagePreviewFirst(null);
        }
    }, []);
    // useEffect(() => {
    //     if (isSuccess) {
    //         toast.success(tosterKey.receiptUploadedSuccessfully);
    //         fetchExpenseItemData(data?.expenseId, token);
    //     }
    // }, [isSuccess]);

    useEffect(() => {
        const updateExpenseData = async () => {
            if (isSuccess) {
                toast.success(tosterKey.receiptAttachedSuccessfully);
                if (fetchExpenseItemData && setExpenseItemData && data?.expenseId) {
                    try {
                        const fetchedData = await fetchExpenseItemData(data.expenseId, token);
                        setExpenseItemData(fetchedData);
                    } catch (error) {
                        console.error('Error fetching expense item data:', error);
                    }
                }
            }
        };

        updateExpenseData();
    }, [isSuccess, fetchExpenseItemData, setExpenseItemData, data?.expenseId, token]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <form
            className='position-relative'>
            {!fileIsloading ?
                <>
                    <input type="file" className='w-25px h-25px position-absolute' style={{ opacity: 0, top: 0, left: 0 }}
                        onChange={handleImageChangeFirst}
                        id="file"
                        name="file"
                        accept=".png, .jpg, .jpeg, .pdf" />
                    <FaUpload className='text-dark text-hover-primary' />
                </>
                :
                <div className='d-flex align-items-center gap-4'>
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">{labelKey.loading}...</span>
                    </div>
                </div>
            }

        </form>
    )
}

export default UploadDirectReceipt