import ReactApexChart from 'react-apexcharts';
import labelKey from "../../localization/label.json";
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';

const ApproverSummaryChart = (approverSummaryData: any) => {
    // const { userAccountID } = useAppSelector(selectAuth)
    // const [filterByStartDate, setFilterByStartDate] = useState("01/01/0001");
    // const [filterByEndDate, setFilterByEndDate] = useState("01/01/0001");
    // const { data, refetch } = useExpenseApproverSummeryQuery({
    //     managerId: userAccountID,
    //     startDate: filterByStartDate,
    //     endDate: filterByEndDate,
    // })
    // const handleStartDate = (e: any) => {
    //     // setFilterByStartDate(e.target.value);
    //     const newValue = e.target.value;
    //     if (newValue === '') {
    //         setFilterByStartDate('0001-01-01');
    //     } else {
    //         setFilterByStartDate(newValue);
    //     }
    // };
    // const handleEndDate = (e: any) => {
    //     // setFilterByEndDate(e.target.value);
    //     const newValue = e.target.value;
    //     if (newValue === '') {
    //         setFilterByEndDate('0001-01-01');
    //     } else {
    //         setFilterByEndDate(newValue);
    //     }
    // };
    // useEffect(() => {
    //     refetch();
    //     if (filterByStartDate === "" || filterByEndDate === "") {
    //         refetch();
    //     }

    // }, [filterByStartDate, filterByEndDate]);


    const pieChartOptions: ApexCharts.ApexOptions = {
        chart: {
          type: 'donut',
        },
        labels: approverSummaryData?.approverSummaryData?.result?.map(
          (category: any) => category?.statusName
        ) || [],
        legend: {
          show: true,
          position: 'bottom', // Set the legend's position to bottom
          labels: {
            colors: 'var(--bs-heading-color)', // Apply CSS variable to legend labels
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  formatter: function (val) {
                    return (
                      '$' +
                      Number(val).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    );
                  },
                  color: 'var(--bs-heading-color)', // Apply CSS variable to value labels
                },
                total: {
                  show: true,
                  label: labelKey.total,
                  formatter: function (w: any) {
                    const total = w.globals.seriesTotals.reduce(
                      (a: any, b: any) => {
                        return a + b;
                      },
                      0
                    );
                    return (
                      '$' +
                      Number(total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    );
                  },
                  color: 'var(--bs-heading-color)', // Apply CSS variable to total label
                },
              },
            },
          },
        },
        colors: ['#3498db', '#07bc0c', '#e74c3c'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (value: any) {
              return `$${value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
            },
          },
        },
      };
      

    const pieChartSeries = approverSummaryData?.approverSummaryData?.result?.map((category: any) => category?.amount) || [];
    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className='d-flex justify-content-between align-items-center'>
                <h2>{labelKey.approverSummary}
                    <FaInfoCircle className='text-gray cursor-pointer ms-2'
                        data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.' />
                </h2>
                {/* <div>
                    <button
                        type='button'
                        className='btn btn-sm btn-light-primary me-3'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        {labelKey.filter}
                    </button>
                    <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                        <div className='px-7 py-5'>
                            <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                        </div>

                        <div className='separator border-gray-200'></div>

                        <div className='px-7 py-5' data-kt-user-table-filter='form'>
                            <div>
                                <TextField
                                    label={`${labelKey.startDate}:`}
                                    type="date"
                                    max="9999-12-31"
                                    value={filterByStartDate}
                                    onChange={handleStartDate}
                                    fieldClass='mb-5'
                                />
                                <TextField
                                    label={`${labelKey.endDate}:`}
                                    type="date"
                                    max="9999-12-31"
                                    value={filterByEndDate}
                                    onChange={handleEndDate}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='my-20 py-10'>
                {approverSummaryData?.approverSummaryData &&
                    approverSummaryData?.approverSummaryData?.result &&
                    approverSummaryData?.approverSummaryData?.result.length > 0 ? (
                <div className='approver-status  d-flex d-md-inline justify-content-center'>
                    <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
                </div>
                ) : (
                <p className='text-center mt-5'>No data available for the chart.</p>
                )}
            </div>
        </>
    )
}

export default ApproverSummaryChart