import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import labelKey from '../../app/localization/label.json'
import { Spinner } from 'react-bootstrap';
import { FaCross } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';

interface filterProps {
    children: any;
    onclickApplyFilters: any;
    onclickResetFilters: any;
    isApplyingFiltersLoading: any
}

const FilterMenu = ({ children, onclickApplyFilters, onclickResetFilters, isApplyingFiltersLoading }: filterProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const dateRangePickerRef = useRef<HTMLDivElement | null>(null);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    // const handleClickOutside = (event: any) => {
    //     if (menuRef.current && !menuRef.current.contains(event.target)) {
    //         closeMenu();
    //     }
    //     // if (dateRangePickerRef.current && dateRangePickerRef.current.contains(event.target)
    //     // ) {
    //     //     closeMenu();
    //     // }
    // };


    // useEffect(() => {
    //     if (isMenuOpen) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [isMenuOpen]);

    useEffect(() => {
        if (!isApplyingFiltersLoading && isMenuOpen) {
            closeMenu();
        }
    }, [isApplyingFiltersLoading]);
    const handleResetFilters = () => {
        onclickResetFilters();
        closeMenu();
    };

    return (
        <>
            <div className='position-relative'>
                <button
                    type='button'
                    className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                    data-kt-menu-placement='bottom-end'
                    onClick={toggleMenu}
                >
                    <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                    {labelKey.filter}
                </button>
                <div
                    ref={menuRef}
                    className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px' ${isMenuOpen ? 'show position-absolute mt-3' : ''}`}
                    style={{ right: "5px" }}>
                    <div className='px-7 py-5'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                            <IoMdClose className='fs-2 cursor-pointer' onClick={() => setIsMenuOpen(false)} />
                        </div>

                    </div>
                    <div className='separator border-gray-200'></div>
                    <div className='px-7 py-5'>
                        <div ref={dateRangePickerRef} className='mb-5'>
                            {children}
                        </div>
                    </div>
                    <div className='separator border-gray-200'></div>
                    <div className='px-7 py-5'>
                        <button className='btn btn-primary pull-right' onClick={onclickApplyFilters}>
                            {isApplyingFiltersLoading ? <>
                                <span>loading...</span>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            </> : "Apply Filters"}
                        </button>
                        <button className='btn btn-danger pull-right me-3' onClick={handleResetFilters}>Reset Filters</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterMenu