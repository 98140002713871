/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useEffect, useState } from 'react';
import { FaEye } from "react-icons/fa6";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CurrencyComp from "../../../../../components/currencyComponent/CurrencyComp";
import DateComp from "../../../../../components/dateComponent/DateComp";
import { constraintConfig } from '../../../../../constraintConfig';
import { selectAuth } from "../../../../../features/authSlice";
import { useAppSelector } from "../../../../../hooks";
import { useGetMileageByDateQuery } from "../../../../../services/ExpenseApi";
import { useAllCurrencyLookupQuery, useGetAllExpenseTypeQuery, useGetPaymentMethodLookupQuery } from "../../../../../services/GeneralApi";
import labelKey from "../../../../localization/label.json";
type Props = {
  formik: any
}
interface LightboxData {
  imageURL: string;
}
const Step5 = ({ formik }: Props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
  const { roleID } = useAppSelector(selectAuth);
  const { data: getAllExpenseType } = useGetAllExpenseTypeQuery('')
  // const { data: vendor } = useGetVendorLookUpQuery('')
  const expenseItems = formik.values.expenseItem;
  // const { data: expenseCategory } = useExpenseCategoryLookupQuery('')
  const [rowIds, setRowIds] = useState<number[]>([]);

  useEffect(() => {
    const ids = expenseItems.map((item: any, index: any) => index);
    setRowIds(ids);
  }, [expenseItems]);
  const currentDate = new Date();
  const { data: getMileageByDate } = useGetMileageByDateQuery({
    date: currentDate.toISOString().split('T')[0]
  })

  const { data: getPaymentMethod } = useGetPaymentMethodLookupQuery('')
  const { data: currency } = useAllCurrencyLookupQuery('')
  const [totalAmount, setTotalAmount] = useState(0); // Add state for total amount

  useEffect(() => {
    let total = 0;
    expenseItems.forEach((item: any) => {
      const kmRate = getMileageByDate?.result?.ratePerKM * item?.milage;
      const mileRate = getMileageByDate?.result?.ratePerMile * item?.milage;
      const itemAmount = parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ? kmRate || mileRate : parseFloat(item?.amount);
      total += itemAmount;
    });
    setTotalAmount(total); // Update total amount state
  }, [expenseItems, getMileageByDate]);

  // const totalAmount = expenseItems.reduce(
  //   (total: number, item: any) => {
  //     console.log('total', total);

  //     const kmRate = getMileageByDate?.result?.ratePerKM * item?.milage;
  //     const mileRate = getMileageByDate?.result?.ratePerMile * item?.milage;
  //     const calculatedAmount = kmRate ? kmRate : mileRate;
  //     return total + (parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ? calculatedAmount : item?.amount);
  //   },
  //   0
  // );
  const isPdfFile = (filePath: string | undefined) => {
    if (!filePath) return false;
    const fileExtension = filePath.toLowerCase().split('.').pop();
    return fileExtension === 'pdf';
  };
  const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
    if (attachmentFile) {
      if (isPdfFile(attachmentFile)) {
        // For PDF files, open in the browser
        window.open(attachmentFile, '_blank');
      } else {
        // For image files, open in the Lightbox
        setLightboxData({ imageURL: attachmentFile });
        setLightboxOpen(true);
      }
    }
  };

  return (
    <>
      <div data-kt-stepper-element='content'>
        <div className='w-100 text-center'>
          {/* begin::Heading */}
          <h1 className='fw-bold text-gray-800 mb-3'>{labelKey.review}!</h1>
          {/* end::Heading */}
          <div className="row m-0">
            <div className="col-md-8">
              <div className="d-flex ">
                <label className="text-start d-flex fs-5 fw-bold">Expense Type: </label>
                <select
                  className='form-control bg-transparent text-dark fs-inherit border-0 p-0 w-150px ms-2'
                  {...formik.getFieldProps(`expenseType.id`)}
                  disabled
                >
                  {Array.isArray(getAllExpenseType?.result) && getAllExpenseType?.result?.map((option: any, index: any) => (
                    <option key={option.id} value={option.id}
                    >{option?.value}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex mt-3">
                <label className="text-start d-flex fs-5 fw-bold">{labelKey.reportName}: </label>
                <p className="ms-2 text-dark fs-5 fw-semibold mb-0">{formik?.values?.title}</p>
              </div>
              <label className="text-start d-flex fs-5 fw-bold mt-3">{labelKey.totalAmount}: <span className="fw-semibold ms-3"> <CurrencyComp amount={totalAmount.toString()} /> </span> </label>
            </div>
            <div className="col-md-4">
              <label className="text-start d-flex fs-5 fw-bold">{labelKey.date}:  <span className="ms-3"><DateComp formattedDate={formik?.values?.reportDate} /></span></label> <br />
              <label className="text-start d-flex fs-5 fw-bold">{labelKey.status}: <span className={`badge badge-light-${formik?.values?.expenseStatus?.id === constraintConfig.expenseStatus.rejected ? "danger" : "primary"} fs-7 fw-semibold ms-2`}> {formik?.values?.expenseStatus?.title}</span></label>
            </div>
            <div className="col-md-12 mt-4">
              <label className="text-start d-flex fs-5 fw-bold">{labelKey.expenseDescription}:</label>
              <p className="text-start d-flex mh-100px overflow-auto">{formik?.values?.expenseDetail}</p>
            </div>

            <div className="col-md-12 mt-4">
              <div className='table-responsive' style={{ height: "29vh" }}>
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 text-nowrap">
                  <thead>
                    <tr>
                      <th className="text-start rounded-start ps-4 w-100px">{labelKey.category}</th>
                      <th className="text-start">{labelKey.invoiceDate}</th>
                      <th className="text-start">{labelKey.currency}</th>
                      <th className="text-start">{labelKey.amount}</th>
                      <th className="text-start">
                        {/* {expenseItems.some((item: any) => parseInt(item?.expenseCategory?.id) === 14) && (
                          <> */}
                        {labelKey.distance}
                        {/* </>
                        )} */}
                      </th>
                      <th className="text-start">{labelKey.vendor}</th>
                      <th className="text-start">{labelKey.paymentMethod}</th>
                      <th className="text-start">{labelKey.chargeLocation}</th>
                      <th className="text-start w-100px">{labelKey.receipt}</th>
                      <th className="text-start rounded-end pe-4">{labelKey.description}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseItems.map((item: any, index: any) => {
                      const kmRate = getMileageByDate?.result?.ratePerKM * item?.milage;
                      const mileRate = getMileageByDate?.result?.ratePerMile * item?.milage;
                      // const itemAmount = parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ? kmRate || mileRate : item?.amount;
                      return (
                        <>
                          <tr key={index}>
                            <td className="ps-4 text-start">
                              {item?.expenseCategory?.title}
                            </td>
                            <td><p className="mb-0 w-75px">{moment(item?.itemDate).format("DD MMM YYYY")}</p></td>
                            <td className="text-start">
                              <select
                                disabled
                                className='border-0 p-0 remove-select-arrow text-start text-dark bg-transparent'
                                value={item?.currency?.id || ''}
                              >
                                {currency?.result.map((option: any, index: any) => (
                                  <option key={index} value={option.id}>{option?.value}</option>
                                ))}
                              </select>
                            </td>
                            <td className="text-start">
                              {parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                                <CurrencyComp amount={kmRate ? kmRate.toString() : mileRate.toString()} /> :
                                <CurrencyComp amount={item?.amount} />
                              }
                            </td>
                            <td className="text-start">
                              {parseInt(item?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                                <>
                                  {item?.milage ? item?.milage : '_'}
                                </>
                                : null
                              }
                            </td>

                            <td className="text-start">
                              {parseInt(item?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage ?
                                <>
                                  {item?.vendor?.name}
                                </>
                                : null
                              }
                            </td>
                            <td className="text-start">
                              <select
                                disabled
                                className='border-0 p-0 remove-select-arrow text-start text-dark bg-transparent'
                                value={item?.paymentMethod?.id || ''}
                              >
                                {getPaymentMethod?.result.map((option: any, index: any) => (
                                  <option key={index} value={option.id}
                                  >{option?.value}</option>
                                ))}
                              </select>
                            </td>
                            <td className="text-start">{item?.expItemLocation?.title ? item?.expItemLocation?.title : <span>N/A</span>} </td>
                            <td className="text-start w-100px">
                              {item?.file?.name && item?.file?.name?.length > 20
                                ? item?.file?.name.substring(0, 20) + '...'
                                : item?.file?.name}

                              {!item?.attachmentFile || item?.attachmentFile === null || item?.attachmentFile === "null" || item?.attachmentFile === "undefined" || item?.attachmentFile === ""
                                ?
                                null
                                :
                                <FaEye onClick={() => openPdfInBrowserOrLightbox(item?.attachmentFile)} className="ms-6" />
                              }
                              {lightboxOpen && (
                                <Lightbox
                                  mainSrc={lightboxData.imageURL}
                                  onCloseRequest={() => setLightboxOpen(false)}
                                  imageCaption="Attachment"
                                  enableZoom={true}
                                  imagePadding={50}
                                />
                              )}
                            </td>
                            <td className="text-start text-wrap">
                              <div className="overflow-auto mh-100px">
                                <p className="mb-0 w-300px">
                                  {item?.itemDetail ? item?.itemDetail : <span className="ms-10">N/A</span>}
                                </p>
                              </div>
                            </td>
                          </tr>
                          {/* Render split line items as additional rows */}
                          {item?.isSplitLineItem === true && <>
                            {item.splitLineItems && item.splitLineItems.map((splitItem: any, splitIndex: any) => (
                              <>

                                {splitIndex === 0 && splitItem.amount > 0 && (
                                  <tr className="bg-secondary p-2">
                                    <td className="ps-10 text-start fw-bold">{labelKey.splitLocations} ( {item.splitLineItems.length} )</td>
                                    <td className=" text-start fw-bold" colSpan={11}>{labelKey.splitAmount} </td>
                                  </tr>
                                )}
                                {splitItem.amount > 0 &&
                                  <tr key={`${index}-${splitIndex}`} className="split-item-row bg-secondary">
                                    <td className="ps-10 text-start">
                                      {splitItem.expItemLocation?.title ? splitItem.expItemLocation.title : <span>N/A</span>}
                                    </td>
                                    <td className="text-start" colSpan={11}>
                                      <CurrencyComp amount={splitItem.amount} />
                                    </td>

                                  </tr>
                                }
                              </>
                            ))}
                          </>}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Step5 };

