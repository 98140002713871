import { useCallback, useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import Pagination from '../../../components/pagination/Pagination';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useManagerTeamBudgetQuery } from '../../../services/BudgetApi';
import labelKey from "../../localization/label.json";
import AddBudgetModal from './AddBudgetModal';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import { Tooltip } from 'react-tooltip';
import { debounce } from 'lodash';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import useColumnVisibility from '../../customHooks/useColumnVisibility';

const BudgetPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'budget' });
    const { userAccountID } = useAppSelector(selectAuth);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [immediateSearchStr, setImmediateSearchStr] = useState('');

    const { data, isLoading, refetch } = useManagerTeamBudgetQuery({
        managerId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        searchstr: searchStr,
    })
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        refetch();
        if (searchStr === "") {
            refetch();
        }

    }, [searchStr]);
    const [showAddBudgetModal, setShowAddBudgetModal] = useState(false)
    const [editBudgetData, setEditBudgetData] = useState({})


    const addBudgetModalHandler = () => {
        setShowAddBudgetModal((prev) => !prev);
    };

    const [prevModalState, setPrevModalState] = useState({
        showAddBudgetModal: false,
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            showAddBudgetModal: showAddBudgetModal,
        });
    }, [showAddBudgetModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.showAddBudgetModal && !showAddBudgetModal)
        ) {
            refetch();
        }
    }, [
        showAddBudgetModal,
        prevModalState.showAddBudgetModal,
        refetch,
    ]);

    return (
        <>
            <AddBudgetModal show={showAddBudgetModal}
                handleClose={() => setShowAddBudgetModal(false)} budgetData={editBudgetData} />
            <PageTitle breadcrumbs={[]}>{labelKey.userBudgetAllocation}</PageTitle>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div className='card-title'>
                        <SearchBarComponent
                            placeholder='Budget'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setEditBudgetData(true);
                                    addBudgetModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addBudget}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('user') && (
                                        <TableHeading
                                            label={labelKey.user}
                                            columnId='user'
                                        />
                                    )}
                                    {hiddenColumns.includes('allocatedBudget') && (
                                        <TableHeading
                                            label={labelKey.allocatedBudget}
                                            columnId='allocatedBudget'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('startDate') && (
                                        <TableHeading
                                            label={labelKey.startDate}
                                            columnId='startDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('endDate') && (
                                        <TableHeading
                                            label={labelKey.endDate}
                                            columnId='endDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('remainingBudget') && (
                                        <TableHeading
                                            label={labelKey.remainingBudget}
                                            columnId='remainingBudget'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />

                                            <TextField
                                                rightLabel={labelKey.user}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("user")}
                                                checked={hiddenColumns.includes('user')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.allocatedBudget}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("allocatedBudget")}
                                                checked={hiddenColumns.includes('allocatedBudget')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.startDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("startDate")}
                                                checked={hiddenColumns.includes('startDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.endDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("endDate")}
                                                checked={hiddenColumns.includes('endDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.remainingBudget}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("remainingBudget")}
                                                checked={hiddenColumns.includes('remainingBudget')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ?
                                            (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('sr') && (
                                                                <td className='ps-4'> {(page - 1) * 10 + index + 1}</td>
                                                            )}
                                                            {hiddenColumns.includes('user') && (
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                            {/* {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> : */}
                                                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                            {/* } */}
                                                                        </div>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.userAccount?.firstName} {data?.userAccount?.middleName} {data?.userAccount?.lastName}</span>
                                                                            <span className='text-lowercase'>{data?.userAccount?.email}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('allocatedBudget') && (
                                                                <td><CurrencyComp amount={data?.allocateBudget} /></td>
                                                            )}
                                                            {hiddenColumns.includes('startDate') && (
                                                                <td><DateComp formattedDate={data.startDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('endDate') && (
                                                                <td><DateComp formattedDate={data.endDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('remainingBudget') && (
                                                                <td><CurrencyComp amount={data?.remainingBudget} /></td>
                                                            )}
                                                            {hiddenColumns.includes('actions') && (
                                                                <td>
                                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                        <Tooltip id="update-budget" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="update-budget" data-tooltip-content={labelKey.updateBudget}
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                setEditBudgetData(data);
                                                                                addBudgetModalHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </>)
                                            :
                                            (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={7} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                    />
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default BudgetPage
