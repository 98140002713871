import { useEffect, useRef, useState } from 'react';
import { DownloadTableExcel } from "react-export-table-to-excel";
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { KTSVG } from '../../../../_metronic/helpers';
import { PageTitle } from '../../../../_metronic/layout/core';
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../../components/dateComponent/DateComp';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import { constraintConfig } from '../../../../constraintConfig';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import { useGetExpenseForApQuery } from '../../../../services/ExpenseApi';
import { API_END_POINTS } from '../../../../services/apiEndpoints';
import labelKey from '../../../localization/label.json';
import CategoriesWithIcons from '../CategoriesWithIcons';
import ApApprovedModal from './ApApprovedModal';
import AssigntomeModal from './AssigntomeModal';
import ReactReadMoreReadLess from "react-read-more-read-less"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import UploadDirectReceipt from '../UploadDirectReceipt';
import DownloadReport from '../../reportAnalytics/DownloadReport';
import SyncWithBCModal from './SynxExpenseWithBCModal';
import DateTimeComp from '../../../../components/dateComponent/DateTimeComp';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
interface LightboxData {
    imageURL: string;
}

const ExpenseForAp = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'accountPayableList' });

    const { userAccountID, token, baseURL } = useAppSelector(selectAuth)
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("reportDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterByDate, setFilterByDate] = useState("01/01/0001");
    const [filterByStatus, setFilterByStatus] = useState("Manager Approved");
    const [sortedColumn, setSortedColumn] = useState("reportDate");
    const [isChecked, setIsChecked] = useState(false)
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const tableRef = useRef(null);


    const { data, isLoading, refetch, } = useGetExpenseForApQuery({
        accountPayableId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        filterByCategory: '',
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        filterByStatus: filterByStatus,
    })

    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };


    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        setSearchStr(e.target.value);
    }

    // const handleDateChange = (e: any) => {
    //     const newValue = e.target.value;
    //     if (newValue === '') {
    //         setFilterByDate('0001-01-01');
    //     } else {
    //         setFilterByDate(newValue);
    //     }
    // };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setFilterByStartDate(startDateFormatted);
        setFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
    };
    useEffect(() => {
        if (filterByStatus === "" ||
            filterByDate === "" ||
            searchStr === "" ||
            sortOrder === "asc" ||
            sortBy === ""
        ) {
            refetch();
        }
    }, [filterByStatus, filterByDate, sortOrder, sortBy, searchStr]);

    const fetchExpenseItemData = async (expenseId: any, token: any) => {
        const headers = {
            Authorization: `Bearer ${token}`, // Replace 'Bearer' with your token type if needed
            // Add any other headers you need here
        };
        const response = await fetch(`${baseURL}/api/Expense${API_END_POINTS.expenseItemById}?ExpenseId=${expenseId}`, {
            headers,
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    };
    const [showInnerTable, setShowInnerTable] = useState(null);
    const [expenseItemData, setExpenseItemData] = useState<any>(null); // Initialize with null
    const [expenseItemIsLoading, setExpenseItemIsLoading] = useState(false);

    const handleRowClick = async (rowId: any, expenseId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
            if (expenseId) {
                setExpenseItemIsLoading(true);
                try {
                    const data = await fetchExpenseItemData(expenseId, token);
                    setExpenseItemData(data);
                } catch (error) {
                } finally {
                    setExpenseItemIsLoading(false); // Set loading state to false after fetching data
                }
            }
        }
    };
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    const [showExpenseConfirmationModal, setShowExpenseConfirmationModal] = useState(false)
    const [expenseConfirmationData, setExpenseConfirmationData] = useState({});

    const expenseConfirmationModalHandler = () => {
        setShowExpenseConfirmationModal((prev) => !prev);
    };

    const [showAssignToMeModal, setShowAssignToMeModal] = useState(false)
    const [asignToMeData, setAsignToMeData] = useState({});

    const assignToMeHandler = () => {
        setShowAssignToMeModal((prev) => !prev);
    };
    const [showSyncWithBCModal, setShowSyncWithBCModal] = useState(false)

    const syncWithBCHandler = () => {
        setShowSyncWithBCModal((prev) => !prev);
    };

    const [activeTab, setActiveTab] = useState('Manager Approved');

    const handleTabChange = (status: string) => {
        setActiveTab(status);
        setFilterByStatus(status);
        setShowInnerTable(null);
    };
    const handleRefresh = () => {
        refetch()
    };
    const [prevModalState, setPrevModalState] = useState({
        showExpenseConfirmationModal: false,
        showAssignToMeModal: false,
        showSyncWithBCModal: false
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            showExpenseConfirmationModal: showExpenseConfirmationModal,
            showAssignToMeModal: showAssignToMeModal,
            showSyncWithBCModal: showSyncWithBCModal
        });
    }, [showExpenseConfirmationModal,
        showAssignToMeModal,
        showSyncWithBCModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.showExpenseConfirmationModal && !showExpenseConfirmationModal) ||
            (prevModalState.showAssignToMeModal && !showAssignToMeModal) ||
            (prevModalState.showSyncWithBCModal && !showSyncWithBCModal)
        ) {
            refetch();
        }
    }, [
        showExpenseConfirmationModal,
        showAssignToMeModal,
        showSyncWithBCModal,
        prevModalState.showExpenseConfirmationModal,
        prevModalState.showAssignToMeModal,
        prevModalState.showSyncWithBCModal,
        refetch,
    ]);
    const resetFilters = () => {
        setFilterByStartDate('')
        setFilterByEndDate('')
    }
    return (
        <>
            <ApApprovedModal show={showExpenseConfirmationModal} handleClose={() => setShowExpenseConfirmationModal(false)} data={expenseConfirmationData} />
            <AssigntomeModal show={showAssignToMeModal} handleClose={() => setShowAssignToMeModal(false)} data={asignToMeData} isChecked={isChecked} />
            <SyncWithBCModal show={showSyncWithBCModal} handleClose={() => setShowSyncWithBCModal(false)} data={expenseConfirmationData} />


            <PageTitle breadcrumbs={[]}>Account Payable Approval</PageTitle>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div>
                        <div className='btn-group'>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Manager Approved' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Manager Approved')}
                            >
                                {labelKey.allManagerApproved}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Pending for Approval' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Pending for Approval')}
                            >
                                {labelKey.readyForApApproval}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'AP Approved' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('AP Approved')}
                            >
                                {labelKey.aPApproved}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === '' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('')}
                            >
                                {labelKey.all}
                            </button>
                        </div>
                        <Tooltip id="refreshData" place="top" />
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <i className='fa fa-repeat'></i>
                        </div>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        <SearchBarComponent
                            placeholder='Expense'
                            value={searchStr}
                            onChange={handleSearchChange}
                        />
                        <div>
                            <button
                                type='button'
                                className='btn btn-sm btn-light-primary me-3'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                {labelKey.filter}
                            </button>
                            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px mh-450px overflow-auto' data-kt-menu='true'>
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                                </div>

                                <div className='separator border-gray-200'></div>

                                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                    <div className='mb-5'>
                                        <label className='form-label fw-bold text-dark fs-6'>Date:</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className="form-control form-control-lg form-control-solid default-input"
                                                value={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className='separator border-gray-200'></div>
                                <div className='px-7 py-5'>
                                    <button className='btn btn-danger pull-right' onClick={resetFilters}>Reset Filters</button>
                                </div>
                            </div>
                        </div>
                        {/* <DownloadTableExcel
                            filename="users table"
                            sheet="users"
                            currentTableRef={tableRef.current}
                        >
                            <button type='button' className='btn btn-sm btn-light-primary me-3' >
                                <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                Export
                            </button>
                        </DownloadTableExcel> */}
                    </div>

                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' ref={tableRef}>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('reportID') && (
                                        <TableHeading
                                            label={labelKey.reportId}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='reportID'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('userAccount') && (
                                        <TableHeading
                                            label={labelKey.user}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='userAccount'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseType') && (
                                        <TableHeading
                                            label={labelKey.expenseType}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseType'
                                        />
                                    )}
                                    {hiddenColumns.includes('title') && (
                                        <TableHeading
                                            label={labelKey.title}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='title'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseDetail') && (
                                        <TableHeading
                                            label={labelKey.description}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseDetail'
                                            className="w-300px"
                                        />
                                    )}
                                    {hiddenColumns.includes('amount') && (
                                        <TableHeading
                                            label={labelKey.totalAmount}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='amount'
                                        />
                                    )}
                                    {hiddenColumns.includes('reportDate') && (
                                        <TableHeading
                                            label={labelKey.reportDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='reportDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseStatus') && (
                                        <TableHeading
                                            label={labelKey.expenseStatus}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseStatus'
                                        />
                                    )}
                                    {/* {data?.result?.data.some((data: any) => data?.expenseStatus?.id !== constraintConfig.expenseStatus.apApproved) && (
                                        <> */}
                                    {hiddenColumns.includes('assignToMe') && (
                                        <TableHeading
                                            label={labelKey.assignToMe}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='assignToMe'
                                        />
                                    )}
                                    {hiddenColumns.includes('assignToAP') && (
                                        <TableHeading
                                            label={labelKey.assignToAP}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='assignToAP'
                                        />
                                    )}
                                    {/* </>)} */}
                                    {hiddenColumns.includes('clarificationNote') && (
                                        <TableHeading
                                            label={labelKey.clarificationNote}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='clarificationNote'
                                            className="w-300px"
                                        />
                                    )}
                                    {hiddenColumns.includes('syncToBCDate') && (
                                        <TableHeading
                                            label={labelKey.syncToBCDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='syncToBCDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('syncToBCStatus') && (
                                        <TableHeading
                                            label={labelKey.syncToBCStatus}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='syncToBCStatus'
                                        />
                                    )}
                                    {hiddenColumns.includes('bCExpenseCreated') && (
                                        <TableHeading
                                            label={labelKey.bCExpenseCreated}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='bCExpenseCreated'
                                        />
                                    )}
                                    {hiddenColumns.includes('bcExpenseCreatedDate') && (
                                        <TableHeading
                                            label={labelKey.bcExpenseCreatedDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='bcExpenseCreatedDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('bCExpenseUpdated') && (
                                        <TableHeading
                                            label={labelKey.bCExpenseUpdated}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='bCExpenseUpdated'
                                        />
                                    )}
                                    {hiddenColumns.includes('bcExpenseUpdatedDate') && (
                                        <TableHeading
                                            label={labelKey.bcExpenseUpdatedDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='bcExpenseUpdatedDate'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {/* {data?.result?.data.some((data: any) => data?.expenseStatus?.id !== constraintConfig.expenseStatus.apApproved) && (
                                            <> */}
                                        {hiddenColumns.includes('action') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        {/* </>
                                        )} */}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.reportId}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("reportID")}
                                                checked={hiddenColumns.includes('reportID')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.user}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("userAccount")}
                                                checked={hiddenColumns.includes('userAccount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.expenseType}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseType")}
                                                checked={hiddenColumns.includes('expenseType')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.title}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("title")}
                                                checked={hiddenColumns.includes('title')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.description}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseDetail")}
                                                checked={hiddenColumns.includes('expenseDetail')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.amount}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("amount")}
                                                checked={hiddenColumns.includes('amount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.reportDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("reportDate")}
                                                checked={hiddenColumns.includes('reportDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.expenseStatus}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseStatus")}
                                                checked={hiddenColumns.includes('expenseStatus')}
                                                fieldClass='mb-4'
                                            />
                                            {data?.result?.data.some((data: any) => data?.expenseStatus?.id !== constraintConfig.expenseStatus.apApproved) && (
                                                <>
                                                    <TextField
                                                        rightLabel={labelKey.assignToMe}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("assignToMe")}
                                                        checked={hiddenColumns.includes('assignToMe')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.assignToAP}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("assignToAP")}
                                                        checked={hiddenColumns.includes('assignToAP')}
                                                        fieldClass='mb-4'
                                                    />
                                                </>)}
                                            <TextField
                                                rightLabel={labelKey.clarificationNote}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("clarificationNote")}
                                                checked={hiddenColumns.includes('clarificationNote')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.syncToBCDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("syncToBCDate")}
                                                checked={hiddenColumns.includes('syncToBCDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.syncToBCStatus}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("syncToBCStatus")}
                                                checked={hiddenColumns.includes('syncToBCStatus')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.bCExpenseCreated}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("bCExpenseCreated")}
                                                checked={hiddenColumns.includes('bCExpenseCreated')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.bcExpenseCreatedDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("bcExpenseCreatedDate")}
                                                checked={hiddenColumns.includes('bcExpenseCreatedDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.bCExpenseUpdated}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("bCExpenseUpdated")}
                                                checked={hiddenColumns.includes('bCExpenseUpdated')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.bcExpenseUpdatedDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("bcExpenseUpdatedDate")}
                                                checked={hiddenColumns.includes('bcExpenseUpdatedDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("action")}
                                                checked={hiddenColumns.includes('action')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>



                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ? (
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <>
                                                        <Tooltip id="my-tooltip" place="bottom" />
                                                        <tr
                                                            key={index}
                                                            data-tooltip-id="my-tooltip" data-tooltip-content="Click here to Expand"
                                                            className={`cursor-pointer ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                            onClick={() => {
                                                                handleRowClick(index, data?.expenseId);
                                                            }}>
                                                            {hiddenColumns.includes('reportID') && (
                                                                <td className='ps-5'>{data?.trackingId}</td>
                                                            )}
                                                            {hiddenColumns.includes('userAccount') && (
                                                                <td>
                                                                    <Link to={`/user-profile/expense-items/${data.userAccount?.orgUserID}`}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='symbol symbol-50px me-5'>
                                                                                {/* {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> : */}
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                                {/* } */}
                                                                            </div>
                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                <p className='text-capitalize mb-0'>
                                                                                    {data?.userAccount?.firstName}  {data?.userAccount?.middleName} {data?.userAccount?.lastName}
                                                                                </p>
                                                                                <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                                    {data?.userAccount?.email}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('expenseType') && (
                                                                <td>{data?.expenseType?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('title') && (
                                                                <td>{data?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseDetail') && (
                                                                <td className='w-300px text-wrap'>
                                                                    {/* {data?.expenseDetail?.length > 60 ? `${data?.expenseDetail.substring(0, 40)}...` : data?.expenseDetail} */}
                                                                    {data && data.expenseDetail && <ReactReadMoreReadLess
                                                                        charLimit={30}
                                                                        readMoreText={"Read more"}
                                                                        readLessText={"Read less"}
                                                                        readMoreClassName="readMore"
                                                                        readLessClassName="readLess"
                                                                    >
                                                                        {data.expenseDetail}
                                                                    </ReactReadMoreReadLess>}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('amount') && (
                                                                <td>
                                                                    <CurrencyComp amount={data?.amount} />
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('reportDate') && (
                                                                <td><DateComp formattedDate={data?.reportDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('expenseStatus') && (
                                                                <td>
                                                                    {data?.expenseStatus.id === constraintConfig.expenseStatus.drafted ?
                                                                        <span className="badge badge-light-primary fs-7 fw-semibold">Draft</span> :
                                                                        data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval ?
                                                                            <span className="badge badge-light-info fs-7 fw-semibold">Pending Approval</span> :
                                                                            // data?.expenseStatus.id === constraintConfig.expenseStatus.clarification ?
                                                                            //     <span className="badge badge-light-warning fs-7 fw-semibold">Clarification</span> :
                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.approved ?
                                                                                <span className="badge badge-light-success fs-7 fw-semibold">Approved</span> :
                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.rejected ?
                                                                                    <span className="badge badge-light-danger fs-7 fw-semibold">Rejected</span> :
                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.apApproved ?
                                                                                        <span className="badge badge-light-success fs-7 fw-semibold">AP Approved</span> : ""
                                                                    }
                                                                </td>
                                                            )}
                                                            {/* {data?.expenseStatus?.id !== constraintConfig.expenseStatus.apApproved &&
                                                                <> */}
                                                            {hiddenColumns.includes('assignToMe') && (
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input cursor-pointer"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id={`flexSwitchCheckChecked-${data?.expenseId}`}
                                                                            checked={data?.assignedActive}
                                                                            onChange={(e) => {
                                                                                setIsChecked(e.target.checked);
                                                                                setAsignToMeData(data);
                                                                                assignToMeHandler();
                                                                            }}
                                                                            disabled={data?.assignedActive === true && data?.assignedToAPId !== userAccountID}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.expenseId}`}></label>
                                                                    </div>
                                                                    {/* } */}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('assignToAP') && (
                                                                <td>{data?.assignedToAPName}</td>
                                                            )}
                                                            {/* </>
                                                            } */}
                                                            {hiddenColumns.includes('clarificationNote') && (
                                                                <td className='text-wrap w-300px'>
                                                                    {/* {data?.clarificationNote && data?.clarificationNote.length > 20 ? `${data?.clarificationNote.substring(0, 20)}...` : data?.clarificationNote} */}

                                                                    {data && data.clarificationNote && <ReactReadMoreReadLess
                                                                        charLimit={30}
                                                                        readMoreText={"Read more"}
                                                                        readLessText={"Read less"}
                                                                        readMoreClassName="readMore"
                                                                        readLessClassName="readLess"
                                                                    >
                                                                        {data.clarificationNote}
                                                                    </ReactReadMoreReadLess>}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('syncToBCDate') && (
                                                                <td><DateTimeComp formattedDate={data?.syncSSToBCDateTime} /></td>
                                                            )}
                                                            {/* <td>{data?.syncSSToBCMsg}</td> */}
                                                            {hiddenColumns.includes('syncToBCStatus') && (
                                                                <td>
                                                                    {data?.syncSSToBCStatusCode === "Accepted" ?
                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{data?.syncSSToBCStatusCode}</span> :
                                                                        <> {data?.syncSSToBCStatusCode}</>
                                                                    }
                                                                </td>)}
                                                            {hiddenColumns.includes('bCExpenseCreated') && (
                                                                <td>{data?.bcExpenseCreated === true ? "Yes" : data?.bcExpenseCreated === false ? "No" : ''}</td>
                                                            )}
                                                            {hiddenColumns.includes('bcExpenseCreatedDate') && (
                                                                <td><DateTimeComp formattedDate={data?.bcExpenseCreatedDateTime} /></td>
                                                            )}
                                                            {hiddenColumns.includes('bCExpenseUpdated') && (
                                                                <td>{data?.bcExpenseUpdatedStatus === true ? "Yes" : data?.bcExpenseUpdatedStatus === false ? "No" : ''}</td>
                                                            )}
                                                            {hiddenColumns.includes('bcExpenseUpdatedDate') && (
                                                                <td><DateTimeComp formattedDate={data?.bcExpenseUpdatedDateTime} /></td>
                                                            )}
                                                            {hiddenColumns.includes('action') && (
                                                                <td className='text-end' >
                                                                    <Tooltip id="my-tooltip-1" place="top" />
                                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                        <DownloadReport data={data} />
                                                                        {data?.assignedToAPId === userAccountID || data?.assignedToAPId === null ?
                                                                            <>
                                                                                {data?.expenseStatus?.id !== constraintConfig.expenseStatus.apApproved &&
                                                                                    <button
                                                                                        // data-tooltip-id="my-tooltip-1" data-tooltip-content="Update Status"
                                                                                        className='btn btn-primary btn-xsm'
                                                                                        onClick={() => {
                                                                                            setExpenseConfirmationData(data?.expenseId);
                                                                                            expenseConfirmationModalHandler();
                                                                                        }}
                                                                                    >
                                                                                        Approve Expense
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                            : null
                                                                        }
                                                                        {data?.expenseStatus?.id !== constraintConfig.expenseStatus.apApproved &&
                                                                            <button
                                                                                className='btn btn-primary btn-xsm'
                                                                                onClick={() => {
                                                                                    setExpenseConfirmationData(data?.expenseId);
                                                                                    syncWithBCHandler();
                                                                                }}
                                                                            >
                                                                                Sync With BC
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                        {showInnerTable === index && (
                                                            <tr className={`inner-row ${showInnerTable === index ? 'highlighted-row' : ''}`}>
                                                                <td className='pt-0 px-3' colSpan={18}>
                                                                    <div className="table-responsive sticky-responsive">
                                                                        <table className="table align-middle gs-0 gy-4">
                                                                            <thead>
                                                                                <tr className='fw-bold text-muted bg-light'>
                                                                                    {/* <th className='ps-4 rounded-start text-center w-60px'>id</th> */}
                                                                                    <th className='ps-4'>{labelKey.itemDate}</th>
                                                                                    <th>{labelKey.category}</th>
                                                                                    {/* <th>currency</th> */}
                                                                                    <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                                    <th className='w-150px pe-15'>{labelKey.reimbursableDistance}</th>
                                                                                    <th>{labelKey.vendor}</th>
                                                                                    <th>{labelKey.paymentMethod}</th>
                                                                                    <th>{labelKey.attachment}</th>
                                                                                    <th>{labelKey.source}</th>
                                                                                    <th className='rounded-end'>{labelKey.description}</th>
                                                                                    {(data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted) && (
                                                                                        <th className='text-end pe-3 rounded-end'>{labelKey.action}</th>
                                                                                    )}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {!expenseItemIsLoading && <>
                                                                                    {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                                                        <tr key={index}>
                                                                                            {/* <td className='text-center'>
                                                                                                {(page - 1) * pageSize + index + 1}
                                                                                            </td> */}
                                                                                            <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                            <td className='ps-5'><CategoriesWithIcons itemData={itemData} /></td>
                                                                                            {/* <td>{itemData?.currency?.title}</td> */}
                                                                                            <td className='text-end pe-15'><CurrencyComp amount={itemData?.amount} /></td>
                                                                                            <td className='text-end pe-15'>
                                                                                                {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                    <>
                                                                                                        {itemData?.reimbursableDistance}
                                                                                                    </>
                                                                                                    : null
                                                                                                }
                                                                                            </td>
                                                                                            <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                            <td>{itemData?.paymentMethod?.title}</td>
                                                                                            <td>
                                                                                                {itemData?.attachmentFile === null || itemData?.attachmentFile === "null" || itemData?.attachmentFile === "undefined" ?
                                                                                                    <span className='ps-9'>N/A</span> :
                                                                                                    <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                }

                                                                                                {lightboxOpen && (
                                                                                                    <Lightbox
                                                                                                        mainSrc={lightboxData.imageURL}
                                                                                                        onCloseRequest={() => setLightboxOpen(false)}
                                                                                                        imageCaption="Attachment"
                                                                                                        enableZoom={true}
                                                                                                        imagePadding={50}
                                                                                                    />
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                    <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                                        svgClassName='w-20px h-20px'
                                                                                                        data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                                    :
                                                                                                    itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                        <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                            data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                        itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                            <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                                data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                            : null}
                                                                                            </td>
                                                                                            <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </>}
                                                                                {expenseItemIsLoading && (
                                                                                    <tr>
                                                                                        <td colSpan={20} className="text-center">Loading ....</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        ) :
                                            (
                                                <tr>
                                                    <td colSpan={20}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={20} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table >
                    </div >

                    <div className="d-flex flex-stack flex-wrap pt-10">
                        <div className="fs-6 fw-bold text-gray-700">
                            Showing {data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            &nbsp; to &nbsp;
                            {data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            &nbsp; of <span>{data?.result?.totalRecords}</span> Entries
                        </div>


                        <div className='d-flex align-items-center gap-3'>
                            <div className='d-flex align-items-center'>
                                <label className='fs-6 text-gray-700 fw-semibold me-2'>Per page</label>
                                <select value={pageSize} className='form-select form-select-solid h-40px w-80px' onChange={handlePageSizeChange}>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel="Next"
                                onPageChange={(e: any) => handlePageClick(e)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={1}
                                pageCount={data?.result?.totalPages}
                                previousLabel="Previous"
                                // renderOnZeroPageCount={null}


                                containerClassName='pagination'
                                pageClassName='page-item'
                                previousClassName='page-item'
                                nextClassName='page-item'
                                breakClassName='page-item'

                                pageLinkClassName='page-link'
                                previousLinkClassName='page-link'
                                nextLinkClassName='page-link'
                                breakLinkClassName='page-link'
                                activeClassName='active'
                            />
                        </div>
                    </div>
                    {/* end::Table container */}
                </div >


                {/* begin::Body */}
            </div >
        </>
    )
}

export default ExpenseForAp